import { FC } from 'react';

export interface TimeColonIconProps {
	width?: number;
	height?: number;
}

export const TimeColonIcon: FC<TimeColonIconProps> = ({
	width = 5,
	height = 16,
}) => {
	return (
		<svg
			fill="none"
			height={height}
			viewBox="0 0 5 16"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.4806 10.5789L4.09939 8.95789V13.821L2.4806 15.4421L0.861816 13.821V12.2L2.4806 10.5789Z"
				fill="#BF3054"
			/>
			<path
				d="M2.4806 0.852631L4.09939 2.47368V4.09474L2.4806 5.71579L0.861816 7.33684V2.47368L2.4806 0.852631Z"
				fill="#BF3054"
			/>
		</svg>
	);
};

import { FRAME_DURATION, START_DELAY } from '@common/constants';

const LEFT = [
	'polygon(0% 0%, 100% 0%, 100% 100%, 100% 0%, 100% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 50% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%)',
];

const LEFT_EXIT = [
	'polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 75%, 75% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 50%, 50% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 25%, 25% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 0%, 0% 100%, 0 100%, 0 0)',
	'polygon(75% 0, 75% 0%, 0% 75%, 0 75%, 0 0)',
	'polygon(50% 0, 50% 0%, 0% 50%, 0 50%, 0 0)',
	'polygon(25% 0, 25% 0%, 0% 25%, 0 25%, 0 0)',
	'polygon(0% 0, 0% 0%, 0% 0%, 0 0%, 0 0)',
];

const RIGHT = [
	'polygon(0% 0%, 100% 0%, 0% 0%, 0% 100%, 0% 0%)',
	'polygon(50% 0%, 100% 0%, 0% 0%, 0% 100%, 0% 100%)',
	'polygon(100% 0%, 100% 0%, 0% 0%, 0% 100%, 50% 100%)',
	'polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%, 100% 100%)',
];

const RIGHT_EXIT = [
	'polygon(100% 0, 100% 100%, 0 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 100%, 25% 100%, 0 75%, 0 0)',
	'polygon(100% 0, 100% 100%, 50% 100%, 0 50%, 0 0)',
	'polygon(100% 0, 100% 100%, 75% 100%, 0 25%, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 100%, 0 0%, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 75%, 25% 0, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 50%, 50% 0, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 25%, 75% 0, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 0%, 100% 0, 0 0)',
];

const show = {
	clipPath: '',
	transition: {
		duration: FRAME_DURATION,
		ease: 'linear',
		delay: 0,
	},
};

export const LEFT_VARIANTS = (delay: number) => ({
	initial: {
		clipPath: LEFT[0],
	},

	animate: {
		clipPath: LEFT,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + delay,
		},
	},

	show,

	exit: {
		opacity: 1,
		clipPath: LEFT_EXIT,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
});

export const RIGHT_VARIANTS = (delay: number) => ({
	initial: {
		clipPath: RIGHT[0],
	},

	animate: {
		clipPath: RIGHT,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + delay,
		},
	},

	show,

	exit: {
		opacity: 1,
		clipPath: RIGHT_EXIT,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
});

import React from 'react';
import styled from 'styled-components';

const Border = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
`;

export const GenesisBorder = () => {
	return (
		<Border
			fill="none"
			height="100%"
			viewBox="0 0 452 495"
			width="100%"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_562_12852)">
				<path
					d="M372.31 3C373.39 3 374.42 3.33 375.32 3.95L443.98 51.63C447.04 53.76 448.87 57.25 448.87 60.98V434.02C448.87 437.75 447.04 441.24 443.98 443.37L375.31 491.05C374.42 491.67 373.39 492 372.31 492H79.56C78.48 492 77.45 491.67 76.55 491.05L7.89 443.37C4.83 441.24 3 437.75 3 434.02V60.98C3 57.25 4.83 53.76 7.89 51.63L76.56 3.95C77.45 3.33 78.48 3 79.56 3H372.31ZM372.31 0H79.56C77.87 0 76.23 0.52 74.84 1.49L6.18 49.17C2.31 51.86 0 56.27 0 60.98V434.02C0 438.73 2.31 443.14 6.18 445.83L74.84 493.51C76.23 494.48 77.86 495 79.56 495H372.31C374 495 375.64 494.48 377.03 493.51L445.69 445.83C449.56 443.14 451.87 438.73 451.87 434.02V60.98C451.87 56.27 449.56 51.86 445.69 49.17L377.03 1.49C375.64 0.52 374.01 0 372.31 0Z"
					fill="#BF3054"
				/>
				<g clipPath="url(#clip1_562_12852)">
					<path
						d="M371.4 468.37H80.3896L92.0097 476.44C94.0697 477.87 96.5296 478.64 99.0396 478.64H352.75C355.26 478.64 357.71 477.87 359.78 476.44L371.4 468.37Z"
						fill="#FFF1DF"
					/>
					<path
						d="M365.74 19.5C367.68 19.5 369.55 20.1 371.14 21.22L424.28 58.95C426.79 60.69 428.29 63.55 428.29 66.61V420.32C428.29 423.37 426.79 426.24 424.28 427.98L367.47 467.43H84.32L27.51 427.98C25 426.24 23.5 423.38 23.5 420.32V66.61C23.5 63.56 25 60.69 27.51 58.95L81.92 21.17C83.49 20.08 85.33 19.5 87.24 19.5H365.74ZM365.74 16.5H87.24C84.73 16.5 82.27 17.27 80.21 18.7L25.79 56.48C22.47 58.78 20.5 62.56 20.5 66.6V420.32C20.5 424.36 22.48 428.14 25.79 430.44L83.37 470.42H368.41L425.99 430.44C429.31 428.14 431.28 424.36 431.28 420.32V66.61C431.28 62.57 429.3 58.79 425.99 56.49L372.87 18.77C370.79 17.29 368.29 16.5 365.74 16.5Z"
						fill="#FFF1DF"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_562_12852">
					<rect fill="white" height="495" width="451.87" />
				</clipPath>
				<clipPath id="clip1_562_12852">
					<rect
						fill="white"
						height="463.64"
						transform="translate(19 15)"
						width="413.79"
					/>
				</clipPath>
			</defs>
		</Border>
	);
};

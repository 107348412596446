import { FC } from 'react';

import { BaseModal, CharacterModalGallery } from '@home/components/molecules';
import { CardContent } from '@home/types';

export interface CharacterModalProps {
	onClose: () => void;
	onChangeIndex?: (newIndex: number) => void;
	index: number;
	contents: CardContent[];
}

export const CharacterModal: FC<CharacterModalProps> = (
	props: CharacterModalProps,
) => {
	return (
		<BaseModal
			content={props.contents.map((c) => ({
				centerContent: c.image && <CharacterModalGallery image={c.image} />,
			}))}
			index={props.index}
			onChangeIndex={props.onChangeIndex}
			onClose={props.onClose}
		/>
	);
};

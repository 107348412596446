import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AnimationManager } from '@common/animations';
import { HudAnimation, LottieAnimation } from '@common/enums';
import { useStore } from '@common/store';
import { FooterSectionContent } from '@home/sections';
import { ExternalAsset } from '@home/types';

import { NavBarItem } from '../NavBar/NavBar';
import {
	ArchHudElement,
	CountdownHudElement,
	HudBottomRow,
	ScrollIndicator,
} from './components';

export interface HudContent {
	countdown: string | null;
	countdownLabel: string;
	prePhaseLabel: string;
	topCta?: {
		label: string;
		link: string;
	};
	bottomCta?: {
		label: string;
		link: string;
	};
	footerMenu?: { label: string; link?: string }[];
	footerCopyright?: { label: string; link?: string };
	footerSocial?: { icon: ExternalAsset; link: string }[];
	navbarItems: NavBarItem[];
}

interface SHudWrapperProps {
	readonly zIndex: number;
}

const SHudWrapper = styled.div<SHudWrapperProps>`
	pointer-events: none;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: ${(props) => props.zIndex};
`;

const SHud = styled.div`
	max-width: 2000px;
`;

export interface HudProps {
	children?: ReactNode;
	zIndex: number;
	content: HudContent;
	footer: FooterSectionContent;
	scrollPercentage: number;
}

const ANIMATIONS = {
	countdown: LottieAnimation.COUNTDOWN,
	arch: LottieAnimation.ARCH,
	discord: LottieAnimation.DISCORD,
	speedmeter: LottieAnimation.SPEEDMETER,
};

export const Hud: FC<HudProps> = ({
	zIndex,
	content,
	footer,
	scrollPercentage,
}: HudProps) => {
	const isWebglLoaded = useStore((state) => state.isWebglLoaded);
	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (isWebglLoaded && loaded) {
			setTimeout(() => {
				AnimationManager.startCountdownAnimationLoop(1);
				AnimationManager.startDiscordAnimationLoop(200);
				AnimationManager.startArchesAnimationLoop(500);
				setTimeout(() => {
					setOpen(true);
				}, 500);
				AnimationManager.startSpeedmetersAnimationLoop(250);
			}, 1600);
		}
		setLoaded(true);
	}, [isWebglLoaded, loaded]);

	return (
		<SHudWrapper zIndex={zIndex}>
			<SHud className="w-full relative">
				<ScrollIndicator percentage={scrollPercentage} />

				<ArchHudElement
					filename={ANIMATIONS.arch}
					id={[HudAnimation.ARCH_LEFT_TOP, HudAnimation.ARCH_LEFT_BOTTOM]}
					open={open}
					position="left"
				/>

				<CountdownHudElement
					content={content}
					filename={ANIMATIONS.countdown}
					id={HudAnimation.COUNTDOWN}
				/>

				<ArchHudElement
					filename={ANIMATIONS.arch}
					id={[HudAnimation.ARCH_RIGHT_TOP, HudAnimation.ARCH_RIGHT_BOTTOM]}
					open={open}
					position="right"
				/>

				<HudBottomRow
					animations={ANIMATIONS}
					content={content}
					footer={footer}
				/>
			</SHud>
		</SHudWrapper>
	);
};

import { FC } from 'react';

export const GameOverviewFeatureFrameMobile: FC = () => {
	return (
		<svg
			fill="none"
			preserveAspectRatio="xMidYMid"
			viewBox="0 0 327 83"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M292 69C298.627 69 304 63.6274 304 57C304 50.3726 298.627 45 292 45C285.373 45 280 50.3726 280 57C280 63.6274 285.373 69 292 69Z"
				fill="#BF3054"
			/>
			<path d="M292 54H295V57H297V52H292V54Z" fill="#FFF1DF" />
			<path d="M292 60H289V57H287V62H292V60Z" fill="#FFF1DF" />
			<path d="M24 58V23" stroke="url(#paint0_linear_43_89)" strokeWidth="2" />
			<path
				d="M21.1504 62L24 59.1505L26.8495 62L24 64.8496L21.1504 62ZM19 62L24 67L29 62L24 57L19 62Z"
				fill="#FFF1DF"
			/>
			<path d="M9 77L11.9322 80H297.763L302 77H9Z" fill="#FFF1DF" />
			<path
				d="M291.947 5.75C292.542 5.75 293.107 5.92947 293.594 6.27406L317.53 22.9366C318.876 23.877 319.685 25.4205 319.685 27.0645V60.601C319.685 62.0871 319.033 63.4942 317.895 64.449L300.654 78.9506C299.752 79.7116 298.606 80.128 297.432 80.128H12.8444L2.83496 68.0815V9.97844L7.5533 5.75H291.947Z"
				stroke="#FFF1DF"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="M11.3698 82.2318C11.778 82.72 12.3794 83 13.0166 83H304.167C305.685 83 307.153 82.4616 308.313 81.4852L323.928 68.3476C325.876 66.7108 327 64.2915 327 61.7501V26.024C327 23.2098 325.632 20.5823 323.334 18.967L297.988 1.17017C296.907 0.409197 295.611 0 294.294 0H9.63715L0 8.61483V68.6348L11.3698 82.2247V82.2318ZM1.40332 9.33991L7.0095 4.31459H291.948C292.828 4.31459 293.688 4.5874 294.411 5.08993L318.346 21.7524C320.079 22.9585 321.117 24.9399 321.117 27.0577V60.5943C321.117 62.5039 320.272 64.313 318.819 65.5406L301.578 80.0422C300.418 81.0186 298.95 81.557 297.432 81.557H12.1717L1.43197 68.6348H1.39618V9.33274L1.40332 9.33991Z"
				fill="url(#paint1_linear_43_89)"
			/>
			<defs>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint0_linear_43_89"
					x1="nan"
					x2="nan"
					y1="nan"
					y2="nan"
				>
					<stop stopColor="#C935D4" stopOpacity="0" />
					<stop offset="1" stopColor="#C935D4" />
				</linearGradient>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint1_linear_43_89"
					x1="0"
					x2="294.986"
					y1="55.9964"
					y2="55.9964"
				>
					<stop stopColor="#741F7A" />
					<stop offset="1" stopColor="#BF3054" />
				</linearGradient>
			</defs>
		</svg>
	);
};

import { FC } from 'react';

export const GameOverviewFeatureFrameTopRight: FC = () => {
	return (
		<svg
			fill="none"
			preserveAspectRatio="xMidYMid"
			viewBox="0 0 413 157"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M37.5498 147L43.4498 152H394.45L398.55 147H37.5498Z"
				fill="#FFF1DF"
			/>
			<path
				d="M401.69 11L407.05 15.79V135.5L394.13 151H45.0803C43.9103 151 42.7602 150.58 41.8702 149.83L17.7902 129.63C16.6602 128.68 16.0002 127.28 16.0002 125.8V38.69C16.0002 37.05 16.8003 35.52 18.1503 34.58L51.5903 11.36C51.9303 11.12 52.3302 11 52.7402 11H401.69V11ZM402.45 9H52.7302C51.9002 9 51.1102 9.25 50.4302 9.73L17.0002 32.94C15.1202 34.25 13.9902 36.4 13.9902 38.69V125.8C13.9902 127.87 14.9002 129.83 16.4902 131.16L40.5703 151.36C41.8303 152.42 43.4303 153 45.0703 153H395.06L409.04 136.22V14.89L402.45 9V9Z"
				fill="#FFF1DF"
			/>
			<path
				d="M397.12 155.93C396.55 156.61 395.71 157 394.82 157H32.8705C30.7505 157 28.7005 156.25 27.0805 154.89L5.27051 136.59C2.55051 134.31 0.980469 130.94 0.980469 127.4V37.24C0.980469 33.32 2.89046 29.66 6.10046 27.41L41.5005 2.61998C43.0105 1.55998 44.8205 0.98999 46.6605 0.98999H399.521L412.98 12.99V136.99L397.1 155.92L397.12 155.93ZM411.041 14L403.21 6.99998H52.7305C51.5005 6.99998 50.3005 7.37999 49.2905 8.07999L15.8605 31.29C13.4405 32.97 11.9905 35.73 11.9905 38.68V125.79C11.9905 128.45 13.1705 130.97 15.2005 132.68L39.2805 152.88C40.9005 154.24 42.9505 154.99 45.0705 154.99H395.99L410.99 136.99H411.041V13.99V14Z"
				fill="url(#paint0_linear_188_3822)"
			/>
			<path
				d="M55.5801 24.76V124.53C55.5801 124.95 55.1301 125.3 54.5801 125.3C54.0301 125.3 53.5801 124.96 53.5801 124.53V24.76C53.5801 24.34 54.0301 23.99 54.5801 23.99C55.1301 23.99 55.5801 24.33 55.5801 24.76Z"
				fill="url(#paint1_linear_188_3822)"
			/>
			<path
				d="M50.83 129.41L54.58 125.66L58.33 129.41L54.58 133.16L50.83 129.41ZM48 129.41L54.58 135.99L61.16 129.41L54.58 122.83L48 129.41Z"
				fill="#FFF1DF"
			/>
			<path
				d="M372.5 141C379.404 141 385 135.404 385 128.5C385 121.596 379.404 116 372.5 116C365.596 116 360 121.596 360 128.5C360 135.404 365.596 141 372.5 141Z"
				fill="#BF3054"
			/>
			<path d="M372 126H375V129H377V124H372V126Z" fill="#FFF1DF" />
			<path d="M373 131H370V128H368V133H373V131Z" fill="#FFF1DF" />
			<defs>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint0_linear_188_3822"
					x1="1.00047"
					x2="413"
					y1="78.99"
					y2="78.99"
				>
					<stop stopColor="#BF3054" />
					<stop offset="1" stopColor="#741F7A" />
				</linearGradient>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint1_linear_188_3822"
					x1="54.5801"
					x2="54.5801"
					y1="125.3"
					y2="24"
				>
					<stop stopColor="#C935D4" />
					<stop offset="1" stopColor="#9928A1" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { Card } from '@home/components/molecules';
import { CarouselContent, Interval } from '@home/types';

import { VARIANTS } from '../animations';
import { RoadmapBackground } from './RoadmapBackground';
import { RoadmapBorder } from './RoadmapBorder';

const CardContainer = styled(motion.div)`
	width: 198px;
	aspect-ratio: 198/229;
	min-width: 198px;
	z-index: 9;

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		min-width: 150px;
	}
`;

const SImage = styled.div`
	z-index: 2;
	position: absolute;
	top: 13px;
	left: 18px;
	width: 171px;
	height: 209px;

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		top: 10px;
		left: 13px;
		width: 131px;
		height: 161px;
	}
`;

const SClippedContainer = styled.div<{
	clipPath: string;
}>`
	aspect-ratio: 1; // Very important little *ucker!!!
	height: 203px;
	clip-path: ${(props) => props.clipPath};
	background-position: left top;
	background-size: contain;
	background-repeat: no-repeat;

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		height: 155px;
	}
`;

interface AnimateCardsProps {
	cards: CarouselContent['cards'];
	active: Interval;
}

export const AnimateCards = ({ cards, active }: AnimateCardsProps) => {
	const [initial, setInitial] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setInitial(false);
		}, 1500 + (active.end - active.start + 1) * 200);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isActive = (index: number) =>
		index >= active.start && index <= active.end;

	return (
		<>
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_ROADMAP_CARD"
					>
						<path d="M0.167488 0H0.8264V4.632e-05L0.842364 0.0125655V0.956715L0.806703 1H0.100657L0.0116628 0.928338C0.00424526 0.922174 0 0.918017 0 0.908423V0.127747C0 0.117134 0.00527163 0.112096 0.0140421 0.106025L0.160145 0.0033832C0.163317 0.00115862 0.165025 0 0.167488 0Z" />
					</clipPath>
				</defs>
			</svg>
			{cards.map((card, i) => (
				<CardContainer
					key={card.id}
					animate={
						initial && isActive(i) ? 'entry' : isActive(i) ? 'show' : 'hide'
					}
					className="relative"
					exit="hide"
					variants={VARIANTS(i)}
				>
					<SImage>
						<SClippedContainer clipPath="url(#CLIP_PATH_ROADMAP_CARD)">
							<Card content={card} />
						</SClippedContainer>
					</SImage>
					<RoadmapBorder />
					<RoadmapBackground />
				</CardContainer>
			))}
		</>
	);
};

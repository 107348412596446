import React from 'react';
import styled from 'styled-components';

const Background = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;

export const GenesisBackground = () => {
	return (
		<Background
			fill="none"
			height="100%"
			viewBox="0 0 452 495"
			width="100%"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_562_12863)">
				<path
					d="M0 434.02C0 438.73 2.31 443.14 6.18 445.83L74.84 493.51C76.23 494.48 77.86 495 79.56 495H372.31C374 495 375.64 494.48 377.03 493.51L445.69 445.83C449.56 443.14 451.87 438.73 451.87 434.02V60.98C451.87 56.27 449.56 51.86 445.69 49.17L377.03 1.49C375.64 0.52 374.01 0 372.31 0H79.56C77.87 0 76.23 0.52 74.84 1.49L6.18 49.17C2.31 51.86 0 56.27 0 60.98V434.02Z"
					fill="#3B113F"
				/>
			</g>
			<defs>
				<clipPath id="clip0_562_12863">
					<rect fill="white" height="495" width="451.87" />
				</clipPath>
			</defs>
		</Background>
	);
};

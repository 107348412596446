import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';

export interface BaseSectionTransitionerProps {
	visible: boolean;
	className?: string;
	children: ReactNode;
}

const animationConfig = {
	initial: { opacity: 0, y: 240 },

	animate: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 1.4,
			ease: [0.65, 0, 0.35, 1],
		},
	},

	exit: {
		opacity: 0,
		y: -600,
		transition: {
			duration: 1,
			ease: [0.32, 0, 0.67, 0],
		},
	},
};

export const BaseSectionTransitioner: FC<BaseSectionTransitionerProps> = ({
	visible,
	className,
	children,
}) => {
	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					animate="animate"
					className={className}
					exit="exit"
					initial="initial"
					variants={animationConfig}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

import { Interval } from '@home/types';

export const getActiveElements = (elements: unknown[], interval: Interval) => {
	const newElements = [];
	const { start, end } = interval;

	if (start < end) {
		for (let i = start; i <= end; i++) {
			newElements.push(elements[i]);
		}
	} else {
		for (let i = start; i <= elements.length - 1; i++) {
			newElements.push(elements[i]);
		}
		for (let i = 0; i <= end; i++) {
			newElements.push(elements[i]);
		}
	}

	return newElements;
};

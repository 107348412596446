import React from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';

const Base = styled.p`
	white-space: pre-line;
	font-family: 'Prohibition';
`;

const RegularText = styled(Base)`
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.065;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.08em;
	}
`;

const SmallText = styled(Base)`
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.065em;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 11px;
		line-height: 14px;
		letter-spacing: 0.08em;
	}
`;

const FooterText = styled(Base)`
	font-family: 'Rift';
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.01;
	color: #741f7a;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 11px;
		line-height: 14px;
		letter-spacing: 0.08em;
	}
`;

const BoxText = styled(Base)`
	font-family: 'Open Sans';
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0;

	a {
		color: #bf3054;
	}

	a:hover {
		color: #fff1df;
	}

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0em;
	}
`;

export interface TextProps {
	children: React.ReactNode | string;
	className?: string;
	small?: boolean;
	footer?: boolean;
	box?: boolean;
	innerHtml?: boolean;
}

export const Text: React.FC<TextProps> = ({
	children,
	className = '',
	small = false,
	footer = false,
	box = false,
	innerHtml = false,
}: TextProps) =>
	box ? (
		<BoxText
			className={`text-offWhite ${className}`}
			dangerouslySetInnerHTML={
				innerHtml ? { __html: children as string } : undefined
			}
		>
			{!innerHtml ? children : null}
		</BoxText>
	) : footer ? (
		<FooterText className={`text-offWhite ${className}`}>{children}</FooterText>
	) : small ? (
		<SmallText className={`text-offWhite ${className}`}>{children}</SmallText>
	) : (
		<RegularText className={`text-offWhite ${className}`}>
			{children}
		</RegularText>
	);

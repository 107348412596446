import { FC } from 'react';

export interface DiamondIndicatorIconProps {
	active?: boolean;
}

export const DiamondIndicatorIcon: FC<DiamondIndicatorIconProps> = ({
	active,
}) => {
	if (active)
		return (
			<svg
				fill="none"
				height="12"
				viewBox="0 0 12 12"
				width="12"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M10 6L6 2L2 6L6 10L10 6Z" stroke="#FFF1DF" strokeWidth="2" />
			</svg>
		);

	return (
		<svg
			fill="none"
			height="12"
			viewBox="0 0 12 12"
			width="12"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M10 6L6 2L2 6L6 10L10 6Z" stroke="#BF3054" strokeWidth="2" />
		</svg>
	);
};

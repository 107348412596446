export const CLIP_PATH_FROM_TOP_LEFT = [
	'polygon(0% 0%, 100% 0%, 0% 0%, 0% 100%, 0% 0%)',
	'polygon(50% 0%, 100% 0%, 0% 0%, 0% 100%, 0% 100%)',
	'polygon(100% 0%, 100% 0%, 0% 0%, 0% 100%, 50% 100%)',
	'polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%, 100% 100%)',
];

export const VARIANTS = (index: number) => ({
	initial: {
		opacity: 0,
		clipPath: CLIP_PATH_FROM_TOP_LEFT[0],
	},

	entry: {
		opacity: 1,
		clipPath: CLIP_PATH_FROM_TOP_LEFT,
		transition: {
			duration: 0.5,
			ease: 'linear',
			delay: 1 + 0.2 * index,
		},
	},

	show: {
		opacity: 1,
		clipPath: CLIP_PATH_FROM_TOP_LEFT[3],
		transition: {
			duration: 0.5,
			ease: 'linear',
			delay: 0,
		},
	},

	hide: {
		opacity: 0,
		clipPath: CLIP_PATH_FROM_TOP_LEFT[3],
		transition: {
			duration: 0.25,
			ease: 'linear',
			delay: 0,
		},
	},
});

export const LINE_VARIANTS = {
	show: {
		opacity: 1,
		transition: {
			duration: 0.5,
			ease: 'linear',
			delay: 0,
		},
	},

	hide: {
		opacity: 0,
		transition: {
			duration: 0.25,
			ease: 'linear',
			delay: 0,
		},
	},
};

export const INDICATOR_VARIANTS = {
	initial: {
		opacity: 0,
	},

	show: {
		opacity: 1,
		transition: {
			duration: 0.5,
			ease: 'linear',
			delay: 0,
		},
	},

	hide: {
		opacity: 0,
		transition: {
			duration: 0.25,
			ease: 'linear',
			delay: 0,
		},
	},
};

const CLIP_PATH = [
	'polygon(0 -100%, 0 0, -100% 200%, 0 200%, 0 50%)',
	'polygon(0 -100%, 0 0, -100% 200%, 0 200%, 25% 50%)',
	'polygon(25% -100%, 0 0, -100% 200%, 50% 200%, 50% 50%)',
	'polygon(50% -100%, 0 0, -100% 200%, 100% 200%, 75% 50%)',
	'polygon(75% -100%, 0 0, -100% 200%, 150% 200%, 100% 50%)',
	'polygon(100% -100%, 0 0, -100% 200%, 200% 200%, 100% 50%)',
];

const CLIP_PATH_EXIT = [
	'polygon(100% -100%, 0 0, -100% 200%, 100% 200%, 100% 50%)',
	'polygon(75% -100%, 0 0, -100% 200%, 75% 200%, 100% 50%)',
	'polygon(50% -100%, 0 0, -100% 200%, 50% 200%, 75% 50%)',
	'polygon(25% -100%, 0 0, -100% 200%, 25% 200%, 50% 50%)',
	'polygon(0 -100%, 0 0, -100% 200%, 0 200%, 25% 50%)',
	'polygon(0 -100%, 0 0, -100% 200%, 0 200%, 0 50%)',
];

export const BOX_VARIANTS = {
	initial: {
		opacity: 0,
		clipPath: CLIP_PATH[0],
	},

	animate: {
		opacity: 1,
		clipPath: CLIP_PATH,
		transition: {
			duration: 0.5,
			ease: 'linear',
			delay: 2,
		},
	},

	exit: {
		opacity: 1,
		clipPath: CLIP_PATH_EXIT,
		transition: {
			duration: 0.5,
			ease: 'linear',
			delay: 0,
		},
	},
};

import { useMemo } from 'react';

import { LoaderEvent } from '@common/enums';
import { useStore } from '@common/store';
import { randomRange } from '@common/utils';

export const useLoaderEvent = (event: LoaderEvent): (() => void) => {
	const setLoadingProgress = useStore((state) => state.setLoadingProgress);

	const callback = useMemo(() => {
		switch (event) {
			case LoaderEvent.MAIN_BUNDLE_LOADED:
				return () => {
					setLoadingProgress(Math.floor(randomRange(25, 35)));
				};
			case LoaderEvent.WEBGL_CODE_LOADED:
				return () => {
					setLoadingProgress(Math.floor(randomRange(65, 70)));
				};
			case LoaderEvent.WEBGL_ASSETS_LOADED:
				return () => {
					setLoadingProgress(100);
				};
			default:
				return () => {
					/* */
				};
		}
	}, [event, setLoadingProgress]);

	return callback;
};

import { useEffect, useState } from 'react';

import { addLeadingZero } from '@common/utils';

interface useCountdownProps {
	startTime: number;
	endTime: number;
}

export const useCountdown = ({ startTime, endTime }: useCountdownProps) => {
	const [timeElapsed, setTimeElapsed] = useState(0);
	const [countdown, setCountdown] = useState(
		endTime - startTime > 0 ? endTime - startTime : 0,
	);

	useEffect(() => {
		if (countdown > 0) {
			const interval = setInterval(() => {
				if (countdown !== 0) {
					const elapsed = timeElapsed + 1000;
					setTimeElapsed(elapsed);

					setCountdown(
						endTime - (startTime + elapsed) > 0
							? endTime - (startTime + elapsed)
							: 0,
					);
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [startTime, endTime, timeElapsed]);

	const getDays = () => Math.floor(countdown / (1000 * 60 * 60 * 24));

	const getHours = () =>
		Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

	const getMinutes = () =>
		Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));

	const getSeconds = () => Math.floor((countdown % (1000 * 60)) / 1000);

	return {
		countdown,
		days: addLeadingZero(getDays()),
		hours: addLeadingZero(getHours()),
		minutes: addLeadingZero(getMinutes()),
		seconds: addLeadingZero(getSeconds()),
	};
};

import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg``;

const SPath = styled.path`
	transition: fill 0.5s ease;
`;

interface IndicatorIconProps {
	active?: boolean;
	width?: number;
	height?: number;
}

export const IndicatorIcon = ({
	active,
	width = 14,
	height = 14,
}: IndicatorIconProps) => {
	return (
		<Icon
			fill="none"
			height={height}
			viewBox="0 0 14 14"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<SPath
				d="M2.83 6.58L6.58 2.83L10.33 6.58L6.58 10.33L2.83 6.58ZM0 6.58L6.58 13.16L13.16 6.58L6.58 0L0 6.58Z"
				fill={active ? '#FFF1DF' : '#9928A1'}
			/>
		</Icon>
	);
};

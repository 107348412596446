import { useEffect } from 'react';
import styled from 'styled-components';

import { LoaderEvent, zIndex } from '@common/enums';
import { useLoaderEvent } from '@common/hooks';
import { useStore } from '@common/store';
import { delay } from '@common/utils';

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${zIndex.WEBGL};
	background: #230b26;
`;

export const WebGLFallback = () => {
	const webglLoadedEventDispatch = useLoaderEvent(
		LoaderEvent.WEBGL_ASSETS_LOADED,
	);

	const setIsWebglLoaded = useStore((state) => state.setIsWebglLoaded);

	useEffect(() => {
		webglLoadedEventDispatch();
		delay(1500).then(() => setIsWebglLoaded(true));
	}, [setIsWebglLoaded, webglLoadedEventDispatch]);

	return <Container />;
};

import { FC } from 'react';
import styled from 'styled-components';

import { DynamicAnimationLoader } from '@home/components/atoms';

import { ArchDetails } from './ArchDetails';

interface SArchQuaterHudElementAnimationContainerProps {
	flipX?: boolean;
	flipY?: boolean;
	height: number;
	width: number;
}

const SArchQuaterHudElement = styled.div``;

const SArchQuaterHudElementAnimationContainer = styled.div<SArchQuaterHudElementAnimationContainerProps>`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	transform: ${(props) =>
		`${props.flipX ? 'scaleX(-1)' : 'scaleX(1)'} ${
			props.flipY ? 'scaleY(-1)' : 'scaleY(1)'
		}`};
`;

export interface ArchQuaterHudElementProps {
	id: string;
	filename: string;
	width: number;
	height: number;
	flipX?: boolean;
	flipY?: boolean;
}

export const ArchQuaterHudElement: FC<ArchQuaterHudElementProps> = (
	props: ArchQuaterHudElementProps,
) => {
	return (
		<SArchQuaterHudElement className="">
			<SArchQuaterHudElementAnimationContainer
				flipX={props.flipX}
				flipY={props.flipY}
				height={props.height}
				width={props.width}
			>
				<DynamicAnimationLoader
					autoplay={false}
					filename={props.filename}
					id={props.id}
					loop={false}
				/>
			</SArchQuaterHudElementAnimationContainer>

			<ArchDetails flipX={props.flipX} flipY={props.flipY} />
		</SArchQuaterHudElement>
	);
};

import React from 'react';
import styled from 'styled-components';

import { MenuIconProps } from '@home/types';

const Active = styled.svg`
	.cls-1 {
		fill: #bf3054;
	}

	.cls-2,
	.cls-3 {
		fill: none;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}

	.cls-2 {
		stroke: #fff1df;
		stroke-width: 2px;
	}

	.cls-3 {
		stroke: #bf3054;
		stroke-width: 1.5px;
	}

	.cls-4 {
		fill: #fff1df;
	}
`;

const Hover = styled.svg`
	.cls-1 {
		fill: #fff1df;
	}
	.cls-2 {
		fill: #bf3054;
	}
	.cls-3 {
		stroke-width: 1.35px;
	}
	.cls-3,
	.cls-4 {
		fill: none;
		stroke: #fff1df;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}
	.cls-4 {
		stroke-width: 1.8px;
	}
`;

const InActive = styled.svg`
	.cls-1 {
		fill: #bf3054;
	}

	.cls-2,
	.cls-3 {
		fill: none;
		stroke: #bf3054;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}

	.cls-2 {
		stroke-width: 2px;
	}

	.cls-3 {
		stroke-width: 1.5px;
	}

	.cls-4 {
		fill: #741f7a;
	}
`;

export const RoadmapIcon = ({
	active,
	hover,
	width = 48,
	height = 48,
}: MenuIconProps) => {
	return active ? (
		<Active
			height={height}
			viewBox="0 0 47 48.4"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g data-name="Roadmap - Active" id="Roadmap_-_Active">
				<g>
					<circle className="cls-1" cx="27.5" cy="15.2" r="1" />
					<circle className="cls-1" cx="35.5" cy="12.2" r="1" />
					<circle className="cls-1" cx="11.5" cy="36.2" r="1" />
					<circle className="cls-1" cx="23.5" cy="28.2" r="1" />
					<path
						className="cls-2"
						d="M70.8,84.3v-18a3.9,3.9,0,0,0-4-4,4,4,0,0,0-4,4v17"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M74.8,75.3v-9a4,4,0,0,1,4-4,3.9,3.9,0,0,1,4,4v18"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M66.8,66.3v18a4,4,0,0,0,4,4,3.9,3.9,0,0,0,4-4v-1"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-2"
						d="M78.8,70.3v14a4,4,0,0,0,4,4,3.9,3.9,0,0,0,4-4v-17"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
			<g data-name="Frame - Active" id="Frame_-_Active">
				<g>
					<path
						className="cls-1"
						d="M94.9,75.3a18.5,18.5,0,0,1-.9,5.8h2.1a22,22,0,0,0,.8-5.8,22.5,22.5,0,0,0-.8-5.8H94A19,19,0,0,1,94.9,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-1"
						d="M54.8,75.3a19,19,0,0,1,.9-5.8H53.6a22.5,22.5,0,0,0-.8,5.8,22,22,0,0,0,.8,5.8h2.1A18.5,18.5,0,0,1,54.8,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
				<g>
					<path
						className="cls-4"
						d="M96.3,81.1a22.2,22.2,0,0,1-42.9,0H51.3a24.2,24.2,0,0,0,47,0Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M74.8,51.1A24.4,24.4,0,0,0,51.3,69.5h2.1a22.2,22.2,0,0,1,42.9,0h2A24.3,24.3,0,0,0,74.8,51.1Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
		</Active>
	) : hover ? (
		<Hover
			height={height}
			viewBox="0 0 50.96 43.63"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Roadmap_-_Inactive_copy">
				<g>
					<circle className="cls-1" cx="29.08" cy="13.71" r=".9" />
					<circle className="cls-1" cx="36.28" cy="11.01" r=".9" />
					<circle className="cls-1" cx="14.68" cy="32.61" r=".9" />
					<circle className="cls-1" cx="25.48" cy="25.41" r=".9" />
					<path
						className="cls-4"
						d="M21.88,29.91V13.71c0-1.99-1.61-3.6-3.6-3.6s-3.6,1.61-3.6,3.6v15.3"
					/>
					<path
						className="cls-3"
						d="M25.48,21.81V13.71c0-1.99,1.61-3.6,3.6-3.6s3.6,1.61,3.6,3.6V29.91"
					/>
					<path
						className="cls-3"
						d="M18.28,13.71V29.91c0,1.99,1.61,3.6,3.6,3.6s3.6-1.61,3.6-3.6v-.9"
					/>
					<path
						className="cls-4"
						d="M29.08,17.31v12.6c0,1.99,1.61,3.6,3.6,3.6s3.6-1.61,3.6-3.6V14.61"
					/>
				</g>
			</g>
			<g id="Layer_3">
				<g>
					<path
						className="cls-2"
						d="M48.84,21.81c0,2.14-.32,4.2-.91,6.15h2.21c.53-1.96,.82-4.02,.82-6.15s-.29-4.19-.82-6.15h-2.21c.59,1.95,.91,4.01,.91,6.15Z"
					/>
					<path
						className="cls-2"
						d="M2.12,21.81c0-2.14,.32-4.2,.91-6.15H.82c-.53,1.96-.82,4.02-.82,6.15s.29,4.19,.82,6.15H3.03c-.59-1.95-.91-4.01-.91-6.15Z"
					/>
				</g>
				<path
					className="cls-2"
					d="M6.46,15.67C9.06,7.64,16.59,1.8,25.48,1.8s16.42,5.83,19.02,13.86h1.88C43.73,6.63,35.37,0,25.48,0S7.24,6.63,4.57,15.67h1.88Z"
				/>
				<path
					className="cls-2"
					d="M44.5,27.96c-2.6,8.03-10.14,13.86-19.02,13.86s-16.42-5.83-19.02-13.86h-1.88c2.66,9.04,11.02,15.67,20.91,15.67s18.24-6.63,20.91-15.67h-1.88Z"
				/>
			</g>
		</Hover>
	) : (
		<InActive
			height={height}
			viewBox="0 0 47 48.4"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g data-name="Roadmap - Inactive" id="Roadmap_-_Inactive">
				<g>
					<circle className="cls-1" cx="27.5" cy="15.2" r="1" />
					<circle className="cls-1" cx="35.5" cy="12.2" r="1" />
					<circle className="cls-1" cx="11.5" cy="36.2" r="1" />
					<circle className="cls-1" cx="23.5" cy="28.2" r="1" />
					<path
						className="cls-2"
						d="M70.8,84.3v-18a3.9,3.9,0,0,0-4-4,4,4,0,0,0-4,4v17"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M74.8,75.3v-9a4,4,0,0,1,4-4,3.9,3.9,0,0,1,4,4v18"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M66.8,66.3v18a4,4,0,0,0,4,4,3.9,3.9,0,0,0,4-4v-1"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-2"
						d="M78.8,70.3v14a4,4,0,0,0,4,4,3.9,3.9,0,0,0,4-4v-17"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
			<g data-name="Frame - Inactive" id="Frame_-_Inactive">
				<g>
					<path
						className="cls-4"
						d="M96.3,81.1a22.2,22.2,0,0,1-42.9,0H51.3a24.2,24.2,0,0,0,47,0Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M74.8,51.1A24.4,24.4,0,0,0,51.3,69.5h2.1a22.2,22.2,0,0,1,42.9,0h2A24.3,24.3,0,0,0,74.8,51.1Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
				<g>
					<path
						className="cls-4"
						d="M94.9,75.3a18.5,18.5,0,0,1-.9,5.8h2.1a22,22,0,0,0,.8-5.8,22.5,22.5,0,0,0-.8-5.8H94A19,19,0,0,1,94.9,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M54.8,75.3a19,19,0,0,1,.9-5.8H53.6a22.5,22.5,0,0,0-.8,5.8,22,22,0,0,0,.8,5.8h2.1A18.5,18.5,0,0,1,54.8,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
		</InActive>
	);
};

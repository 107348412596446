import React from 'react';
import styled from 'styled-components';

import { ImageAsset, Text } from '@home/components/atoms';
import { FooterSectionContent } from '@home/sections';

const SFooterMenuContainer = styled.menu``;

const SFooterMenuItem = styled.a`
	letter-spacing: 0.08em;

	p {
		opacity: 0.75;

		&:hover {
			opacity: 1;
		}
	}
`;

const SFooterSocialContainer = styled.div``;

const SFooterSocialItem = styled.a`
	display: inline-block;
	height: 20px;
	width: 20px;
	opacity: 0.75;

	&:hover {
		opacity: 1;
	}
`;

interface FooterContentProps {
	content: FooterSectionContent;
	hud?: boolean;
}

export const FooterContent = ({ content, hud = false }: FooterContentProps) => {
	const baseClasses =
		'relative flex flex-col-reverse gap-y-6 lg:flex-row justify-between items-center w-full max-w-5xl px-4 py-10 lg:py-4 lg:mt-6';
	const hudClasses =
		'absolute bottom-[12px] w-full flex flex-col lg:flex-row items-center justify-between pointer-events-auto w-screen max-w-[960px]';

	const textClasses = `px-[12px]`;

	return (
		<div className={hud ? hudClasses : baseClasses}>
			<SFooterMenuContainer className="flex lg:hidden flex-wrap align-center justify-center opacity-75">
				<SFooterMenuItem
					className={textClasses}
					href={content.copyright.link}
					rel="noreferrer"
					target="_blank"
				>
					<Text footer>{content.copyright.label}</Text>
				</SFooterMenuItem>
			</SFooterMenuContainer>
			<SFooterMenuContainer className="flex flex-wrap align-center justify-center opacity-75">
				<SFooterMenuItem
					className={`${textClasses} hidden lg:block`}
					href={content.copyright.link}
					rel="noreferrer"
					target="_blank"
				>
					<Text footer>{content.copyright.label}</Text>
				</SFooterMenuItem>
				{content.menu.map((item, index) => (
					<SFooterMenuItem
						key={index}
						className={textClasses}
						href={item.link}
						rel="noreferrer"
						target="_blank"
					>
						<Text footer>{item.label}</Text>
					</SFooterMenuItem>
				))}
			</SFooterMenuContainer>
			<SFooterSocialContainer
				className={`flex flex-row ${
					!hud ? 'gap-6 lg:gap-[10px]' : 'gap-[10px]'
				}`}
			>
				{content.social.map((item, index) => (
					<SFooterSocialItem
						key={index}
						className="inline-block"
						href={item.link}
						rel="noreferrer"
						target="_blank"
					>
						<ImageAsset aspectRatio="1" id={item.icon.id} url={item.icon.url} />
					</SFooterSocialItem>
				))}
			</SFooterSocialContainer>
		</div>
	);
};

import { PanInfo, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useModal, useWindowSize } from '@common/hooks';
import { getActiveElements } from '@common/utils';
import { ChevronIcon } from '@home/components/atoms';
import {
	CharacterModal,
	GenesisCard,
	Position,
} from '@home/components/molecules';
import { CardContent, CarouselContent } from '@home/types';

import { BUTTON_VARIANTS } from './animations';
import { Indicator } from './components';

const Container = styled(motion.div)`
	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		margin-top: -24px;
		margin-bottom: -24px;
	}
`;

const ImageContainer = styled(motion.div)``;

export interface InfiniteCarouselProps {
	content: CarouselContent;
	className?: string;
	minCardWidth?: number;
}

export const InfiniteCarousel = ({
	content,
	className = '',
}: InfiniteCarouselProps) => {
	const wSize = useWindowSize();
	const [modal] = useModal();
	const [initial, setInitial] = useState(true);
	const [exit] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setInitial(false);
		}, 2000);
	}, []);

	const [visible, setVisible] = useState({
		start: 0,
		end: content.pageSize - 1,
	});
	const [isMobile, setIsMobile] = useState(wSize.width < Breakpoint.MEDIUM);

	const imageContainer = useRef<HTMLDivElement>(null);
	const [edgeWidth, setEdgeWidth] = useState(0);

	useEffect(() => {
		if (imageContainer.current?.children.namedItem('left')?.clientWidth)
			setEdgeWidth(
				imageContainer.current.children.namedItem('left')?.clientWidth ?? 0,
			);
	}, [imageContainer, wSize]);

	useEffect(() => {
		const mobile = wSize.width < Breakpoint.LARGE;
		setIsMobile(mobile);

		if (mobile)
			setVisible((v) => ({ ...v, end: v.start + content.pageSize - 2 }));
		else setVisible((v) => ({ ...v, end: v.start + content.pageSize - 1 }));
	}, [wSize.width, content.pageSize]);

	const paginate = (steps: number) => {
		const lastIndex = content.cards.length - 1;

		const getNewIndex = (value: number) =>
			value + steps < 0
				? lastIndex
				: value + steps > lastIndex
				? 0
				: value + steps;

		setVisible((v) => ({
			start: getNewIndex(v.start),
			end: getNewIndex(v.end),
		}));
	};

	const getCards = () => [
		getCardBefore(),
		...(getActiveElements(content.cards, visible) as CardContent[]),
		getCardAfter(),
	];

	const getCardBefore = () =>
		content.cards[
			visible.start - 1 >= 0 ? visible.start - 1 : content.cards.length - 1
		];

	const getCardAfter = () =>
		content.cards[visible.end + 1 < content.cards.length ? visible.end + 1 : 0];

	const positionsDesktop = [
		'before',
		'left',
		'centerLeft',
		'centerRight',
		'right',
		'after',
	];

	const positionsMobile = ['before', 'left', 'center', 'right', 'after'];
	/**
	 * Experimenting with distilling swipe offset and velocity into a single variable, so the
	 * less distance a user has swiped, the more velocity they need to register as a swipe.
	 * Should accomodate longer swipes and short flicks without having binary checks on
	 * just distance thresholds and velocity > 0.
	 * https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?from-embed=&file=/src/Example.tsx
	 */
	const swipeConfidenceThreshold = 10000;
	const swipePower = (offset: number, velocity: number) => {
		return Math.abs(offset) * velocity;
	};

	const onDragEnd = (
		_: MouseEvent | TouchEvent | PointerEvent,
		{ offset, velocity }: PanInfo,
	) => {
		const swipe = swipePower(offset.x, velocity.x);

		if (swipe < -swipeConfidenceThreshold) {
			paginate(1);
		} else if (swipe > swipeConfidenceThreshold) {
			paginate(-1);
		}
	};

	const _onClick = (id: string) => {
		const clickedCard = content.cards.find((x) => x.id === id);

		if (!clickedCard) return;
		const index = content.cards.indexOf(clickedCard);
		modal.open({
			content: (
				<CharacterModal
					contents={content.cards}
					index={index}
					onClose={() => {
						modal.close();
					}}
				/>
			),
		});
	};

	const classes = `
    flex sm-gap-x-4 lg:gap-x-[40px] justify-center sm:justify-between items-center lg:w-11/12 lg:mx-auto
    ${className}
    ${isMobile ? '-mx-96' : ''}
  `;
	const buttonClasses = 'hidden md:block z-10';

	return (
		<Container key="carousel">
			<div className={classes}>
				<motion.div className={buttonClasses} variants={BUTTON_VARIANTS}>
					<ChevronIcon
						direction="left"
						height={40}
						width={20}
						onClick={() => paginate(-1)}
					/>
				</motion.div>
				<svg
					className="absolute"
					height="0"
					width="0"
					xmlns="http://www.w3.org/2000/svg"
				>
					<defs>
						<clipPath
							clipPathUnits="objectBoundingBox"
							id="CLIP_PATH_GENESIS_CARD"
						>
							<path d="M0.76405 0C0.76838 0 0.77255 0.00133903 0.7761 0.00384003L0.89474 0.088072C0.90034 0.091956 0.90369 0.098341 0.90369 0.105173V0.89483C0.90369 0.90164 0.90034 0.90804 0.89474 0.91193L0.76791 1H0.13578L0.00895202 0.91193C0.00334902 0.90804 0 0.90166 0 0.89483V0.105173C0 0.098364 0.00334902 0.091956 0.00895202 0.088072L0.130422 0.00372797C0.133927 0.00129497 0.138035 0 0.142299 0H0.76405Z" />
						</clipPath>
					</defs>
				</svg>
				<ImageContainer
					ref={imageContainer}
					className="relative flex w-full gap-x-2 lg:gap-x-[12px] justify-center items-center my-10 lg:mx-[40px]"
					onPanEnd={isMobile ? onDragEnd : undefined}
				>
					{getCards().map((card, i) => (
						<GenesisCard
							key={card.id}
							content={card}
							edgeCardMaxWidth={edgeWidth}
							exitAnimation={exit}
							initialAnimation={initial}
							position={
								(isMobile
									? positionsMobile[i]
									: positionsDesktop[i]) as Position
							}
							onClick={_onClick}
						/>
					))}
				</ImageContainer>
				<motion.div className={buttonClasses} variants={BUTTON_VARIANTS}>
					<ChevronIcon
						direction="right"
						height={40}
						width={20}
						onClick={() => paginate(1)}
					/>
				</motion.div>
			</div>
			{content.pageIndicator && (
				<Indicator active={visible} length={content.cards.length} />
			)}
		</Container>
	);
};

import { FC } from 'react';

import { Text } from '@home/components/atoms';
import {
	BaseModal,
	BaseModalContentContainer,
} from '@home/components/molecules';
import { FAQ } from '@home/types';

export interface FAQModalProps {
	onClose: () => void;
	onChangeIndex?: (newIndex: number) => void;
	index: number;
	contents: FAQ[];
}

export const FAQModal: FC<FAQModalProps> = (props: FAQModalProps) => {
	return (
		<BaseModal
			content={props.contents.map((c) => ({
				centerContent: (
					<BaseModalContentContainer
						content={
							<Text box innerHtml>
								{c.answer.html}
							</Text>
						}
						title={c.question}
					/>
				),
			}))}
			index={props.index}
			onChangeIndex={props.onChangeIndex}
			onClose={props.onClose}
		/>
	);
};

const START_DELAY = 0.7;
const ROW_DELAY = 0.3;
const BG_DELAY = 0.1;
const FRAME_DURATION = 0.5;
const BG_DURATION = 0.5;

const CLIP_PATH_TL = [
	'polygon(100% 0%, 100% 100%, 0% 100%, 100% 100%, 100% 100%)',
	'polygon(100% 0%, 100% 100%, 0% 100%, 50% 100%, 100% 0%)',
	'polygon(100% 0%, 100% 100%, 0% 100%, 0% 100%, 50% 0%)',
	'polygon(100% 0%, 100% 100%, 0% 100%, 0% 0%, 0% 0%)',
];

const CLIP_PATH_TL_EXIT = [
	'polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 0)',
	'polygon(75% 0, 100% 25%, 100% 100%, 0 100%, 0 0)',
	'polygon(50% 0, 100% 50%, 100% 100%, 0 100%, 0 0)',
	'polygon(25% 0, 100% 75%, 100% 100%, 0 100%, 0 0)',
	'polygon(0 0, 100% 100%, 100% 100%, 0 100%, 0 0)',
	'polygon(0 25%, 75% 100%, 75% 100%, 0 100%, 0 25%)',
	'polygon(0 50%, 50% 100%, 50% 100%, 0 100%, 0 50%)',
	'polygon(0 75%, 25% 100%, 25% 100%, 0 100%, 0 75%)',
	'polygon(0 100%, 0% 100%, 0% 100%, 0 100%, 0 10%)',
];

const CLIP_PATH_TR = [
	'polygon(0% 100%, 0% 100%, 100% 100%, 0% 100%, 0% 0%)',
	'polygon(0% 0%, 50% 100%, 100% 100%, 0% 100%, 0% 0%)',
	'polygon(50% 0%, 100% 100%, 100% 100%, 0% 100%, 0% 0%)',
	'polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)',
];

const CLIP_PATH_TR_EXIT = [
	'polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0)',
	'polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 25%)',
	'polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 50%)',
	'polygon(75% 0, 100% 0, 100% 100%, 0 100%, 0 75%)',
	'polygon(100% 25%, 100% 25%, 100% 100%, 25% 100%, 25% 100%)',
	'polygon(100% 50%, 100% 50%, 100% 100%, 50% 100%, 50% 100%)',
	'polygon(100% 75%, 100% 75%, 100% 100%, 75% 100%, 75% 100%)',
	'polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%)',
];

const CLIP_PATH_BL = [
	'polygon(0% 0%, 100% 0%, 100% 100%, 100% 0%, 100% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 50% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%)',
];

const CLIP_PATH_BL_EXIT = [
	'polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 75%, 75% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 50%, 50% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 25%, 25% 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 0%, 0% 100%, 0 100%, 0 0)',
	'polygon(75% 0, 75% 0%, 0% 75%, 0 75%, 0 0)',
	'polygon(50% 0, 50% 0%, 0% 50%, 0 50%, 0 0)',
	'polygon(25% 0, 25% 0%, 0% 25%, 0 25%, 0 0)',
	'polygon(0% 0, 0% 0%, 0% 0%, 0 0%, 0 0)',
];

const CLIP_PATH_BR = [
	'polygon(0% 0%, 100% 0%, 0% 0%, 0% 100%, 0% 0%)',
	'polygon(50% 0%, 100% 0%, 0% 0%, 0% 100%, 0% 100%)',
	'polygon(100% 0%, 100% 0%, 0% 0%, 0% 100%, 50% 100%)',
	'polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%, 100% 100%)',
];

const CLIP_PATH_BR_EXIT = [
	'polygon(100% 0, 100% 100%, 0 100%, 0 100%, 0 0)',
	'polygon(100% 0, 100% 100%, 25% 100%, 0 75%, 0 0)',
	'polygon(100% 0, 100% 100%, 50% 100%, 0 50%, 0 0)',
	'polygon(100% 0, 100% 100%, 75% 100%, 0 25%, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 100%, 0 0%, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 75%, 25% 0, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 50%, 50% 0, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 25%, 75% 0, 0 0)',
	'polygon(100% 0, 100% 100%, 100% 0%, 100% 0, 0 0)',
];

export const TOP_LEFT_VARIANTS = (BG?: boolean) => ({
	initial: {
		clipPath: CLIP_PATH_TL[0],
	},

	animate: {
		clipPath: CLIP_PATH_TL,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + (BG ? BG_DELAY : 0),
		},
	},
	exit: {
		opacity: 1,
		clipPath: CLIP_PATH_TL_EXIT,
		transition: {
			duration: BG_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
});

export const TOP_RIGHT_VARIANTS = (BG?: boolean) => ({
	initial: {
		clipPath: CLIP_PATH_TR[0],
	},

	animate: {
		clipPath: CLIP_PATH_TR,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + (BG ? BG_DELAY : 0),
		},
	},
	exit: {
		opacity: 1,
		clipPath: CLIP_PATH_TR_EXIT,
		transition: {
			duration: BG_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
});

export const BOTTOM_LEFT_VARIANTS = (BG?: boolean) => ({
	initial: {
		clipPath: CLIP_PATH_BL[0],
	},

	animate: {
		clipPath: CLIP_PATH_BL,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + ROW_DELAY + (BG ? BG_DELAY : 0),
		},
	},
	exit: {
		opacity: 1,
		clipPath: CLIP_PATH_BL_EXIT,
		transition: {
			duration: BG_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
});

export const BOTTOM_RIGHT_VARIANTS = (BG?: boolean) => ({
	initial: {
		clipPath: CLIP_PATH_BR[0],
	},

	animate: {
		clipPath: CLIP_PATH_BR,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + ROW_DELAY + (BG ? BG_DELAY : 0),
		},
	},
	exit: {
		opacity: 1,
		clipPath: CLIP_PATH_BR_EXIT,
		transition: {
			duration: BG_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
});

export const CONTENT_VARIANTS = (index: number) => ({
	initial: {
		opacity: 0,
		y: 18,
	},

	animate: {
		opacity: 1,
		y: 0,
		transition: {
			duration: BG_DURATION,
			ease: 'linear',
			delay: START_DELAY + ROW_DELAY + BG_DELAY + 0.2 * index,
		},
	},

	exit: {
		opacity: 0,
		y: 18,
		transition: {
			duration: BG_DURATION / 2,
			ease: 'linear',
			delay: 0,
		},
	},
});

const MOBILE_VARIANTS = (index: number, BG?: boolean) => ({
	...BOTTOM_RIGHT_VARIANTS(BG),

	animate: {
		...BOTTOM_RIGHT_VARIANTS(BG).animate,
		transition: {
			...BOTTOM_RIGHT_VARIANTS(BG).animate.transition,
			delay: START_DELAY + (BG ? BG_DELAY : 0) + 0.2 * index,
		},
	},
});

export const ANIMATION_VARIANTS = {
	TopLeft: TOP_LEFT_VARIANTS,
	TopRight: TOP_RIGHT_VARIANTS,
	BottomLeft: BOTTOM_LEFT_VARIANTS,
	BottomRight: BOTTOM_RIGHT_VARIANTS,
	Mobile: BOTTOM_RIGHT_VARIANTS,
};

export const getMobileVariant = (
	position: 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight',
	BG?: boolean,
) => {
	const positions = ['TopLeft', 'TopRight', 'BottomLeft', 'BottomRight'];

	return MOBILE_VARIANTS(positions.indexOf(position), BG);
};

export const getContentVariant = (
	position: 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight',
	mobile?: boolean,
) => {
	const positions = ['TopLeft', 'TopRight', 'BottomLeft', 'BottomRight'];

	return mobile
		? CONTENT_VARIANTS(positions.indexOf(position))
		: CONTENT_VARIANTS(0);
};

import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, createContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ContentSection, zIndex } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { Menu, NavBarItem } from '@home/components/organisms';
import { FooterSectionContent } from '@home/sections';
import { Size } from '@home/types';

interface SMenuContainerProps {
	zIndex: number;
	size: Size;
}

const SMenuContainer = styled(motion.div)<SMenuContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	height: ${({ size }) => size.height}px;
	width: ${({ size }) => size.width}px;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: ${(props) => props.zIndex};
`;

interface MenuContextInterface {
	visible: boolean;
	active: ContentSection;
	items: NavBarItem[];
	set: (data: {
		visible?: boolean;
		active?: ContentSection;
		items?: NavBarItem[];
		footer?: FooterSectionContent;
	}) => void;
	footer?: FooterSectionContent;
}

export interface MenuProviderProps {
	children: ReactNode | ReactNode[];
	initialSection?: ContentSection;
}

export const MenuContext = createContext<MenuContextInterface>({
	visible: false,
	active: ContentSection.Intro,
	items: [],
	set: () => undefined,
	footer: undefined,
});

const MenuContainerFramerVariants = {
	initial: { opacity: 0 },
	animate: {
		opacity: 1,
		transition: {
			duration: 0.6,
			staggerChildren: 0.08,
			delayChildren: 0.1,
		},
	},
	exit: { opacity: 0 },
};

export const MenuProvider = ({ children, initialSection }: MenuProviderProps) => {
	const [menuContext, setMenuContext] = useState<{
		visible: boolean;
		active: ContentSection;
		items: NavBarItem[];
		footer?: FooterSectionContent;
	}>({
		visible: false,
		active: initialSection ?? ContentSection.Intro,
		items: [],
		footer: undefined,
	});

	const size = useWindowSize();

	const _set = (data: {
		visible?: boolean;
		active?: ContentSection;
		items?: NavBarItem[];
		footer?: FooterSectionContent;
	}) => {
		setMenuContext((v) => ({
			visible: data.visible ?? v.visible,
			active: data.active ?? v.active,
			items: data.items ?? v.items,
			footer: data.footer ?? v.footer,
		}));
	};

	useEffect(() => {
		setMenuContext((v) => ({
			visible: v.visible,
			active: initialSection ?? ContentSection.Intro,
			items: v.items,
			footer: v.footer,
		}));
	}, [initialSection]);

	return (
		<MenuContext.Provider
			value={{
				set: _set,
				...menuContext,
			}}
		>
			{children}

			<AnimatePresence>
				{menuContext.visible && (
					<SMenuContainer
						animate="animate"
						data-Menuclick="Menu-background"
						exit="exit"
						initial="initial"
						size={size}
						variants={MenuContainerFramerVariants}
						zIndex={zIndex.MODAL}
					>
						<Menu items={menuContext.items} />
					</SMenuContainer>
				)}
			</AnimatePresence>
		</MenuContext.Provider>
	);
};

import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { DynamicAnimationLoader } from '@home/components/atoms';

const HEIGHT = 57.8;
const WIDTH = 566.1;

const SDiscordHudElement = styled.div``;

const SDiscordCTAElement = styled.div<{
	height: number;
	width: number;
	scale: number;
}>`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	top: ${({ scale }) => scale * 56}px;

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: ${Breakpoint.LARGE}px) {
		top: 0;
	}
`;

const SDiscordCTALabel = styled.a`
	font-size: 16px;
	letter-spacing: 0.1em;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 14px;
		letter-spacing: 0.06em;
		margin-top: -10px;
	}
`;

const SDiscordHudElementContainer = styled.div<{
	height: number;
	width: number;
	scale: number;
}>`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	margin-top: ${({ scale }) => 62 * scale}px;
	margin-bottom: 0px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		margin-top: auto;
		margin-bottom: 16px;
	}
`;

export interface DiscordHudElementProps {
	id: string;
	filename: string;
	cta?: {
		label: string;
		link: string;
	};
}

export const DiscordHudElement: FC<DiscordHudElementProps> = (
	props: DiscordHudElementProps,
) => {
	const { hudScale } = useWindowSize();

	return (
		<SDiscordHudElement className="relative">
			<SDiscordHudElementContainer
				height={HEIGHT * hudScale}
				scale={hudScale}
				width={WIDTH * hudScale}
			>
				<DynamicAnimationLoader
					autoplay={false}
					filename={props.filename}
					id={props.id}
					loop={false}
				/>
			</SDiscordHudElementContainer>

			{props.cta && (
				<SDiscordCTAElement
					className="absolute flex flex-row justify-center pointer-events-auto"
					height={HEIGHT * hudScale}
					scale={hudScale}
					width={WIDTH * hudScale}
				>
					<SDiscordCTALabel
						className="px-24 mt-1 text-offWhite"
						href={props.cta.link}
						rel="noreferrer"
						target="_blank"
					>
						{props.cta.label}
					</SDiscordCTALabel>
				</SDiscordCTAElement>
			)}
		</SDiscordHudElement>
	);
};

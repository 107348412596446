import { FC } from 'react';

export const GameOverviewFeatureFrameTopLeft: FC = () => {
	return (
		<svg
			fill="none"
			preserveAspectRatio="xMidYMid"
			viewBox="0 0 413 157"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.9102 147L17.0002 152H371L376.91 147H12.9102Z"
				fill="#FFF1DF"
			/>
			<path
				d="M360.27 9C361.1 9 361.89 9.25 362.57 9.73L396 32.94C397.88 34.25 399.01 36.4 399.01 38.69V125.8C399.01 127.87 398.1 129.83 396.51 131.16L372.43 151.36C371.17 152.42 369.57 153 367.93 153H17.94L3.95996 136.22V14.89L10.55 9H360.27"
				stroke="#FFF1DF"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="M15.88 155.93C16.45 156.61 17.29 157 18.18 157H380.13C382.25 157 384.3 156.25 385.92 154.89L407.73 136.59C410.45 134.31 412.02 130.94 412.02 127.4V37.24C412.02 33.32 410.11 29.66 406.9 27.41L371.5 2.61998C369.99 1.55998 368.18 0.98999 366.34 0.98999H13.46L0 12.99V136.99L15.88 155.92V155.93ZM1.95999 14L9.79001 6.99998H360.27C361.5 6.99998 362.7 7.37999 363.71 8.07999L397.14 31.29C399.56 32.97 401.01 35.73 401.01 38.68V125.79C401.01 128.45 399.83 130.97 397.8 132.68L373.72 152.88C372.1 154.24 370.05 154.99 367.93 154.99H17L2 136.99H1.95001V13.99L1.95999 14Z"
				fill="url(#paint0_linear_188_3803)"
			/>
			<path
				d="M40.83 129.42L44.58 125.67L48.33 129.42L44.58 133.17L40.83 129.42ZM38 129.42L44.58 136L51.16 129.42L44.58 122.84L38 129.42Z"
				fill="#FFF1DF"
			/>
			<path
				d="M353.5 141C360.404 141 366 135.404 366 128.5C366 121.596 360.404 116 353.5 116C346.596 116 341 121.596 341 128.5C341 135.404 346.596 141 353.5 141Z"
				fill="#BF3054"
			/>
			<path d="M353 126H356V129H358V124H353V126Z" fill="#FFF1DF" />
			<path d="M354 131H351V128H349V133H354V131Z" fill="#FFF1DF" />
			<path
				d="M45.5801 24.77V124.54C45.5801 124.96 45.1301 125.31 44.5801 125.31C44.0301 125.31 43.5801 124.97 43.5801 124.54V24.77C43.5801 24.35 44.0301 24 44.5801 24C45.1301 24 45.5801 24.34 45.5801 24.77Z"
				fill="url(#paint1_linear_188_3803)"
			/>
			<defs>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint0_linear_188_3803"
					x1="0"
					x2="412"
					y1="78.99"
					y2="78.99"
				>
					<stop stopColor="#741F7A" />
					<stop offset="1" stopColor="#BF3054" />
				</linearGradient>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint1_linear_188_3803"
					x1="44.5801"
					x2="44.5801"
					y1="125.31"
					y2="24"
				>
					<stop stopColor="#C935D4" />
					<stop offset="1" stopColor="#9928A1" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

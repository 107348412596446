import React from 'react';
import styled from 'styled-components';

import { ImageAsset, IndicatorIcon, Text } from '@home/components/atoms';
import { CardContent } from '@home/types';

const STitle = styled(Text)`
	position: absolute;
	z-index: 10;
	bottom: 13px;
	left: 32px;
	text-align: left;
	width: 132px;
`;

const Indicator = styled.div`
	position: absolute;
	z-index: 10;
	bottom: 22px;
	left: 10px;
`;

const Gradient = styled.div`
	position: absolute;
	z-index: 9;
	bottom: 0;
	height: 50%;
	width: 100%;
	background: linear-gradient(
		0deg,
		#3b113f 4.76%,
		rgba(59, 17, 63, 0.75) 58.73%,
		rgba(59, 17, 63, 0) 100%
	);
`;
interface CardProps {
	content: CardContent;
	className?: string;
}

export const Card = ({ content, className = '' }: CardProps) => {
	const { title, image } = content;
	const cardClasses = `${className}`;

	return (
		<div className={cardClasses}>
			{image && <ImageAsset id={image.id} url={image.url} />}
			{title && <STitle>{title}</STitle>}
			<Indicator>
				<IndicatorIcon active />
			</Indicator>
			<Gradient />
		</div>
	);
};

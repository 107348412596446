import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import {
	GenesisBackground,
	GenesisBorder,
	GenesisImageAnimator,
} from '@home/components/atoms';
import { CardContent } from '@home/types';

import {
	LEFT_VARIANTS,
	RIGHT_VARIANTS,
	keyframesLeftToRight,
	keyframesRightToLeft,
} from './animations';

export type Position =
	| 'before'
	| 'left'
	| 'centerLeft'
	| 'centerRight'
	| 'center'
	| 'right'
	| 'after';

const Card = styled(motion.div)<{
	position: Position;
	edgeCardMaxWidth: number;
}>`
	${({ edgeCardMaxWidth }) => keyframesRightToLeft(edgeCardMaxWidth)}
	${({ edgeCardMaxWidth }) => keyframesLeftToRight(edgeCardMaxWidth)}
  width: 100%;
	aspect-ratio: 404.79/447.93;
	max-width: 404.79px;
	z-index: 9;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		max-width: 300px;
	}

	@media (max-width: ${Breakpoint.SMALL}px) {
		max-width: 200px;
	}

	@media (max-width: ${Breakpoint.XS}px) {
		max-width: 150px;
	}

	${({ position, edgeCardMaxWidth }) =>
		position === 'left'
			? `
      transform: matrix3d(1,0,0.00, 0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      z-index: 10;
    `
			: position === 'right'
			? `
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      z-index: 10;
    `
			: position === 'before'
			? `
        position: absolute;
        pointer-events: none;
        opacity: 0;
        transform: matrix3d(1,0,0.00,0.0015,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.5);
        left: -32%;
        max-width: ${edgeCardMaxWidth}px;
      `
			: position === 'after'
			? `
        position: absolute;
        pointer-events: none;
        opacity: 0;
        transform: matrix3d(1,0,0.00,-0.0015,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.5);
        right: -32%;
        max-width: ${edgeCardMaxWidth}px;
      `
			: ''}
`;

const SImage = styled.div`
	z-index: 2;
	position: absolute;
	top: 4%;
	left: 5%;
`;

const SClippedContainer = styled.div<{
	clipPath: string;
}>`
	aspect-ratio: 1; // Very important little *ucker!!!
	width: 100%;
	clip-path: ${(props) => props.clipPath};
`;

export interface GenesisCardProps {
	content: CardContent;
	position: Position;
	edgeCardMaxWidth: number;
	initialAnimation: boolean;
	exitAnimation: boolean;
	onClick?: (id: string) => void;
}

export const GenesisCard = ({
	content,
	position,
	edgeCardMaxWidth,
	initialAnimation,
	exitAnimation,
	onClick,
}: GenesisCardProps) => {
	const wSize = useWindowSize();
	const { image } = content;
	const element = useRef<HTMLDivElement>(null);
	const [currentPos, setCurrentPos] = useState(position);

	useEffect(() => {
		const div = element.current;
		const animationDuration = 500;
		const animationEasing = `${animationDuration}ms linear`;

		if (div) {
			/** Moving to the right */
			if (position === 'right' && currentPos === 'after') {
				div.style.animation = 'afterToRight ' + animationEasing;
			}

			if (position === 'centerRight' && currentPos === 'right') {
				div.style.animation = 'rightToCenterRight ' + animationEasing;
			}

			if (position === 'center' && currentPos === 'right') {
				div.style.animation = 'rightToCenter ' + animationEasing;
			}

			if (position === 'left' && currentPos === 'center') {
				div.style.animation = 'centerToLeft ' + animationEasing;
			}

			if (position === 'centerLeft' && currentPos === 'centerRight') {
				div.style.animation = 'centerRightToCenterLeft ' + animationEasing;
			}

			if (position === 'left' && currentPos === 'centerLeft') {
				div.style.animation = 'centerLeftToLeft ' + animationEasing;
			}

			if (position === 'before' && currentPos === 'left') {
				div.style.animation = 'leftToBefore ' + animationEasing;
			}

			/** Moving to the left */
			if (position === 'left' && currentPos === 'before') {
				div.style.animation = 'beforeToLeft ' + animationEasing;
			}

			if (position === 'centerLeft' && currentPos === 'left') {
				div.style.animation = 'leftToCenterLeft ' + animationEasing;
			}

			if (position === 'center' && currentPos === 'left') {
				div.style.animation = 'leftToCenter ' + animationEasing;
			}

			if (position === 'right' && currentPos === 'center') {
				div.style.animation = 'centerToRight ' + animationEasing;
			}

			if (position === 'centerRight' && currentPos === 'centerLeft') {
				div.style.animation = 'centerLeftToCenterRight ' + animationEasing;
			}

			if (position === 'right' && currentPos === 'centerRight') {
				div.style.animation = 'centerRightToRight ' + animationEasing;
			}

			if (position === 'after' && currentPos === 'right') {
				div.style.animation = 'rightToAfter ' + animationEasing;
			}
		}

		setCurrentPos(position);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [position]);

	const isBeforeCard = () => position === 'before';
	const isAfterCard = () => position === 'after';

	const _onClick = () => {
		if (isBeforeCard() || isAfterCard() || !onClick) return;
		onClick(content.id);
	};

	const getVariant = () => {
		if (position === 'left') {
			return LEFT_VARIANTS(0.4);
		}

		if (position === 'centerLeft') {
			return LEFT_VARIANTS(0);
		}

		if (position === 'center') {
			return RIGHT_VARIANTS(0);
		}

		if (position === 'centerRight') {
			return RIGHT_VARIANTS(0);
		}

		if (position === 'right') {
			return RIGHT_VARIANTS(0.4);
		}

		return RIGHT_VARIANTS(0);
	};

	return wSize.width < Breakpoint.LARGE &&
		(isBeforeCard() || isAfterCard()) ? null : (
		<Card
			ref={element}
			animate={initialAnimation ? 'animate' : 'show'}
			className={`relative ${
				!isBeforeCard() && !isAfterCard()
					? 'cursor-grabbing lg:cursor-pointer'
					: ''
			}`}
			edgeCardMaxWidth={edgeCardMaxWidth}
			exit={exitAnimation ? 'exit' : 'show'}
			id={position}
			position={position}
			variants={getVariant()}
			onClick={_onClick}
		>
			<SImage className="w-full h-full">
				<SClippedContainer clipPath="url(#CLIP_PATH_GENESIS_CARD)">
					{image && <GenesisImageAnimator image={image.url} />}
				</SClippedContainer>
			</SImage>
			<GenesisBorder />
			<GenesisBackground />
		</Card>
	);
};

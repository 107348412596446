import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { ExternalAsset } from '@home/types';

import { VIDEO_TRANSITION } from './animations';
import { VideoFrame, VideoOverlay } from './components';

const SVideoDiv = styled.div`
	/* img {
    position: absolute;
    top: 0;
    width: 100%;
    aspect-ratio: 16/9;
  } */
`;

const SIframe = styled.iframe`
	width: 100%;
	aspect-ratio: 16/9;
`;

export interface VideoProps {
	videoUrl: string;
	staticImage: ExternalAsset;
	overlayText: string;
}

const imageAnimationVariants = {
	hidden: { opacity: 0 },
	show: { opacity: 1 },
};

export const Video: FC<VideoProps> = (props: VideoProps) => {
	const [videoOpen, setVideoOpen] = useState(false);

	const _openVideo = () => {
		setVideoOpen(true);
	};

	return (
		<SVideoDiv className="relative w-11/12 lg:w-9/12">
			<VideoFrame />

			<motion.div
				className="p-3 md:p-5 box-border relative border-transparent"
				variants={VIDEO_TRANSITION}
			>
				<motion.div
					animate={videoOpen ? 'hidden' : 'show'}
					className={videoOpen ? 'pointer-events-none' : ''}
					initial="show"
					variants={imageAnimationVariants}
					onClick={videoOpen ? undefined : _openVideo}
				>
					<VideoOverlay text={props.overlayText} />
				</motion.div>

				<SIframe
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					src={props.videoUrl}
					title="Game Trailer Video Player"
					// allowfullscreen
				></SIframe>

				<motion.img
					alt="static image"
					animate={videoOpen ? 'hidden' : 'show'}
					className={`p-3 md:p-5 absolute top-0 left-0 aspect-video w-full h-full ${
						videoOpen ? 'pointer-events-none' : ''
					}`}
					initial="show"
					src={props.staticImage.url}
					variants={imageAnimationVariants}
				/>
			</motion.div>
		</SVideoDiv>
	);
};

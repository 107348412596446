import { FC } from 'react';

import { GameOverviewFeatureFrameBottomLeft } from './GameOverviewFeatureFrameBottomLeft';
import { GameOverviewFeatureFrameBottomRight } from './GameOverviewFeatureFrameBottomRight';
import { GameOverviewFeatureFrameMobile } from './GameOverviewFeatureFrameMobile';
import { GameOverviewFeatureFrameTopLeft } from './GameOverviewFeatureFrameTopLeft';
import { GameOverviewFeatureFrameTopRight } from './GameOverviewFeatureFrameTopRight';

export class Svg {
	static GameOverviewFeatureFrameTopLeft: FC = () => (
		<GameOverviewFeatureFrameTopLeft />
	);

	static GameOverviewFeatureFrameTopRight: FC = () => (
		<GameOverviewFeatureFrameTopRight />
	);

	static GameOverviewFeatureFrameBottomLeft: FC = () => (
		<GameOverviewFeatureFrameBottomLeft />
	);

	static GameOverviewFeatureFrameBottomRight: FC = () => (
		<GameOverviewFeatureFrameBottomRight />
	);

	static GameOverviewFeatureFrameMobile: FC = () => (
		<GameOverviewFeatureFrameMobile />
	);
}

import lottie from 'lottie-web';
import getConfig from 'next/config';
import { FC, useEffect, useRef } from 'react';

import { GlobalLottieAnimations } from '@common/globals';

export interface DynamicAnimationLoaderProps {
	id: string;
	filename: string;
	loop?: boolean;
	autoplay?: boolean;
}

export const DynamicAnimationLoader: FC<DynamicAnimationLoaderProps> = ({
	id,
	filename,
	loop = false,
	autoplay = false,
}: DynamicAnimationLoaderProps) => {
	const { publicRuntimeConfig } = getConfig();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref !== undefined && ref.current !== null) {
			const animation = lottie.loadAnimation({
				name: id,
				container: ref.current,
				renderer: 'svg',
				loop: loop,
				autoplay: autoplay,
				path: `${publicRuntimeConfig.basePath}/static/animations/${filename}.json`,
			});

			lottie.setQuality('low');
			GlobalLottieAnimations.set(id, animation);

			return () => animation.destroy();
		}
	}, [filename, loop, autoplay, id, publicRuntimeConfig.basePath]);

	return <div ref={ref} className="h-full will-change-transform" />;
};

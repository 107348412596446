import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { useWindowSize } from '@common/hooks';

const SCALE_FACTOR = 0.85;

export interface ArchDetailsProps {
	flipX?: boolean;
	flipY?: boolean;
}

const SArchDetails = styled(motion.div)<{
	left?: number;
	top?: number;
	bottom?: number;
	right?: number;
}>`
	left: ${(props) => props.left}px;
	right: ${(props) => props.right}px;
	top: ${(props) => props.top}px;
	bottom: ${(props) => props.bottom}px;
`;

const MARGINS: {
	topLeft: { left: { [k: string]: number }; top: { [k: string]: number } };
	bottomLeft: {
		left: { [k: string]: number };
		bottom: { [k: string]: number };
	};
	topRight: { right: { [k: string]: number }; top: { [k: string]: number } };
} = {
	topLeft: {
		left: { '1.2': 210, '1': 170, '0.95': 160, '0.78': 124, '0.7': 106 },
		top: { '1.2': 240, '1': 200, '0.95': 190, '0.78': 156, '0.7': 140 },
	},

	bottomLeft: {
		left: { '1.2': 210, '1': 170, '0.95': 160, '0.78': 124, '0.7': 106 },
		bottom: { '1.2': 310, '1': 260, '0.95': 250, '0.78': 200, '0.7': 182 },
	},

	topRight: {
		right: { '1.2': 210, '1': 170, '0.95': 160, '0.78': 124, '0.7': 106 },
		top: { '1.2': 240, '1': 200, '0.95': 190, '0.78': 156, '0.7': 140 },
	},
};

export const ArchDetails: FC<ArchDetailsProps> = (props) => {
	const { hudScale } = useWindowSize();

	if (props.flipX && props.flipY) {
		return null;
	}

	if (props.flipX) {
		return (
			<SArchDetails
				animate={{ opacity: 1 }}
				className="absolute"
				initial={{ opacity: 0 }}
				right={MARGINS.topRight.right[hudScale.toString()]}
				top={MARGINS.topRight.top[hudScale.toString()]}
				transition={{ delay: 4.4 }}
			>
				<svg
					fill="none"
					height={224 * SCALE_FACTOR * hudScale}
					viewBox="0 0 82 224"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clipPath="url(#clip0_53_2)">
						<path
							d="M5.91016 11.8199H8.86016L11.8202 5.90991H8.86016L5.91016 11.8199Z"
							fill="#BF3054"
						/>
						<path
							d="M0 17.6901L2.95 17.7301L5.91 11.8201H2.95L0 17.6901Z"
							fill="#BF3054"
						/>
						<path
							d="M5.90998 0H2.94998L1.47998 2.95H4.42998L5.90998 0Z"
							fill="#BF3054"
						/>
						<path
							d="M11.8201 0H8.86014L7.39014 2.95H10.3401L11.8201 0Z"
							fill="#BF3054"
						/>
						<path
							d="M27.6897 45.21H24.7397V48.16H27.6897V45.21Z"
							fill="#BF3054"
						/>
						<path
							d="M24.7398 51.1199L21.7798 57.0299H24.7398H27.6898V54.0799H26.2098L27.6898 51.1199H24.7398Z"
							fill="#BF3054"
						/>
						<path
							d="M33.5999 45.21H30.6499L27.6899 51.12H30.6499L33.5999 45.21Z"
							fill="#BF3054"
						/>
						<path
							d="M21.7798 59.98V62.94H24.7398H27.6898L30.6498 57.03H27.6898L26.2098 59.98H21.7798Z"
							fill="#BF3054"
						/>
						<path
							d="M60.7398 147.11V144.16L54.8298 141.2V144.16L60.7398 147.11Z"
							fill="#BF3054"
						/>
						<path
							d="M54.8298 129.39V132.34L60.7398 135.29V132.34L54.8298 129.39Z"
							fill="#BF3054"
						/>
						<path
							d="M48.9199 147.08L51.8699 147.11L54.8299 141.2H51.8699L48.9199 147.08Z"
							fill="#BF3054"
						/>
						<path
							d="M50.3999 132.34H53.3499L54.8299 129.39H51.8699L50.3999 132.34Z"
							fill="#BF3054"
						/>
						<path
							d="M54.8298 135.29V141.2H57.7798H60.7398V135.29H57.7798H54.8298Z"
							fill="#BF3054"
						/>
						<path
							d="M81.8302 208.31V205.35H78.8802H75.9202V211.26H78.8802V208.31H81.8302Z"
							fill="#BF3054"
						/>
						<path
							d="M78.8802 217.17L81.8302 211.26H78.8802L75.9202 217.17H78.8802Z"
							fill="#BF3054"
						/>
						<path
							d="M70.02 223.08H72.97L75.92 217.17H72.97L70.02 223.08Z"
							fill="#BF3054"
						/>
						<path
							d="M67.0601 217.17L64.1101 223.05L67.0601 223.08L70.0201 217.17H67.0601Z"
							fill="#BF3054"
						/>
						<path
							d="M72.97 211.26L70.02 217.14L72.97 217.17L75.92 211.26H72.97Z"
							fill="#BF3054"
						/>
						<path
							d="M72.97 205.35L70.02 211.23L72.97 211.26L75.92 205.35H72.97Z"
							fill="#BF3054"
						/>
					</g>
					<defs>
						<clipPath id="clip0_53_2">
							<rect fill="white" height="223.08" width="81.83" />
						</clipPath>
					</defs>
				</svg>
			</SArchDetails>
		);
	}

	if (props.flipY) {
		return (
			<SArchDetails
				animate={{ opacity: 1 }}
				bottom={MARGINS.bottomLeft.bottom[hudScale.toString()]}
				className="absolute"
				initial={{ opacity: 0 }}
				left={MARGINS.bottomLeft.left[hudScale.toString()]}
				transition={{ delay: 4.4 }}
			>
				<svg
					fill="none"
					height={224 * SCALE_FACTOR * hudScale}
					viewBox="0 0 81 224"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clipPath="url(#clip0_53_26)">
						<path
							d="M75.0601 217.7H78.0201L80.9701 211.79H78.0201L75.0601 217.7Z"
							fill="#BF3054"
						/>
						<path
							d="M69.1499 223.58L72.1099 223.61L75.0599 217.7H72.1099L69.1499 223.58Z"
							fill="#BF3054"
						/>
						<path
							d="M75.0599 205.88H72.1099L70.6299 208.84H73.5899L75.0599 205.88Z"
							fill="#BF3054"
						/>
						<path
							d="M80.97 205.88H78.02L76.54 208.84H79.49L80.97 205.88Z"
							fill="#BF3054"
						/>
						<path
							d="M54.0701 159.52H51.1201V162.47H54.0701V159.52Z"
							fill="#BF3054"
						/>
						<path
							d="M51.1202 165.43L48.1602 171.34H51.1202H54.0702V168.38H52.5902L54.0702 165.43H51.1202Z"
							fill="#BF3054"
						/>
						<path
							d="M59.9803 159.52H57.0203L54.0703 165.43H57.0203L59.9803 159.52Z"
							fill="#BF3054"
						/>
						<path
							d="M48.1602 174.29V177.24H51.1202H54.0702L57.0202 171.34H54.0702L52.5902 174.29H48.1602Z"
							fill="#BF3054"
						/>
						<path
							d="M32.4002 93.2901V90.3301L26.4902 87.3801V90.3301L32.4002 93.2901Z"
							fill="#BF3054"
						/>
						<path
							d="M26.4902 75.5601V78.5101L32.4002 81.4701V78.5101L26.4902 75.5601Z"
							fill="#BF3054"
						/>
						<path
							d="M20.5801 93.2501L23.5401 93.2901L26.4901 87.3801H23.5401L20.5801 93.2501Z"
							fill="#BF3054"
						/>
						<path
							d="M22.0601 78.5101H25.0101L26.4901 75.5601H23.5401L22.0601 78.5101Z"
							fill="#BF3054"
						/>
						<path
							d="M26.4902 81.47V87.38H29.4502H32.4002V81.47H29.4502H26.4902Z"
							fill="#BF3054"
						/>
						<path
							d="M17.7298 2.95V0H14.7698H11.8198V5.91H14.7698V2.95H17.7298Z"
							fill="#BF3054"
						/>
						<path
							d="M14.7698 11.8199L17.7298 5.90991H14.7698L11.8198 11.8199H14.7698Z"
							fill="#BF3054"
						/>
						<path
							d="M5.91016 17.7301H8.86016L11.8202 11.8201H8.86016L5.91016 17.7301Z"
							fill="#BF3054"
						/>
						<path
							d="M2.95 11.8201L0 17.6901L2.95 17.7301L5.91 11.8201H2.95Z"
							fill="#BF3054"
						/>
						<path
							d="M8.86016 5.90991L5.91016 11.7899L8.86016 11.8199L11.8202 5.90991H8.86016Z"
							fill="#BF3054"
						/>
						<path
							d="M8.86016 0L5.91016 5.88L8.86016 5.91L11.8202 0H8.86016Z"
							fill="#BF3054"
						/>
					</g>
					<defs>
						<clipPath id="clip0_53_26">
							<rect fill="white" height="223.61" width="80.97" />
						</clipPath>
					</defs>
				</svg>
			</SArchDetails>
		);
	}

	return (
		<SArchDetails
			animate={{ opacity: 1 }}
			className="absolute"
			initial={{ opacity: 0 }}
			left={MARGINS.topLeft.left[hudScale.toString()]}
			top={MARGINS.topLeft.top[hudScale.toString()]}
			transition={{ delay: 4.4 }}
		>
			<svg
				fill="none"
				height={224 * SCALE_FACTOR * hudScale}
				viewBox="0 0 81 224"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_53_50)">
					<path
						d="M75.0601 11.8199H78.0201L80.9701 5.90991H78.0201L75.0601 11.8199Z"
						fill="#BF3054"
					/>
					<path
						d="M69.1499 17.6901L72.1099 17.7301L75.0599 11.8201H72.1099L69.1499 17.6901Z"
						fill="#BF3054"
					/>
					<path
						d="M75.0599 0H72.1099L70.6299 2.95H73.5899L75.0599 0Z"
						fill="#BF3054"
					/>
					<path d="M80.97 0H78.02L76.54 2.95H79.49L80.97 0Z" fill="#BF3054" />
					<path
						d="M54.8699 45.21H51.9199V48.16H54.8699V45.21Z"
						fill="#BF3054"
					/>
					<path
						d="M51.92 51.1199L48.96 57.0299H51.92H54.87V54.0799H53.4L54.87 51.1199H51.92Z"
						fill="#BF3054"
					/>
					<path
						d="M60.7801 45.21H57.8301L54.8701 51.12H57.8301L60.7801 45.21Z"
						fill="#BF3054"
					/>
					<path
						d="M48.96 59.98V62.94H51.92H54.87L57.83 57.03H54.87L53.4 59.98H48.96Z"
						fill="#BF3054"
					/>
					<path
						d="M31.7801 147.11V144.16L25.8701 141.2V144.16L31.7801 147.11Z"
						fill="#BF3054"
					/>
					<path
						d="M25.8701 129.39V132.34L31.7801 135.29V132.34L25.8701 129.39Z"
						fill="#BF3054"
					/>
					<path
						d="M19.96 147.08L22.92 147.11L25.87 141.2H22.92L19.96 147.08Z"
						fill="#BF3054"
					/>
					<path
						d="M21.4399 132.34H24.3899L25.8699 129.39H22.9199L21.4399 132.34Z"
						fill="#BF3054"
					/>
					<path
						d="M25.8701 135.29V141.2H28.8301H31.7801V135.29H28.8301H25.8701Z"
						fill="#BF3054"
					/>
					<path
						d="M17.7298 208.31V205.35H14.7698H11.8198V211.26H14.7698V208.31H17.7298Z"
						fill="#BF3054"
					/>
					<path
						d="M14.7698 217.17L17.7298 211.26H14.7698L11.8198 217.17H14.7698Z"
						fill="#BF3054"
					/>
					<path
						d="M5.91016 223.08H8.86016L11.8202 217.17H8.86016L5.91016 223.08Z"
						fill="#BF3054"
					/>
					<path
						d="M2.95 217.17L0 223.05L2.95 223.08L5.91 217.17H2.95Z"
						fill="#BF3054"
					/>
					<path
						d="M8.86016 211.26L5.91016 217.14L8.86016 217.17L11.8202 211.26H8.86016Z"
						fill="#BF3054"
					/>
					<path
						d="M8.86016 205.35L5.91016 211.23L8.86016 211.26L11.8202 205.35H8.86016Z"
						fill="#BF3054"
					/>
				</g>
				<defs>
					<clipPath id="clip0_53_50">
						<rect fill="white" height="223.08" width="80.97" />
					</clipPath>
				</defs>
			</svg>
		</SArchDetails>
	);
};

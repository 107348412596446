import React, { FC, useEffect, useState } from 'react';

import { getActiveElements } from '@common/utils';
import { Interval } from '@home/types';

interface IndicatorProps {
	length: number;
	active: Interval;
}

export const Indicator: FC<IndicatorProps> = ({
	length,
	active,
}: IndicatorProps) => {
	const [elements, setElements] = useState(Array.from(Array(length).keys()));

	useEffect(() => {
		setElements(Array.from(Array(length).keys()));
	}, [length]);

	const isActive = (index: number) =>
		getActiveElements(elements, active).includes(index);

	return (
		<div className="flex gap-x-2 align-center justify-center">
			{elements.map((element) => (
				<div
					key={element}
					className={`rounded-full h-2 w-2 ${
						isActive(element) ? `bg-orange-400` : `bg-white`
					}`}
				/>
			))}
		</div>
	);
};

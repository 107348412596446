import { FC } from 'react';
import styled from 'styled-components';

import { useWindowSize } from '@common/hooks';
import { DynamicAnimationLoader } from '@home/components/atoms';

const HEIGHT = 170;
const WIDTH = 622;

interface SSpeedMeterHudElementAnimationContainerProps {
	flipX?: boolean;
	height: number;
	width: number;
}

const SSpeedMeterHudElement = styled.div``;

const SSpeedMeterHudElementAnimationContainer = styled.div<SSpeedMeterHudElementAnimationContainerProps>`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	transform: ${(props) => (props.flipX ? 'scaleX(-1)' : 'scaleX(1)')};
`;

export interface SpeedMeterHudElementProps {
	id: string;
	filename: string;
	flipX?: boolean;
}

export const SpeedMeterHudElement: FC<SpeedMeterHudElementProps> = (
	props: SpeedMeterHudElementProps,
) => {
	const { hudScale } = useWindowSize();

	return (
		<SSpeedMeterHudElement>
			<SSpeedMeterHudElementAnimationContainer
				flipX={props.flipX}
				height={HEIGHT * hudScale}
				width={WIDTH * hudScale}
			>
				<DynamicAnimationLoader
					autoplay={false}
					filename={props.filename}
					id={props.id}
					loop={false}
				/>
			</SSpeedMeterHudElementAnimationContainer>
		</SSpeedMeterHudElement>
	);
};

import { motion } from 'framer-motion';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { ANIMATION_VARIANTS, getMobileVariant } from '../animations';

const SBGContainer = styled(motion.div)<{ image: string; $mobile?: boolean }>`
	background-size: ${(props) => (props.$mobile ? 'cover' : 'contain')};
	background-repeat: no-repeat;
	background-position: top left;
	background-image: url(${(props) => props.image});
`;

export interface GameFeatureImageAnimatorProps {
	position: 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight';
	image: string;
	children: ReactNode;
	mobile?: boolean;
}

export const GameFeatureImageAnimator: FC<GameFeatureImageAnimatorProps> = (
	props: GameFeatureImageAnimatorProps,
) => {
	return (
		<div>
			<SBGContainer
				$mobile={props.mobile}
				className="absolute h-full w-full"
				image={props.image}
				variants={
					props.mobile
						? getMobileVariant(props.position, true)
						: ANIMATION_VARIANTS[props.position](true)
				}
			/>
			{props.children}
		</div>
	);
};

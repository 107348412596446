import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { DynamicAnimationLoader, Text } from '@home/components/atoms';
import { Countdown } from '@home/components/molecules';

import { HudContent } from '../Hud';

const HEIGHT = 174 * 0.85;
const WIDTH = 1000 * 0.85;

const SCountdownHudContainer = styled.div``;

const SCountdownHudElement = styled.div<{
	width: number;
	height: number;
}>`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	min-width: ${(props) => props.width}px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		margin-top: -32px;
		width: 600px;
		min-width: 600px;
	}
`;

const SCountdownLabel = styled(motion.div)<{ scale: number }>`
	height: ${({ scale }) => scale * 65}px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		height: 30px;
	}
`;

const SCountdownContainer = styled(motion.div)<{ scale: number }>`
	top: ${({ scale }) => scale * 72}px;
	height: ${({ scale }) => scale * 46}px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		top: 35px;
		height: 44px;
	}
`;

const PrePhaseLabelContainer = styled(motion.div)<{ scale: number }>`
	font-family: 'Rift';
	font-weight: bold;
	font-size: ${({ scale }) => scale * 35}px;
	line-height: ${({ scale }) => scale * 35}px;
	letter-spacing: 0.095em;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 30px;
		line-height: 30px;
		letter-spacing: 0.06em;
	}
`;

const variants = {
	initial: {
		opacity: 0,
	},

	animate: {
		opacity: 1,
		transition: {
			delay: 10,
			ease: 'linear',
			duration: 0.5,
		},
	},

	exit: {
		opacity: 0,
		transition: {
			delay: 2,
			ease: 'linear',
			duration: 0.5,
		},
	},
};

export interface CountdownHudElementProps {
	id: string;
	filename: string;
	content: HudContent;
}

export const CountdownHudElement: FC<CountdownHudElementProps> = (
	props: CountdownHudElementProps,
) => {
	const { hudScale } = useWindowSize();

	return (
		<SCountdownHudContainer className="absolute top-0 flex justify-center w-full pointer-events-auto text-white">
			<SCountdownHudElement height={HEIGHT * hudScale} width={WIDTH * hudScale}>
				<DynamicAnimationLoader
					autoplay={false}
					filename={props.filename}
					id={props.id}
					loop={false}
				/>
			</SCountdownHudElement>

			<SCountdownLabel
				className="absolute inset-0 flex items-center justify-center"
				scale={hudScale}
				variants={variants}
			>
				<Text>{props.content.countdownLabel}</Text>
			</SCountdownLabel>

			<SCountdownContainer
				className="absolute inset-0 flex flex-row justify-center"
				scale={hudScale}
				variants={variants}
			>
				{props.content.countdown !== null ? (
					<Countdown
						content={props.content}
						endTime={new Date(props.content.countdown)}
					/>
				) : (
					<PrePhaseLabelContainer
						className="flex items-center"
						scale={hudScale}
					>
						{props.content.prePhaseLabel}
					</PrePhaseLabelContainer>
				)}
			</SCountdownContainer>
		</SCountdownHudContainer>
	);
};

import { FC } from 'react';

export const GameOverviewFeatureFrameBottomLeft: FC = () => {
	return (
		<svg
			fill="none"
			preserveAspectRatio="xMidYMid"
			viewBox="0 0 413 157"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.16992 142L11.9999 147H360.55L367.7 142H6.16992Z"
				fill="#FFF1DF"
			/>
			<path
				d="M367.92 6C369.09 6 370.24 6.42001 371.13 7.17001L395.21 27.37C396.34 28.32 397 29.72 397 31.2V118.31C397 119.95 396.2 121.48 394.85 122.42L361.41 145.64C361.07 145.88 360.67 146 360.26 146H11.31L5.94998 141.21V21.5L18.87 6H367.92V6ZM367.92 4H17.94L3.95996 20.78V142.11L10.55 148H360.27C361.1 148 361.89 147.75 362.57 147.27L396 124.06C397.88 122.75 399.01 120.6 399.01 118.31V31.2C399.01 29.13 398.1 27.17 396.51 25.84L372.43 5.64001C371.17 4.58001 369.57 4 367.93 4H367.92Z"
				fill="#FFF1DF"
			/>
			<path
				d="M15.88 1.07001C16.45 0.390007 17.29 0 18.18 0H380.13C382.25 0 384.3 0.749985 385.92 2.10999L407.73 20.41C410.45 22.69 412.02 26.06 412.02 29.6V119.76C412.02 123.68 410.11 127.34 406.9 129.59L371.5 154.38C369.99 155.44 368.18 156.01 366.34 156.01H13.46L0 144.01V20.01L15.88 1.07999V1.07001ZM1.95999 143L9.79001 150H360.27C361.5 150 362.7 149.62 363.71 148.92L397.14 125.71C399.56 124.03 401.01 121.27 401.01 118.32V31.21C401.01 28.55 399.83 26.03 397.8 24.32L373.72 4.12C372.1 2.76 370.05 2.01001 367.93 2.01001H17L2 20.01H1.95001V143.01L1.95999 143Z"
				fill="url(#paint0_linear_188_3793)"
			/>
			<path
				d="M45.5801 19.77V119.54C45.5801 119.96 45.1301 120.31 44.5801 120.31C44.0301 120.31 43.5801 119.97 43.5801 119.54V19.77C43.5801 19.35 44.0301 19 44.5801 19C45.1301 19 45.5801 19.34 45.5801 19.77Z"
				fill="url(#paint1_linear_188_3793)"
			/>
			<path
				d="M40.83 124.42L44.58 120.67L48.33 124.42L44.58 128.17L40.83 124.42ZM38 124.42L44.58 131L51.16 124.42L44.58 117.84L38 124.42Z"
				fill="#FFF1DF"
			/>
			<path
				d="M353.5 136C360.404 136 366 130.404 366 123.5C366 116.596 360.404 111 353.5 111C346.596 111 341 116.596 341 123.5C341 130.404 346.596 136 353.5 136Z"
				fill="#BF3054"
			/>
			<path d="M353 121H356V124H358V119H353V121Z" fill="#FFF1DF" />
			<path d="M354 126H351V123H349V128H354V126Z" fill="#FFF1DF" />
			<defs>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint0_linear_188_3793"
					x1="0"
					x2="412"
					y1="78"
					y2="78"
				>
					<stop stopColor="#741F7A" />
					<stop offset="1" stopColor="#BF3054" />
				</linearGradient>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint1_linear_188_3793"
					x1="44.5801"
					x2="44.5801"
					y1="120.31"
					y2="19"
				>
					<stop stopColor="#C935D4" />
					<stop offset="1" stopColor="#9928A1" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

import React from 'react';
import styled from 'styled-components';

import { MenuIconProps } from '@home/types';

const Active = styled.svg`
	.cls-1,
	.cls-2 {
		fill: none;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}

	.cls-1 {
		stroke: #bf3054;
		stroke-width: 1.5px;
	}

	.cls-2 {
		stroke: #fff1df;
		stroke-width: 2px;
	}

	.cls-3 {
		fill: #bf3054;
	}

	.cls-4 {
		fill: #fff1df;
	}
`;

const Hover = styled.svg`
	.cls-1 {
		fill: #fff1df;
	}
	.cls-2 {
		fill: #bf3054;
	}
	.cls-3 {
		stroke-width: 1.35px;
	}
	.cls-3,
	.cls-4 {
		fill: none;
		stroke: #fff1df;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}
	.cls-4 {
		stroke-width: 1.8px;
	}
`;

const InActive = styled.svg`
	.cls-1,
	.cls-2 {
		fill: none;
		stroke: #bf3054;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}

	.cls-1 {
		stroke-width: 1.5px;
	}

	.cls-2 {
		stroke-width: 2px;
	}

	.cls-3 {
		fill: #bf3054;
	}

	.cls-4 {
		fill: #741f7a;
	}
`;

export const GenesisIcon = ({
	active,
	hover,
	width = 48,
	height = 48,
}: MenuIconProps) => {
	return active ? (
		<Active
			height={height}
			viewBox="0 0 47 48.4"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g data-name="Legacy collection - Active" id="Legacy_collection_-_Active">
				<line className="cls-1" x1="25.5" x2="11.5" y1="35.2" y2="35.2" />
				<polyline
					className="cls-2"
					points="10.5 30.2 25.5 30.2 37.5 18.2 37.5 24.2 26.5 35.2 10.5 35.2"
				/>
				<path
					className="cls-3"
					d="M84.1,66.3l-8.9,9H65.6l8.9-9h9.6m4.7-2H73.7l-12.9,13H76l12.8-13Z"
					transform="translate(-51.3 -51.1)"
				/>
			</g>
			<g data-name="Frame - Active" id="Frame_-_Active">
				<g>
					<path
						className="cls-3"
						d="M94.9,75.3a18.5,18.5,0,0,1-.9,5.8h2.1a22,22,0,0,0,.8-5.8,22.5,22.5,0,0,0-.8-5.8H94A19,19,0,0,1,94.9,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M54.8,75.3a19,19,0,0,1,.9-5.8H53.6a22.5,22.5,0,0,0-.8,5.8,22,22,0,0,0,.8,5.8h2.1A18.5,18.5,0,0,1,54.8,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
				<g>
					<path
						className="cls-4"
						d="M96.3,81.1a22.2,22.2,0,0,1-42.9,0H51.3a24.2,24.2,0,0,0,47,0Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M74.8,51.1A24.4,24.4,0,0,0,51.3,69.5h2.1a22.2,22.2,0,0,1,42.9,0h2A24.3,24.3,0,0,0,74.8,51.1Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
		</Active>
	) : hover ? (
		<Hover
			height={height}
			viewBox="0 0 50.96 43.63"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Legacy_collection_-_Inactive_copy">
				<line className="cls-3" x1="27.28" x2="14.68" y1="31.71" y2="31.71" />
				<polyline
					className="cls-4"
					points="13.78 27.21 27.28 27.21 38.08 16.41 38.08 21.81 28.18 31.71 13.78 31.71"
				/>
				<path
					className="cls-1"
					d="M33.77,13.71l-8,8.1h-8.59l8-8.1h8.59m4.31-1.8h-13.65l-11.55,11.7h13.65l11.55-11.7h0Z"
				/>
			</g>
			<g id="Layer_3">
				<g>
					<path
						className="cls-2"
						d="M48.84,21.81c0,2.14-.32,4.2-.91,6.15h2.21c.53-1.96,.82-4.02,.82-6.15s-.29-4.19-.82-6.15h-2.21c.59,1.95,.91,4.01,.91,6.15Z"
					/>
					<path
						className="cls-2"
						d="M2.12,21.81c0-2.14,.32-4.2,.91-6.15H.82c-.53,1.96-.82,4.02-.82,6.15s.29,4.19,.82,6.15H3.03c-.59-1.95-.91-4.01-.91-6.15Z"
					/>
				</g>
				<path
					className="cls-2"
					d="M6.46,15.67C9.06,7.64,16.59,1.8,25.48,1.8s16.42,5.83,19.02,13.86h1.88C43.73,6.63,35.37,0,25.48,0S7.24,6.63,4.57,15.67h1.88Z"
				/>
				<path
					className="cls-2"
					d="M44.5,27.96c-2.6,8.03-10.14,13.86-19.02,13.86s-16.42-5.83-19.02-13.86h-1.88c2.66,9.04,11.02,15.67,20.91,15.67s18.24-6.63,20.91-15.67h-1.88Z"
				/>
			</g>
		</Hover>
	) : (
		<InActive
			height={height}
			viewBox="0 0 47 48.4"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				data-name="Legacy collection - Inactive"
				id="Legacy_collection_-_Inactive"
			>
				<line className="cls-1" x1="25.5" x2="11.5" y1="35.2" y2="35.2" />
				<polyline
					className="cls-2"
					points="10.5 30.2 25.5 30.2 37.5 18.2 37.5 24.2 26.5 35.2 10.5 35.2"
				/>
				<path
					className="cls-3"
					d="M84.1,66.3l-8.9,9H65.6l8.9-9h9.6m4.7-2H73.7l-12.9,13H76l12.8-13Z"
					transform="translate(-51.3 -51.1)"
				/>
			</g>
			<g data-name="Frame - Inactive" id="Frame_-_Inactive">
				<g>
					<path
						className="cls-4"
						d="M96.3,81.1a22.2,22.2,0,0,1-42.9,0H51.3a24.2,24.2,0,0,0,47,0Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M74.8,51.1A24.4,24.4,0,0,0,51.3,69.5h2.1a22.2,22.2,0,0,1,42.9,0h2A24.3,24.3,0,0,0,74.8,51.1Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
				<g>
					<path
						className="cls-4"
						d="M94.9,75.3a18.5,18.5,0,0,1-.9,5.8h2.1a22,22,0,0,0,.8-5.8,22.5,22.5,0,0,0-.8-5.8H94A19,19,0,0,1,94.9,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M54.8,75.3a19,19,0,0,1,.9-5.8H53.6a22.5,22.5,0,0,0-.8,5.8,22,22,0,0,0,.8,5.8h2.1A18.5,18.5,0,0,1,54.8,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
		</InActive>
	);
};

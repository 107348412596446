import React from 'react';

interface MenuIconProps {
	color?: string;
	strokeWidth?: number;
	onClick?: () => void;
}

export const MenuIcon = ({
	color = '#FFF1DF',
	strokeWidth = 2,
	onClick,
}: MenuIconProps) => {
	return (
		<svg
			fill="none"
			height="14"
			viewBox="0 0 16 14"
			width="16"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path d="M0 13H16" stroke={color} strokeWidth={strokeWidth} />
			<path d="M0 7H16" stroke={color} strokeWidth={strokeWidth} />
			<path d="M0 1H16" stroke={color} strokeWidth={strokeWidth} />
		</svg>
	);
};

import { motion } from 'framer-motion';
import { FC } from 'react';

import { FRAME_TRANSITION } from '../animations';

export const VideoFrame: FC = () => {
	return (
		<motion.div
			className="absolute w-full h-full inset-0 z-100"
			variants={FRAME_TRANSITION}
		>
			<svg
				fill="none"
				height="100%"
				viewBox="0 0 1054 628"
				width="100%"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M231.91 624.07C234.4 624.07 234.4 620.19 231.91 620.19C229.41 620.19 229.4 624.07 231.91 624.07Z"
					fill="#FFF1DF"
				/>
				<path
					d="M1025.67 36.92V0.920044H901.109V4.92004H1021.67V36.92V40.92H1025.67H1041.67V274.07H1045.67V36.92H1025.67Z"
					fill="#741F7A"
				/>
				<path
					d="M1041.67 587.08H1025.67H1021.67V591.08V623.08H972.29H901.11H900.67V627.08H901.11H972.29H1025.67V591.08H1045.67V353.93H1041.67V587.08Z"
					fill="#741F7A"
				/>
				<path
					d="M1049.67 274.07H1045.67V353.95H1049.67V274.07Z"
					fill="#741F7A"
				/>
				<path
					d="M20.5501 228.79L12.3301 221.99V4.92004H153.45V0.920044H8.33008V224L20.5501 234.16V228.79Z"
					fill="#741F7A"
				/>
				<path
					d="M12.3301 406.01L19.7701 399.85V394.48L8.33008 404V627.08H153.45V623.08H12.3301V406.01Z"
					fill="#741F7A"
				/>
				<path
					d="M1029.67 32.92V595.08H22.4V32.92H1029.67ZM1036.33 26.25H15.73V601.74H1036.33V26.25Z"
					fill="#BF3054"
				/>
				<path
					d="M1036.33 12.92V26.25H1049.66V601.74H1036.33V615.07H4.33008V326.67L17.1101 314L4.34007 301.33V12.92H1036.33ZM1040.33 8.92004H1036.33H4.33008H0.330078V12.92V301.33V303L1.52008 304.17L11.4301 314L1.52008 323.83L0.330078 325V326.67V615.08V619.08H4.33008H1036.33H1040.33V615.08V605.75H1049.66H1053.66V601.75V26.25V22.25H1049.66H1040.33V12.92V8.92004Z"
					fill="#BF3054"
				/>
				<path
					d="M833.52 623.2C831.88 623.2 830.28 622.69 828.93 621.76L811.2 609.35C809.19 607.94 806.77 607.18 804.32 607.18H784.47H613.32H598.21H575.16H563.43H540.52H513.47H490.56H478.83H455.78H440.67H269.55H249.7C247.23 607.18 244.83 607.94 242.82 609.35L225.09 621.76C223.74 622.71 222.14 623.2 220.5 623.2H156.99V627.51H233.84C236.31 627.51 238.71 626.75 240.72 625.34L253.17 616.62C254.52 615.67 256.12 615.18 257.76 615.18H440.68H455.79H478.84H490.57H513.48H540.53H563.44H575.17H598.22H613.33H796.25C797.89 615.18 799.49 615.69 800.84 616.62L813.29 625.34C815.3 626.75 817.72 627.51 820.17 627.51H897.02V623.2H833.52Z"
					fill="#FFF1DF"
				/>
				<path
					d="M833.52 4.79999C831.88 4.79999 830.28 5.30999 828.93 6.23999L811.2 18.65C809.19 20.06 806.77 20.82 804.32 20.82H784.47H613.32H598.21H575.16H563.43H540.52H513.47H490.56H478.83H455.78H440.67H269.55H249.7C247.23 20.82 244.83 20.06 242.82 18.65L225.09 6.23999C223.74 5.28999 222.14 4.79999 220.5 4.79999H156.99V0.48999H233.84C236.31 0.48999 238.71 1.24999 240.72 2.65999L253.17 11.38C254.52 12.33 256.12 12.82 257.76 12.82H440.68H455.79H478.84H490.57H513.48H540.53H563.44H575.17H598.22H613.33H796.25C797.89 12.82 799.49 12.31 800.84 11.38L813.29 2.65999C815.3 1.24999 817.72 0.48999 820.17 0.48999H897.02V4.79999H833.52Z"
					fill="#FFF1DF"
				/>
			</svg>
		</motion.div>
	);
};

/**
 * !! IMPORTANT !!
 * This needs to match tailwind.config.js breakpoints
 */

export enum Breakpoint {
	XS = 375,
	SMALL = 640,
	MEDIUM = 768,
	LARGE = 1024,
	XL = 1280,
	XXL = 1536,

	SMALL_PHONE_HEIGHT = 600,
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { button, folder, useControls } from 'leva';
import { useState } from 'react';

// TODO: remove for production
export interface useSettingsProps {
	meta?: any;
	[key: string]: any;
}

const urlParams = new URLSearchParams(
	typeof window !== 'undefined' ? window.location.search : '',
);
const debug = urlParams.get('debug');

const useSettingsProd = (title = '', props, meta?) => {
	const [settings] = useState(flatten(props));

	return settings;
};

const useSettingsDev = (title = '', props, meta?) => {
	return useControls(title, transformSettingsPropsToLeva(props), meta || {});
};

export const useSettings = debug === 'true' ? useSettingsDev : useSettingsProd;

function transformSettingsPropsToLeva(props: useSettingsProps) {
	return Object.keys(props).reduce((acc, key) => {
		const config = props[key];

		return {
			...acc,
			[key]:
				config.type === 'folder'
					? folder(omit(config, ['type']), config.meta || {})
					: config.type === 'button'
					? button(
							config.callback ||
								function () {
									/** */
								},
							config.meta || {},
					  )
					: config,
		};
	}, {});
}

function omit(object, keys) {
	return Object.keys(object).reduce((acc, key) => {
		if (!keys.includes(key)) {
			return {
				...acc,
				[key]: object[key],
			};
		}

		return acc;
	}, {});
}

function flatten(object = {}) {
	return Object.keys(object).reduce((acc, key) => {
		const value = object[key];
		const isObject = typeof value === 'object' && value !== null;
		const isArray = Array.isArray(value);
		const isVectorSettingsObject =
			isObject &&
			(Object.prototype.hasOwnProperty.call(value, 'x') ||
				Object.prototype.hasOwnProperty.call(value, 'y'));
		const isValueObject =
			isObject && Object.prototype.hasOwnProperty.call(value, 'value');

		if (
			typeof value === 'object' &&
			!isVectorSettingsObject &&
			!isValueObject &&
			!isArray
		) {
			return {
				...acc,
				...flatten(value),
			};
		} else {
			return {
				...acc,
				[key]: isValueObject ? value.value : value,
			};
		}
	}, {});
}

import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint, ContentSection, zIndex } from '@common/enums';
import { useMenu, useWindowSize } from '@common/hooks';
import { ModalCloseButton, NavbarIcon, Title } from '@home/components/atoms';
import { FooterContent } from '@home/components/molecules';
import { Size } from '@home/types';

import { NavBarItem } from '../NavBar/NavBar';
import { MenuBorders, MenuItem } from './components';

const SMenu = styled(motion.div)<{ size: Size }>`
	height: ${({ size }) => size.height - 64}px;
	width: ${({ size }) => size.width - 64}px;
	background: #230b26;
	position: relative;
`;

const CloseIconContainer = styled.div`
	position: absolute;
	top: 24px;
	right: 24px;
	z-index: ${zIndex.MODAL + 1};
	transition: all 0.3s ease;
`;

const MenuItemTitle = styled(Title)``;

const MenuFramerVariants = {
	initial: { opacity: 0, y: 24 },
	animate: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.6 },
	},
	exit: { opacity: 0, y: -12, transition: { duration: 0.3 } },
};

interface MenuProps {
	items: NavBarItem[];
}

export const Menu: FC<MenuProps> = ({ items }: MenuProps) => {
	const size = useWindowSize();
	const [menu] = useMenu();
	const [hover, setHover] = useState<NavBarItem>();

	const onClose = () => {
		menu.close();
	};

	const isActive = (section: ContentSection) => menu.active() === section;
	const isHovering = (item: NavBarItem) => hover?.key === item.key;

	const onSectionClick = (section: ContentSection) => {
		if (isActive(section)) return;
		menu.setActive(section);
		menu.close();
	};

	return (
		<SMenu
			className="border-2 border-red flex flex-col justify-between"
			size={size}
			variants={MenuFramerVariants}
		>
			<CloseIconContainer
				className="hover:scale-110 cursor-pointer"
				onClick={onClose}
			>
				<ModalCloseButton onClick={onClose} />
			</CloseIconContainer>
			<MenuBorders />
			<div className="pt-16 px-6 flex flex-col gap-y-4">
				{items.map((item) => (
					<MenuItem
						key={item.key}
						active={isActive(item.key)}
						onClick={() => onSectionClick(item.key)}
						onHoverEnd={() => setHover(undefined)}
						onHoverStart={() => setHover(item)}
					>
						<NavbarIcon
							active={isActive(item.key)}
							height={
								size.height > 700
									? 40
									: size.height > Breakpoint.SMALL_PHONE_HEIGHT
									? 32
									: 24
							}
							hover={isHovering(item)}
							section={item.key}
							width={
								size.height > 700
									? 40
									: size.height > Breakpoint.SMALL_PHONE_HEIGHT
									? 32
									: 24
							}
						/>
						<MenuItemTitle level={3}>{item.label}</MenuItemTitle>
					</MenuItem>
				))}
			</div>
			<div className="w-full self-center flex items-center justify-center">
				{menu.footer && <FooterContent content={menu.footer} />}
			</div>
		</SMenu>
	);
};

import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { Text } from '@home/components/atoms';
import { Interval } from '@home/types';

import { BOX_VARIANTS, INDICATOR_VARIANTS, LINE_VARIANTS } from '../animations';
import { Date } from './Date';
import { IndicatorMarker } from './IndicatorMarker';
import { VerticalLine } from './VerticalLine';

const Container = styled(motion.div)<{ pageSize: number }>`
	width: ${({ pageSize }) => pageSize * 213}px;
	z-index: 2;
	margin-top: -32px;
	height: calc(67px + 39px);

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		width: ${({ pageSize }) => pageSize * 165}px;
	}
`;

const HorizontalLine = styled(motion.div)<{
	start?: boolean;
	end?: boolean;
	left?: boolean;
}>`
	background: ${({ start, end }) =>
		start
			? 'linear-gradient(90deg, rgba(191, 48, 84, 0) 0%, #BF3054 100%)'
			: end
			? 'linear-gradient(90deg, #BF3054 0%, rgba(191, 48, 84, 0) 100%)'
			: 'linear-gradient(90deg, #BF3054 0%, #BF3054 100%)'};
	transition: background 0.25s linear;
	width: 198px;
	left: ${({ left }) => (left ? '-198px' : 'auto')};

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		width: 150px;
		left: ${({ left }) => (left ? '-150px' : 'auto')};
	}
`;

interface IndicatorProps {
	length: number;
	active: Interval;
	dates?: (string | undefined)[];
}

export const Indicator: FC<IndicatorProps> = ({
	length,
	active,
	dates,
}: IndicatorProps) => {
	const wSize = useWindowSize();
	const [elements, setElements] = useState(Array.from(Array(length).keys()));

	useEffect(() => {
		setElements(Array.from(Array(length).keys()));
	}, [length]);

	const CONTAINER_VARIANTS = {
		current: {
			x:
				wSize.height >= Breakpoint.SMALL_PHONE_HEIGHT
					? active.start > 0
						? 100 - active.start * 213
						: 100
					: active.start > 0
					? 77 - active.start * 165
					: 77,
			transition: {
				duration: 0.5,
				ease: 'linear',
				delay: 0,
			},
		},
	};

	const isActive = (index: number) =>
		index >= active.start && index <= active.end;

	return (
		<Container
			className="relative"
			pageSize={active.end - active.start + 1}
			variants={BOX_VARIANTS}
		>
			<motion.div
				animate="current"
				className="flex items-center justify-start"
				variants={CONTAINER_VARIANTS}
			>
				{elements.map((element) => (
					<motion.div
						key={element}
						animate={isActive(element) ? 'show' : 'hide'}
						className="relative flex flex-row items-end justify-center"
						exit="hide"
						variants={INDICATOR_VARIANTS}
					>
						<HorizontalLine
							left
							animate={
								element === active.start && element !== 0 ? 'show' : 'hide'
							}
							className="absolute left-[-198px] h-[2px] w-[198px] mb-[7px] ml-[-2px]"
							start={element === active.start}
							variants={LINE_VARIANTS}
						/>

						<motion.div className="flex flex-col items-center justify-center gap-y-[2px]">
							<VerticalLine />
							<IndicatorMarker />
							{dates && dates[element] && (
								<Date>
									<Text small>{dates[element]}</Text>
								</Date>
							)}
						</motion.div>

						<HorizontalLine
							animate={element === length - 1 ? 'hide' : 'show'}
							className="h-[2px] mb-[7px] ml-[-2px]"
							end={element === active.end}
							variants={LINE_VARIANTS}
						/>
					</motion.div>
				))}
			</motion.div>
		</Container>
	);
};

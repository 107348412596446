import Image, { ImageLoaderProps } from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { ExternalAsset } from '@home/types';

const Container = styled.div<{
	aspectRatio?: string;
	fit?: 'contain' | 'cover';
}>`
	width: 100%;
	aspect-ratio: ${({ aspectRatio }) => aspectRatio ?? '1'};
	position: relative;

	& img {
		object-fit: ${({ fit }) => fit};
	}
`;

type ImageAssetProps = ExternalAsset & {
	alt?: string;
	aspectRatio?: string;
	fit?: 'contain' | 'cover';
};

export const ImageAsset = ({
	url,
	alt,
	aspectRatio,
	fit = 'contain',
}: ImageAssetProps) => {
	const loader = ({ src }: ImageLoaderProps) => src;

	return (
		<Container aspectRatio={aspectRatio} fit={fit}>
			<Image alt={alt || 'Alt'} layout="fill" loader={loader} src={url} />
		</Container>
	);
};

import { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { AnimationManager } from '@common/animations';
import { Breakpoint } from '@common/enums';
import { useCountdown, useWindowSize } from '@common/hooks';
import { TimeColonIcon } from '@home/components/atoms';
import { HudContent } from '@home/components/organisms';

import { CountdownCTA } from './components';

export interface CountdownProps {
	content: HudContent;
	endTime: Date;
}

const Container = styled.div<{ scale: number }>`
	@media (max-width: ${Breakpoint.LARGE}px) {
		margin-bottom: -4px;
		align-items: flex-end;
	}
`;

const TimeContainer = styled.div<{ scale: number }>`
	width: ${({ scale }) => 40 * scale}px;
`;

const SColonContainer = styled.div<{ scale: number }>`
	width: ${({ scale }) => 38 * scale}px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		width: 13px;
	}
`;

const STime = styled.div<{ scale: number }>`
	font-family: Rift;
	font-weight: bold;
	font-size: ${({ scale }) => 42 * scale}px;
	line-height: ${({ scale }) => 42 * scale}px;
	letter-spacing: 0.085em;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 30px;
		line-height: 22px;
	}
`;

const STimeLabel = styled.p<{ scale: number }>`
	font-family: Prohibition;
	font-size: ${({ scale }) => 13 * scale}px;
	letter-spacing: 0.085em;

	@media (max-width: ${Breakpoint.LARGE}px) {
		font-size: 11px;
	}
`;

let hasLoaded = false;

export const Countdown: FC<CountdownProps> = (props: CountdownProps) => {
	const wSize = useWindowSize();
	const _startTime = useMemo(() => new Date().getTime(), []);
	const _endTime = useMemo(
		() =>
			new Date(
				Date.UTC(
					props.endTime.getFullYear(),
					props.endTime.getMonth(),
					props.endTime.getDate(),
					props.endTime.getHours(),
					props.endTime.getMinutes(),
					props.endTime.getSeconds(),
				),
			).getTime(),
		[props.endTime],
	);

	const { countdown, days, hours, minutes } = useCountdown({
		startTime: _startTime,
		endTime: _endTime,
	});

	useEffect(() => {
		if (!hasLoaded) {
			if (countdown === 0) {
				hasLoaded = true;
				AnimationManager.startCountdownEndAnimationLoop();
			}
		}
	}, [countdown]);

	// AFTER PHASE, date has passed
	if (countdown === 0) {
		return <CountdownCTA cta={props.content.topCta} />;
	}

	// MID PHASE, countdown
	return (
		<Container className="flex flex-row text-offWhite" scale={wSize.hudScale}>
			<TimeContainer
				className="flex flex-col justify-start items-center"
				scale={wSize.hudScale}
			>
				<STime scale={wSize.hudScale}>{days}</STime>
				<STimeLabel scale={wSize.hudScale}>DAYS</STimeLabel>
			</TimeContainer>
			<SColonContainer
				className="flex flex-row justify-center self-center lg:self-start"
				scale={wSize.hudScale}
			>
				<TimeColonIcon
					height={wSize.width < Breakpoint.LARGE ? 26 : 42 * wSize.hudScale}
					width={6 * wSize.hudScale}
				/>
			</SColonContainer>
			<TimeContainer
				className="flex flex-col justify-start items-center"
				scale={wSize.hudScale}
			>
				<STime scale={wSize.hudScale}>{hours}</STime>
				<STimeLabel scale={wSize.hudScale}>HRS</STimeLabel>
			</TimeContainer>
			<SColonContainer
				className="flex flex-row justify-center self-center lg:self-start"
				scale={wSize.hudScale}
			>
				<TimeColonIcon
					height={wSize.width < Breakpoint.LARGE ? 26 : 42 * wSize.hudScale}
					width={6 * wSize.hudScale}
				/>
			</SColonContainer>
			<TimeContainer
				className="flex flex-col justify-start items-center"
				scale={wSize.hudScale}
			>
				<STime scale={wSize.hudScale}>{minutes}</STime>
				<STimeLabel scale={wSize.hudScale}>MINS</STimeLabel>
			</TimeContainer>
		</Container>
	);
};

import { useRouter } from 'next/router';

import { Localization, PageContent } from '@common/types';
import {
	LoaderContent,
	MobileLandscapeScreenContent,
} from '@home/components/molecules';
import { HudContent } from '@home/components/organisms';
import {
	CompanyOverviewSectionContent,
	FooterSectionContent,
	GameOverviewSectionContent,
	GenesisSectionContent,
	IntroSectionContent,
	RadarSectionContent,
	RoadmapSectionContent,
	TrailerSectionContent,
} from '@home/sections';

export const useLocalizedContent = ({
	hudContent,
	loader,
	introSection,
	trailerSection,
	radarSection,
	gameOverviewSection,
	roadmapSection,
	genesisSection,
	companyOverviewSection,
	footer,
	mobileLandscapeScreen,
}: PageContent) => {
	const router = useRouter();
	const language = router.query.locale as string;

	const getLocalizedContent = (
		localizations: Localization[],
		locale: string,
	) => ({
		...(localizations.find((x) => x.locale === locale) ?? localizations[0]),
	});

	const getIntroSectionContent = () =>
		({
			...getLocalizedContent(introSection.localizations, language),
		} as unknown as IntroSectionContent);

	const getTrailerSectionContent = () =>
		({
			...getLocalizedContent(trailerSection.localizations, language),
		} as unknown as TrailerSectionContent);

	const getRadarSectionContent = () =>
		({
			...getLocalizedContent(radarSection.localizations, language),
		} as unknown as RadarSectionContent);

	const getGameOverviewSectionContent = () =>
		({
			...getLocalizedContent(gameOverviewSection.localizations, language),
		} as unknown as GameOverviewSectionContent);

	const getRoadmapSectionContent = () =>
		({
			...getLocalizedContent(roadmapSection.localizations, language),
		} as unknown as RoadmapSectionContent);

	const getGenesisSectionContent = () =>
		({
			...getLocalizedContent(genesisSection.localizations, language),
		} as unknown as GenesisSectionContent);

	const getCompanyOverviewSectionContent = () =>
		({
			...getLocalizedContent(companyOverviewSection.localizations, language),
		} as unknown as CompanyOverviewSectionContent);

	const getFooterContent = () =>
		({
			...getLocalizedContent(footer.localizations, language),
		} as unknown as FooterSectionContent);

	const getMobileLandscapeScreenContent = () =>
		({
			...getLocalizedContent(mobileLandscapeScreen.localizations, language),
		} as unknown as MobileLandscapeScreenContent);

	const getLoaderContent = () =>
		({
			...getLocalizedContent(loader.localizations, language),
		} as unknown as LoaderContent);

	const getHudContent = () =>
		({
			...hudContent,
		} as unknown as HudContent);

	return {
		loaderContent: getLoaderContent(),
		hudContent: getHudContent(),
		introSectionContent: getIntroSectionContent(),
		trailerSectionContent: getTrailerSectionContent(),
		radarSectionContent: getRadarSectionContent(),
		gameOverviewSectionContent: getGameOverviewSectionContent(),
		roadmapSectionContent: getRoadmapSectionContent(),
		genesisSectionContent: getGenesisSectionContent(),
		companyOverviewSectionContent: getCompanyOverviewSectionContent(),
		footerSectionContent: getFooterContent(),
		mobileLandscapeScreen: getMobileLandscapeScreenContent(),
	};
};

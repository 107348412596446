import { TierResult, getGPUTier } from 'detect-gpu';

import {
	ArchAnimationTimeStamp,
	CountdownAnimationTimeStamp,
	HudAnimation,
	LottieAnimation,
	RadarAnimationTimeStamp,
	SpeedmeterAnimationTimeStamp,
} from '@common/enums';
import { GlobalLottieAnimations } from '@common/globals';

let IS_RADAR_PLAYING = false;
let GLOBAL_CURRENT_GPU_TIER: TierResult | undefined = undefined;

export class AnimationManager {
	static loadGPUTier = async () => {
		const gpuTier = await getGPUTier();
		GLOBAL_CURRENT_GPU_TIER = gpuTier;
	};

	static getGPUTier = () => {
		return GLOBAL_CURRENT_GPU_TIER;
	};

	static giveMePerformance = () => {
		const animations = [
			GlobalLottieAnimations.get(HudAnimation.COUNTDOWN),
			GlobalLottieAnimations.get(HudAnimation.ARCH_LEFT_TOP),
			GlobalLottieAnimations.get(HudAnimation.ARCH_LEFT_BOTTOM),
			GlobalLottieAnimations.get(HudAnimation.ARCH_RIGHT_TOP),
			GlobalLottieAnimations.get(HudAnimation.ARCH_RIGHT_BOTTOM),
			GlobalLottieAnimations.get(HudAnimation.SPEED_METER_LEFT),
			GlobalLottieAnimations.get(HudAnimation.SPEED_METER_RIGHT),
		];

		animations.forEach((animation) => {
			animation?.pause();
		});
	};

	static removeMyPerformance = () => {
		const animations = [
			GlobalLottieAnimations.get(HudAnimation.COUNTDOWN),
			GlobalLottieAnimations.get(HudAnimation.ARCH_LEFT_TOP),
			GlobalLottieAnimations.get(HudAnimation.ARCH_LEFT_BOTTOM),
			GlobalLottieAnimations.get(HudAnimation.ARCH_RIGHT_TOP),
			GlobalLottieAnimations.get(HudAnimation.ARCH_RIGHT_BOTTOM),
			GlobalLottieAnimations.get(HudAnimation.SPEED_METER_LEFT),
			GlobalLottieAnimations.get(HudAnimation.SPEED_METER_RIGHT),
		];

		animations.forEach((animation) => {
			animation?.play();
		});
	};

	static startRadarAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			if (!IS_RADAR_PLAYING) {
				const animation = GlobalLottieAnimations.get(LottieAnimation.RADAR);
				requestAnimationFrame(() => {
					IS_RADAR_PLAYING = true;

					if (animation) {
						animation.loop = true;
						animation.playSegments(
							[
								[
									RadarAnimationTimeStamp.TS_START,
									RadarAnimationTimeStamp.TS_LOOP_START,
								],
								[
									RadarAnimationTimeStamp.TS_LOOP_START,
									RadarAnimationTimeStamp.TS_LOOP_END,
								],
							],
							true,
						);
					}
				});
			}
		}, delay);
	};

	static stopRadarAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			requestAnimationFrame(() => {
				const animation = GlobalLottieAnimations.get(LottieAnimation.RADAR);

				if (animation) {
					AnimationManager.removeMyPerformance();
					animation.stop();
					IS_RADAR_PLAYING = false;
				}
			});
		}, delay);
	};

	static startDiscordAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			const animation = GlobalLottieAnimations.get(HudAnimation.DISCORD);

			requestAnimationFrame(() => {
				if (animation) {
					animation.play();
				}
			});
		}, delay);
	};

	static startSpeedmetersAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			const leftAnimation = GlobalLottieAnimations.get(
				HudAnimation.SPEED_METER_LEFT,
			);
			const rightAnimation = GlobalLottieAnimations.get(
				HudAnimation.SPEED_METER_RIGHT,
			);

			requestAnimationFrame(() => {
				if (leftAnimation && rightAnimation) {
					leftAnimation.loop = true;
					rightAnimation.loop = true;
					leftAnimation.playSegments(
						[
							[
								SpeedmeterAnimationTimeStamp.TS_START,
								SpeedmeterAnimationTimeStamp.TS_LOOP_START,
							],
							[
								SpeedmeterAnimationTimeStamp.TS_LOOP_START,
								SpeedmeterAnimationTimeStamp.TS_LOOP_END,
							],
						],
						true,
					);
					rightAnimation.playSegments(
						[
							[
								SpeedmeterAnimationTimeStamp.TS_START,
								SpeedmeterAnimationTimeStamp.TS_LOOP_START,
							],
							[
								SpeedmeterAnimationTimeStamp.TS_LOOP_START,
								SpeedmeterAnimationTimeStamp.TS_LOOP_END,
							],
						],
						true,
					);
				}
			});
		}, delay);
	};

	static startCountdownAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			const animation = GlobalLottieAnimations.get(HudAnimation.COUNTDOWN);

			requestAnimationFrame(() => {
				if (animation) {
					animation.loop = true;
					animation.playSegments(
						[
							[
								CountdownAnimationTimeStamp.TS_START,
								CountdownAnimationTimeStamp.TS_LOOP1_START,
							],
							[
								CountdownAnimationTimeStamp.TS_LOOP1_START,
								CountdownAnimationTimeStamp.TS_LOOP1_END,
							],
						],
						true,
					);
				}
			});
		}, delay);
	};

	static startCountdownEndAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			const animation = GlobalLottieAnimations.get(HudAnimation.COUNTDOWN);

			requestAnimationFrame(() => {
				if (animation) {
					const listenerCallback = () => {
						animation.removeEventListener('loopComplete', listenerCallback);
						animation.stop();

						animation.loop = true;
						animation.playSegments(
							[
								[
									CountdownAnimationTimeStamp.TS_END_TRANSITION_START,
									CountdownAnimationTimeStamp.TS_END_TRANSITION_STOP,
								],
								[
									CountdownAnimationTimeStamp.TS_LOOP2_START,
									CountdownAnimationTimeStamp.TS_LOOP2_END,
								],
							],
							true,
						);
					};

					animation.addEventListener('loopComplete', listenerCallback);
				}
			});
		}, delay);
	};

	static startArchesAnimationLoop = (delay = 1) => {
		setTimeout(() => {
			const leftTop = GlobalLottieAnimations.get(HudAnimation.ARCH_LEFT_TOP);
			const leftBottom = GlobalLottieAnimations.get(
				HudAnimation.ARCH_LEFT_BOTTOM,
			);
			const rightTop = GlobalLottieAnimations.get(HudAnimation.ARCH_RIGHT_TOP);
			const rightBottom = GlobalLottieAnimations.get(
				HudAnimation.ARCH_RIGHT_BOTTOM,
			);

			requestAnimationFrame(() => {
				if (leftTop && leftBottom && rightTop && rightBottom) {
					leftTop.loop = true;
					leftBottom.loop = true;
					rightTop.loop = true;
					rightBottom.loop = true;

					leftTop.playSegments(
						[
							[
								ArchAnimationTimeStamp.TS_START,
								ArchAnimationTimeStamp.TS_LOOP_START,
							],
							[
								ArchAnimationTimeStamp.TS_LOOP_START,
								ArchAnimationTimeStamp.TS_LOOP_END,
							],
						],
						true,
					);

					leftBottom.playSegments(
						[
							[
								ArchAnimationTimeStamp.TS_START,
								ArchAnimationTimeStamp.TS_LOOP_START,
							],
							[
								ArchAnimationTimeStamp.TS_LOOP_START,
								ArchAnimationTimeStamp.TS_LOOP_END,
							],
						],
						true,
					);

					rightTop.playSegments(
						[
							[
								ArchAnimationTimeStamp.TS_START,
								ArchAnimationTimeStamp.TS_LOOP_START,
							],
							[
								ArchAnimationTimeStamp.TS_LOOP_START,
								ArchAnimationTimeStamp.TS_LOOP_END,
							],
						],
						true,
					);

					rightBottom.playSegments(
						[
							[
								ArchAnimationTimeStamp.TS_START,
								ArchAnimationTimeStamp.TS_LOOP_START,
							],
							[
								ArchAnimationTimeStamp.TS_LOOP_START,
								ArchAnimationTimeStamp.TS_LOOP_END,
							],
						],
						true,
					);
				}
			});
		}, delay);
	};
}

import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export interface StoreState {
	loadingProgress: number;
	setLoadingProgress: (progress: number) => void;
	isWebglLoaded: boolean;
	setIsWebglLoaded: (isWebglLoaded: boolean) => void;
	isLoaderTransitionCompleted: boolean;
	setIsLoaderTransitionCompleted: (
		isLoaderTransitionCompleted: boolean,
	) => void;
}

// subscribe with selector
export const useStore = create(
	subscribeWithSelector((set) => ({
		// loading progress [1-100]
		loadingProgress: 0,
		setLoadingProgress: (loadingProgress: number) =>
			set(() => ({ loadingProgress })),

		// is webgl loaded
		isWebglLoaded: false,
		setIsWebglLoaded: (isLoaded: boolean) =>
			set(() => ({ isWebglLoaded: isLoaded })),

		// is loader transition active (along with the exit animation)
		isLoaderTransitionCompleted: false,
		setIsLoaderTransitionCompleted: (isLoaderTransitionCompleted: boolean) =>
			set(() => ({ isLoaderTransitionCompleted })),
	})),
);

import { useCallback, useContext, useEffect, useState } from 'react';

import { SCROLL_THRESHOLD } from '@common/constants';
import { MenuContext } from '@common/contexts';
import { ContentSection } from '@common/enums';
import { debounce } from '@common/utils';

interface useScrollPaginatorProps {
	paginate: (direction: -1 | 1) => void;
	sections: ContentSection[];
}

export const useScrollPaginator = ({
	paginate,
	sections,
}: useScrollPaginatorProps) => {
	const menuContext = useContext(MenuContext);
	const treshold = SCROLL_THRESHOLD;
	const [currentScroll, setCurrentScroll] = useState(0);
	const [disabled, setDisabled] = useState(false);

	const _reset = () => {
		setCurrentScroll(0);
	};

	const _setDisabled = (value: boolean) => {
		setDisabled(value);

		if (!value) _reset();
	};

	const scrollDebounce = debounce(_reset, 500);

	const updateScroll = useCallback(
		(delta: number) => {
			scrollDebounce();

			if (menuContext.active === sections[0] && currentScroll + delta < 0) {
				_reset();

				return;
			}

			if (
				menuContext.active === sections[sections.length - 1] &&
				currentScroll + delta > 0
			) {
				_reset();

				return;
			}
			setCurrentScroll((v) => v + delta);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[menuContext],
	);

	const percentage = () => {
		const calc = currentScroll === 0 ? 0 : (currentScroll / treshold) * 100;

		return calc > 100 ? 100 : calc < -100 ? -100 : calc;
	};

	const wheelListener = ({ deltaY }: WheelEvent) => {
		if (disabled) return;
		updateScroll(deltaY);
	};

	useEffect(() => {
		window.addEventListener('wheel', wheelListener);

		return () => window.removeEventListener('wheel', wheelListener);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled]);

	useEffect(() => {
		if (disabled) return;

		if (Math.abs(currentScroll) > treshold) {
			setCurrentScroll(0);
			paginate(currentScroll > 0 ? 1 : -1);
		}
	}, [currentScroll, paginate, disabled, treshold]);

	return {
		disabled,
		setDisabled: _setDisabled,
		currentScroll,
		percentage: percentage(),
		reset: _reset,
	};
};

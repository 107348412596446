import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import {
	GenesisBackground,
	GenesisBorder,
	GenesisImageAnimator,
} from '@home/components/atoms';
import { ExternalAsset } from '@home/types';

const SWrapper = styled.div`
	height: 575px;
	margin: 0 auto;
	aspect-ratio: 525/575;
	z-index: 9;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		height: 340px;
	}
`;

const SImage = styled.div`
	z-index: 2;
	position: absolute;
	top: 4%;
	left: 5%;
`;

const SClippedContainer = styled.div<{
	clipPath: string;
}>`
	aspect-ratio: 1; // Very important little *ucker!!!
	width: 100%;
	clip-path: ${(props) => props.clipPath};
`;

export interface CharacterModalGalleryProps {
	image: ExternalAsset;
}

export const CharacterModalGallery: FC<CharacterModalGalleryProps> = (
	props: CharacterModalGalleryProps,
) => {
	const { image } = props;

	return (
		<SWrapper className="relative">
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_CHARACTER_MODAL"
					>
						<path d="M0.76405 0C0.76838 0 0.77255 0.00133903 0.7761 0.00384003L0.89474 0.088072C0.90034 0.091956 0.90369 0.098341 0.90369 0.105173V0.89483C0.90369 0.90164 0.90034 0.90804 0.89474 0.91193L0.76791 1H0.13578L0.00895202 0.91193C0.00334902 0.90804 0 0.90166 0 0.89483V0.105173C0 0.098364 0.00334902 0.091956 0.00895202 0.088072L0.130422 0.00372797C0.133927 0.00129497 0.138035 0 0.142299 0H0.76405Z" />
					</clipPath>
				</defs>
			</svg>

			<SImage className="w-full h-full">
				<SClippedContainer clipPath={`url(#CLIP_PATH_GENESIS_CARD)`}>
					{image && <GenesisImageAnimator image={image.url} />}
				</SClippedContainer>
			</SImage>
			<GenesisBorder />
			<GenesisBackground />
		</SWrapper>
	);
};

import React from 'react';
import styled from 'styled-components';

import { MenuIconProps } from '@home/types';

const Active = styled.svg`
	.cls-1,
	.cls-3 {
		fill: none;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 2px;
	}

	.cls-1 {
		stroke: #fff1df;
	}

	.cls-2 {
		fill: #fff1df;
	}

	.cls-3 {
		stroke: #bf3054;
	}

	.cls-4 {
		fill: #bf3054;
	}
`;

const Hover = styled.svg`
	.cls-1 {
		fill: #fff1df;
	}
	.cls-2 {
		fill: #bf3054;
	}
	.cls-3 {
		fill: none;
		stroke: #fff1df;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 1.8px;
	}
`;

const InActive = styled.svg`
	.cls-1 {
		fill: none;
		stroke: #bf3054;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 2px;
	}

	.cls-2 {
		fill: #bf3054;
	}

	.cls-3 {
		fill: #741f7a;
	}
`;

export const TrailerIcon = ({
	active,
	hover,
	width = 48,
	height = 48,
}: MenuIconProps) => {
	return active ? (
		<Active
			height={height}
			viewBox="0 0 47 48.4"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g data-name="Trailer - Active" id="Trailer_-_Active">
				<polyline
					className="cls-1"
					points="12.5 22.2 12.5 27.2 19.5 22.2 12.5 17.2"
				/>
				<path
					className="cls-2"
					d="M82.3,64.8v17H68.5l10.2-7.3,1.7-1.2-1.7-1.2L68.5,64.8H82.3m1.5-1.5h-20l14,10-14,10h20v-20Z"
					transform="translate(-51.3 -51.1)"
				/>
				<line className="cls-3" x1="12.5" x2="32.5" y1="36.2" y2="36.2" />
			</g>
			<g data-name="Frame - Active" id="Frame_-_Active">
				<g>
					<path
						className="cls-4"
						d="M94.9,75.3a18.5,18.5,0,0,1-.9,5.8h2.1a22,22,0,0,0,.8-5.8,22.5,22.5,0,0,0-.8-5.8H94A19,19,0,0,1,94.9,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-4"
						d="M54.8,75.3a19,19,0,0,1,.9-5.8H53.6a22.5,22.5,0,0,0-.8,5.8,22,22,0,0,0,.8,5.8h2.1A18.5,18.5,0,0,1,54.8,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
				<g>
					<path
						className="cls-2"
						d="M96.3,81.1a22.2,22.2,0,0,1-42.9,0H51.3a24.2,24.2,0,0,0,47,0Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-2"
						d="M74.8,51.1A24.4,24.4,0,0,0,51.3,69.5h2.1a22.2,22.2,0,0,1,42.9,0h2A24.3,24.3,0,0,0,74.8,51.1Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
		</Active>
	) : hover ? (
		<Hover
			height={height}
			viewBox="0 0 50.96 43.63"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Trailer_-_Inactive_copy">
				<polyline
					className="cls-3"
					points="15.48 20.01 15.48 24.51 21.78 20.01 15.48 15.51"
				/>
				<path
					className="cls-1"
					d="M32.13,12.36v15.3h-12.44l9.17-6.55,1.54-1.1-1.54-1.1-9.17-6.55h12.44m1.35-1.35H15.48l12.6,9-12.6,9h18V11.01h0Z"
				/>
				<line className="cls-3" x1="15.48" x2="33.48" y1="32.61" y2="32.61" />
			</g>
			<g id="Layer_3">
				<g>
					<path
						className="cls-2"
						d="M48.84,21.81c0,2.14-.32,4.2-.91,6.15h2.21c.53-1.96,.82-4.02,.82-6.15s-.29-4.19-.82-6.15h-2.21c.59,1.95,.91,4.01,.91,6.15Z"
					/>
					<path
						className="cls-2"
						d="M2.12,21.81c0-2.14,.32-4.2,.91-6.15H.82c-.53,1.96-.82,4.02-.82,6.15s.29,4.19,.82,6.15H3.03c-.59-1.95-.91-4.01-.91-6.15Z"
					/>
				</g>
				<path
					className="cls-2"
					d="M6.46,15.67C9.06,7.64,16.59,1.8,25.48,1.8s16.42,5.83,19.02,13.86h1.88C43.73,6.63,35.37,0,25.48,0S7.24,6.63,4.57,15.67h1.88Z"
				/>
				<path
					className="cls-2"
					d="M44.5,27.96c-2.6,8.03-10.14,13.86-19.02,13.86s-16.42-5.83-19.02-13.86h-1.88c2.66,9.04,11.02,15.67,20.91,15.67s18.24-6.63,20.91-15.67h-1.88Z"
				/>
			</g>
		</Hover>
	) : (
		<InActive
			height={height}
			viewBox="0 0 47 48.4"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g data-name="Trailer - Inactive" id="Trailer_-_Inactive">
				<polyline
					className="cls-1"
					points="12.5 22.2 12.5 27.2 19.5 22.2 12.5 17.2"
				/>
				<path
					className="cls-2"
					d="M82.3,64.8v17H68.5l10.2-7.3,1.7-1.2-1.7-1.2L68.5,64.8H82.3m1.5-1.5h-20l14,10-14,10h20v-20Z"
					transform="translate(-51.3 -51.1)"
				/>
				<line className="cls-1" x1="12.5" x2="32.5" y1="36.2" y2="36.2" />
			</g>
			<g data-name="Frame - Inactive" id="Frame_-_Inactive">
				<g>
					<path
						className="cls-3"
						d="M96.3,81.1a22.2,22.2,0,0,1-42.9,0H51.3a24.2,24.2,0,0,0,47,0Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M74.8,51.1A24.4,24.4,0,0,0,51.3,69.5h2.1a22.2,22.2,0,0,1,42.9,0h2A24.3,24.3,0,0,0,74.8,51.1Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
				<g>
					<path
						className="cls-3"
						d="M94.9,75.3a18.5,18.5,0,0,1-.9,5.8h2.1a22,22,0,0,0,.8-5.8,22.5,22.5,0,0,0-.8-5.8H94A19,19,0,0,1,94.9,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
					<path
						className="cls-3"
						d="M54.8,75.3a19,19,0,0,1,.9-5.8H53.6a22.5,22.5,0,0,0-.8,5.8,22,22,0,0,0,.8,5.8h2.1A18.5,18.5,0,0,1,54.8,75.3Z"
						transform="translate(-51.3 -51.1)"
					/>
				</g>
			</g>
		</InActive>
	);
};

import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';

const SCTALink = styled.a`
	font-size: 25px;
	letter-spacing: 0.05em;
	font-family: 'Rift';
	font-weight: bold;
	padding: 2px 42px;

	@media (min-width: ${Breakpoint.MEDIUM}px) {
		font-size: 22px;
		letter-spacing: 0.14em;
		padding: 6px 72px;
	}
`;

export interface CountdownCTAProps {
	cta?: { label: string; link: string };
}

export const CountdownCTA: FC<CountdownCTAProps> = (
	props: CountdownCTAProps,
) => {
	return (
		<div className="flex items-center">
			<SCTALink
				className="text-offWhite"
				href={props.cta?.link}
				rel="noreferrer"
				target="_blank"
			>
				{props.cta?.label}
			</SCTALink>
		</div>
	);
};

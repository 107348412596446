import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

const SVGContainer = styled(motion.div)<{ image: string }>`
	background-image: url(${(props) => props.image});
`;

export interface GenesisImageAnimatorProps {
	image: string;
}

export const GenesisImageAnimator: FC<GenesisImageAnimatorProps> = (
	props: GenesisImageAnimatorProps,
) => {
	//

	return (
		<div>
			<SVGContainer
				className="absolute h-full w-full bg-cover bg-center"
				image={props.image}
			/>
		</div>
	);
};

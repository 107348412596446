import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import {
	ChevronIcon,
	DiamondIndicatorIcon,
	ModalCloseButton,
} from '@home/components/atoms';

export interface ModalContent {
	leftContent?: ReactNode;
	rightContent?: ReactNode;
	centerContent?: ReactNode;
}

export interface BaseModalProps {
	onClose: () => void;
	onChangeIndex?: (newIndex: number) => void;
	index: number;
	content: ModalContent[];
}

const ContentContainer = styled.div`
	margin-top: auto;

	@media (max-width: ${Breakpoint.SMALL}px) {
		margin-top: -20%;
	}
`;

const PageIndicator = styled.div`
	@media (max-width: ${Breakpoint.MEDIUM}px) {
		background: #210d2550;
		backdrop-filter: blur(2px);
	}
`;

export const BaseModal: FC<BaseModalProps> = (props: BaseModalProps) => {
	const [selected, setSelected] = useState<number>(props.index);

	const _prev = () => {
		if (selected === 0) {
			setSelected(props.content.length - 1);
		} else {
			setSelected(selected - 1);
		}
	};

	const _next = () => {
		if (selected === props.content.length - 1) {
			setSelected(0);
		} else {
			setSelected(selected + 1);
		}
	};

	useEffect(() => {
		if (props.onChangeIndex) {
			props.onChangeIndex(selected);
		}
	}, [selected, props]);

	const getLeftContent = () => props.content[selected].leftContent;
	const getRightContent = () => props.content[selected].rightContent;
	const getCenterContent = () => props.content[selected].centerContent;

	const baseClasses =
		'flex flex-col md:flex-row items-center lg:max-w-7xl w-screen min-h-full md:justify-center px-4 md:px-12';

	const centerClasses = `${baseClasses} justify-center`;

	return (
		<div className="overflow-scroll md:overflow-hidden h-screen bg-black bg-opacity-80 md:bg-transparent relative py-20">
			<div className={getCenterContent() ? centerClasses : baseClasses}>
				<div className="md:hidden fixed right-4 top-4 z-50">
					<ModalCloseButton onClick={props.onClose} />
				</div>
				{getLeftContent() && (
					<div className="w-full md:w-5/12 mt-0">{getLeftContent()}</div>
				)}
				<div className="w-full md:w-auto flex flex-col">
					<div className="hidden md:flex flex-row justify-end mb-2 mr-7">
						<ModalCloseButton onClick={props.onClose} />
					</div>
					{(getRightContent() || getCenterContent()) && (
						<ContentContainer className="flex flex-row items-center">
							<div className="hidden md:block mr-[30px]">
								<ChevronIcon direction="left" height={48} onClick={_prev} />
							</div>
							<div className="flex-1">
								{getRightContent() || getCenterContent()}
							</div>
							<div className="hidden md:block ml-[30px]">
								<ChevronIcon direction="right" height={48} onClick={_next} />
							</div>
						</ContentContainer>
					)}

					{props.content.length > 1 && (
						<PageIndicator className="fixed left-0 bottom-0 md:bottom-auto md:relative flex flex-row justify-between md:justify-center w-full md:w-auto items-center px-6 py-4 md:px-0">
							<div className="md:hidden mr-10">
								<ChevronIcon direction="left" height={30} onClick={_prev} />
							</div>
							<div className="flex flex-row">
								{props.content.map((_, index) => (
									<span key={index} className="mx-1">
										<DiamondIndicatorIcon active={index === selected} />
									</span>
								))}
							</div>
							<div className="md:hidden ml-10">
								<ChevronIcon direction="right" height={30} onClick={_next} />
							</div>
						</PageIndicator>
					)}
				</div>
			</div>
		</div>
	);
};

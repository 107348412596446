import { FC } from 'react';

import { Text } from '@home/components/atoms';
import {
	BaseModal,
	BaseModalContentContainer,
	NotificationGallery,
} from '@home/components/molecules';

export interface NotificationModalProps {
	onClose: () => void;
	title: string;
	description: string;
	mainImage: string;
	mainImageMobile?: string;
	leftImage: string;
	rightImage: string;
}

export const NotificationModal: FC<NotificationModalProps> = (
	props: NotificationModalProps,
) => {
	const {
		title,
		description: expandedDescription,
		mainImage,
		mainImageMobile,
		leftImage,
		rightImage,
	} = props;

	return (
		<BaseModal
			content={[
				{
					leftContent: (
						<NotificationGallery
							leftImage={leftImage}
							mainImage={mainImage}
							mainImageMobile={mainImageMobile}
							rightImage={rightImage}
						/>
					),
					rightContent: (
						<BaseModalContentContainer
							alert
							content={
								<Text box innerHtml>
									{expandedDescription}
								</Text>
							}
							title={title}
						/>
					),
				},
			]}
			index={0}
			onClose={props.onClose}
		/>
	);
};

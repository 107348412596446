import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';

export interface TitleProps {
	level: 1 | 2 | 3 | 4 | 5 | 6;
	children: React.ReactNode;
	className?: string;
}

const SH1 = styled.h1`
	font-family: 'Rift';

	font-size: 60px;
	letter-spacing: 0.075em;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		font-size: 30px;
		line-height: 30px;
		letter-spacing: 0.06em;
	}
`;

const SH2 = styled.h2`
	font-family: 'Prohibition';

	font-size: 31.25px;
	letter-spacing: 0.005em;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		font-size: 24px;
		line-height: 28px;
		letter-spacing: 0.06em;
	}
`;

const SH3 = styled.h3`
	font-family: 'Prohibition';
	font-style: normal;
	font-weight: 400;

	font-size: 20px;
	letter-spacing: 0.075em;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.08em;
	}
`;

const SH4 = styled.h4``;

const SH5 = styled.h5`
	font-size: 14px;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		font-size: 13px;
	}
`;

const SH6 = styled.h6``;

export const Title: FC<TitleProps> = ({
	level,
	children,
	className = '',
}: TitleProps) => {
	const classes = `text-offWhite font-normal ${className}`;

	return level === 1 ? (
		<SH1 className={classes}>{children}</SH1>
	) : level === 2 ? (
		<SH2 className={classes}>{children}</SH2>
	) : level === 3 ? (
		<SH3 className={classes}>{children}</SH3>
	) : level === 4 ? (
		<SH4 className={classes}>{children}</SH4>
	) : level === 5 ? (
		<SH5 className={classes}>{children}</SH5>
	) : (
		<SH6 className={classes}>{children}</SH6>
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { clamp, map, randomRange } from '@common/utils';
import {
	RadarShip,
	RadarShipData,
	RadarShipProps,
} from '@home/components/atoms';

interface RadarShipsContainerProps {
	isVisible: boolean;
	data: RadarShipData[];
}

export const RadarShipsContainer = (props: RadarShipsContainerProps) => {
	const { isVisible, data } = props;
	const { width, height } = useWindowSize();

	// fix size bug
	const [isRendered, setIsRendered] = useState(false);
	useEffect(() => setIsRendered(true), []);

	const ships = useMemo(() => {
		if (width <= 0 && height <= 0) return [];

		const progress = width < Breakpoint.MEDIUM ? 0.6 : 0.5;
		const MIN_SPACE_BETWEEN_SHIPS = width < Breakpoint.MEDIUM ? 60 : 90; // space in pixels on the x-axis between the ships in their final position
		const maxNumOfShips = width < Breakpoint.LARGE ? 5 : 10; // Limit amount of ships for performance

		const shipData = data.filter((_, index) => index < maxNumOfShips);

		return shipData.map((ship, index, array) => {
			const shipProgress = clamp(progress + randomRange(-0.15, 0), 0.1, 0.7);
			const shipAnimationDuration = shipProgress * 6;
			const startPosition = [
				map(
					index / (array.length - 1),
					0,
					1,
					-width * 0.2,
					width + width * 0.2,
				),
				0,
			];
			const endPosition = [
				map(
					index / (array.length - 1),
					0,
					1,
					width * 0.5 - maxNumOfShips * 0.45 * MIN_SPACE_BETWEEN_SHIPS,
					width * 0.5 + maxNumOfShips * 0.45 * MIN_SPACE_BETWEEN_SHIPS,
				),
				shipProgress * height,
			];
			const maxAbsoluteCurveOffset = width * 0.08;
			const curveOffset = map(
				index / (array.length - 1),
				0,
				1,
				maxAbsoluteCurveOffset,
				-maxAbsoluteCurveOffset,
			);

			return {
				id: ship.id,
				label: ship.label,
				idLabel: ship.idLabel,
				startPosition,
				endPosition,
				curveOffset,
				duration: shipAnimationDuration,
				delay: randomRange(0, 0.5),
			};
		}) as RadarShipProps[];

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isRendered, width]);

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.g
					animate={{ opacity: 1, transition: { duration: 0.8 } }}
					exit={{ opacity: 0, transition: { duration: 0.4 } }}
					initial={{ opacity: 0 }}
				>
					{ships.map((ship) => {
						return <RadarShip key={ship.id} {...ship} />;
					})}
				</motion.g>
			)}
		</AnimatePresence>
	);
};

RadarShipsContainer.defaultProps = {
	isVisible: true,
	data: [],
	progress: 0,
};

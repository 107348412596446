type Point = [number, number];

export const angleBetweenPointsRadians = (point1: Point, point2: Point) => {
	const xDiff = point2[0] - point1[0];
	const yDiff = point2[1] - point1[1];

	return Math.atan2(yDiff, xDiff);
};

export const angleBetweenPointsDegrees = (point1: Point, point2: Point) => {
	const radians = angleBetweenPointsRadians(point1, point2);

	return radians * (180 / Math.PI);
};

export const randomRange = (min: number, max: number) => {
	return Math.random() * (max - min) + min;
};

/**
 * Returns a value between -1 and 1
 */
export const normalize = (value: number, dimension: number) => {
	return (value / dimension) * 2 - 1;
};

export const clamp = (value: number, min: number, max: number) => {
	return Math.min(Math.max(value, min), max);
};

export function map(
	n: number,
	start1: number,
	end1: number,
	start2: number,
	end2: number,
) {
	return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

/**
 * Quadratic curve utils
 * https://gist.github.com/pbeshai/72c446033a98f99ce1e1371c6eee9644#file-script-js-L15
 */
export type QuadraticCurve = {
	start: Point;
	control: Point;
	end: Point;
};

export function quadraticPath(q: QuadraticCurve) {
	return `M${q.start[0]},${q.start[1]} Q${q.control[0]},${q.control[1]} ${q.end[0]},${q.end[1]}`;
}

// B(t) = (1 - t)^2P0 + 2(1 - t)tP1 + t^2P2
export function interpolateQuadraticBezier(
	start: Point,
	control: Point,
	end: Point,
) {
	// 0 <= t <= 1
	return function interpolator(t: number) {
		return [
			Math.pow(1 - t, 2) * start[0] +
				2 * (1 - t) * t * control[0] +
				Math.pow(t, 2) * end[0],
			Math.pow(1 - t, 2) * start[1] +
				2 * (1 - t) * t * control[1] +
				Math.pow(t, 2) * end[1],
		];
	};
}

// B'(t) = 2(1 - t)(P1 - P0) + 2t(P2 - P1)
export function interpolateQuadraticBezierAngle(
	start: Point,
	control: Point,
	end: Point,
) {
	// 0 <= t <= 1
	return function interpolator(t: number) {
		const tangentX =
			2 * (1 - t) * (control[0] - start[0]) + 2 * t * (end[0] - control[0]);
		const tangentY =
			2 * (1 - t) * (control[1] - start[1]) + 2 * t * (end[1] - control[1]);

		return Math.atan2(tangentY, tangentX) * (180 / Math.PI);
	};
}

export function distanceBetweenTwoPoints(point1: Point, point2: Point) {
	const xDiff = point2[0] - point1[0];
	const yDiff = point2[1] - point1[1];

	return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { PortraitIcon, Text, Title } from '@home/components/atoms';

const Screen = styled(motion.div)`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #3b113f75;
	backdrop-filter: blur(8px);
`;

const Content = styled(motion.div)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 200px;
	text-align: center;
`;

export interface MobileLandscapeScreenContent {
	title: string;
	description: string;
}

interface MobileLandscapeScreenProps {
	content: MobileLandscapeScreenContent;
}

export const MobileLandscapeScreen = ({
	content,
}: MobileLandscapeScreenProps) => {
	return (
		<Screen>
			<Content>
				<PortraitIcon />
				<Title className="mt-6" level={2}>
					{content.title}
				</Title>
				<Text className="mt-4">{content.description}</Text>
			</Content>
		</Screen>
	);
};

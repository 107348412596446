import React from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';

const SSVG = styled.svg`
	width: 48px;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		width: 40px;
	}
`;

interface ModalCloseButtonProps {
	onClick: () => void;
}

export const ModalCloseButton = ({ onClick }: ModalCloseButtonProps) => {
	return (
		<div className="hover:scale-110">
			<SSVG
				className="cursor-pointer"
				fill="none"
				viewBox="0 0 40 40"
				xmlns="http://www.w3.org/2000/svg"
				onClick={onClick}
			>
				<path
					clipRule="evenodd"
					d="M36.2528 25C34.1177 31.9487 27.6487 37 20 37C12.3513 37 5.88234 31.9487 3.7472 25H6.00011C5.64209 23.3919 5.45307 21.7185 5.45307 20C5.45307 18.2815 5.64209 16.6081 6.00011 15H3.7472C5.88234 8.05133 12.3513 3 20 3C27.6487 3 34.1177 8.05133 36.2528 15H34C34.358 16.6081 34.547 18.2815 34.547 20C34.547 21.7185 34.358 23.3919 34 25H36.2528ZM3.00011 20L3.00035 20.1095C3.00012 20.073 3 20.0365 3 20C3 19.9635 3.00012 19.927 3.00035 19.8905L3.00011 20Z"
					fill="#3B113F"
					fillRule="evenodd"
					opacity="0.75"
				/>
				<path
					d="M26 13L20 19L14 13V16.01L17.99 20L14 23.99V27L20 21L26 27V23.99L22.01 20L26 16.01V13Z"
					fill="#FFF1DF"
				/>
				<path
					d="M2 20C2 18.6301 2.13772 17.2924 2.40007 16H4.44603C4.15412 17.2864 4 18.6252 4 20C4 21.3748 4.15412 22.7136 4.44603 24H2.40007C2.13772 22.7076 2 21.3699 2 20Z"
					fill="#BF3054"
				/>
				<path
					clipRule="evenodd"
					d="M20.0003 2C11.434 2 4.26527 7.98405 2.44635 16H0.400391C2.25347 6.87104 10.3245 0 20.0003 0C29.8379 0 38.0165 7.10267 39.6879 16.4597C39.6605 16.3058 39.6312 16.1526 39.6002 16H37.5543C35.7354 7.98405 28.5667 2 20.0003 2ZM37.5543 24C35.7354 32.016 28.5667 38 20.0003 38C11.434 38 4.26527 32.016 2.44635 24H0.400391C2.25347 33.129 10.3245 40 20.0003 40C29.6762 40 37.7472 33.129 39.6002 24H37.5543Z"
					fill="#BF3054"
					fillRule="evenodd"
				/>
				<path
					d="M38 20C38 18.6301 37.8623 17.2924 37.5999 16H35.554C35.8459 17.2864 36 18.6252 36 20C36 21.3748 35.8459 22.7136 35.554 24H37.5999C37.8623 22.7076 38 21.3699 38 20Z"
					fill="#BF3054"
				/>
			</SSVG>
		</div>
	);
};

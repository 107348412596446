export const keyframesRightToLeft = (maxWidth: number) => `
  @keyframes afterToRight {
    from {
      opacity: 0;
      transform: matrix3d(1,0,0.00,-0.002,0,1,0.00,0,0,0,1,0,0,0,0,1) scale(0.85) translateX(115%);
    }
    to {
      opacity: 1;
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
    }
  };

  @keyframes rightToCenterRight {
    from {
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      right: -${maxWidth}px;
    }
    to {
      transform: scale(1);
      right: 0px;
    }
  };

  @keyframes rightToCenter {
    from {
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      right: -${maxWidth}px;
    }
    to {
      transform: scale(1);
      right: 0px;
    }
  };

  @keyframes centerToLeft {
    from {
      transform: scale(1);
      right: -${maxWidth}px;
    }
    to {
      transform: matrix3d(1,0,0.00, 0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      right: 0px;
    }
  };

  @keyframes centerRightToCenterLeft {
    from {
      right: -${maxWidth}px;
    }
    to {
      right: 0px;
    }
  };

  @keyframes centerLeftToLeft {
    from {
      transform: scale(1);
      right: -${maxWidth}px;
    }
    to {
      transform: matrix3d(1,0,0.00, 0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      right: 0px;
    }
  };

  @keyframes leftToBefore {
    from {
      opacity: 1;
      transform: matrix3d(1,0,0.00,0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      left: 0%;
    }
    to {
      opacity: 0;
      transform: matrix3d(1,0,0.00,0.0015,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.5);
      left: -32%;
    }
  };
`;

export const keyframesLeftToRight = (maxWidth: number) => `
  @keyframes beforeToLeft {
    from {
      opacity: 0;
      transform: matrix3d(1,0,0.00,0.002,0,1,0.00,0,0,0,1,0,0,0,0,1) scale(0.85) translateX(-115%);
    }
    to {
      opacity: 1;
      transform: matrix3d(1,0,0.00,0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
    }
  };

  @keyframes leftToCenterLeft {
    from {
      transform:  matrix3d(1,0,0.00, 0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      left: -${maxWidth}px;
    }
    to {
      transform: scale(1);
      left: 0;
    }
  };

  @keyframes leftToCenter {
    from {
      transform:  matrix3d(1,0,0.00, 0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      left: -${maxWidth}px;
    }
    to {
      transform: scale(1);
      left: 0;
    }
  };

  @keyframes centerToRight {
    from {
      transform: scale(1);
      left: -${maxWidth}px;
    }
    to {
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      left: 0px;
    }
  };

  @keyframes centerLeftToCenterRight {
    from {
      left: -${maxWidth}px;
    }
    to {
      left: 0px;
    }
  };
  
  @keyframes centerRightToRight {
    from {
      transform: scale(1);
      left: -${maxWidth}px;
    }
    to {
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      left: 0px;
    }
  };

  @keyframes rightToAfter {
    from {
      opacity: 1;
      transform: matrix3d(1,0,0.00,-0.001,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.1);
      right: 0%;
    }
    to {
      opacity: 0;
      transform: matrix3d(1,0,0.00,-0.0015,0.00,1,0.00,0,0,0,1,0,0,0,0,1) scale(1.5);
      right: -32%;
    }
  };
`;

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';

const SvgContainer = styled(motion.div)<Props>`
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	z-index: ${(props) => props.zIndex};
`;

const Svg = styled.svg`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3396px;
	opacity: 0.15;

	@media (max-width: ${Breakpoint.SMALL}px) {
		width: 2400px;
	}
`;

interface Props {
	readonly zIndex: number;
	isVisible: boolean;
}

export const HudGridCircles = (props: Props) => {
	const { isVisible } = props;

	return (
		<AnimatePresence>
			{isVisible && (
				<SvgContainer
					{...props}
					animate={{ opacity: 1, transition: { duration: 0.8 } }}
					exit={{ opacity: 0, transition: { duration: 0.8 } }}
					initial={{ opacity: 0 }}
				>
					<Svg
						preserveAspectRatio="none"
						viewBox="0 0 3396 1768"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g
							fill="none"
							stroke="#bf3054"
							strokeMiterlimit="10"
							strokeWidth="2"
						>
							<circle cx="1161.5" cy="469.5" r="420.5" />
							<circle cx="1140.5" cy="1310.5" r="420.5" />
							<circle cx="603.5" cy="661.5" r="602.5" />
							<circle cx="2234.5" cy="469.5" r="420.5" />
							<circle cx="2255.5" cy="1310.5" r="420.5" />
							<circle cx="2792.5" cy="661.5" r="602.5" />
							<path d="m1162 1h536v889h-536z" />
							<path
								d="m1698 1h536v889h-536z"
								transform="matrix(-1 0 0 -1 3932 891)"
							/>
							<circle cx="1699" cy="1164.5" r="420.5" />
							<circle cx="1699" cy="1164.5" r="156.5" />
							<circle cx="1699" cy="1164.5" r="602.5" />
							<path d="m1343 530.26v410.49l355.5 205.25 355.5-205.25v-410.49l-355.5-205.25z" />
						</g>
					</Svg>
				</SvgContainer>
			)}
		</AnimatePresence>
	);
};

HudGridCircles.defaultProps = {
	zIndex: 1,
	isVisible: false,
};

import React from 'react';

import { ContentSection } from '@common/enums';
import {
	CompanyOverviewIcon,
	GameOverviewIcon,
	GenesisIcon,
	IntroIcon,
	RadarIcon,
	RoadmapIcon,
	TrailerIcon,
} from '@home/components/atoms';

interface NavbarIconProps {
	section: ContentSection;
	active: boolean;
	hover?: boolean;
	height?: number;
	width?: number;
}

export const NavbarIcon = ({
	section,
	active,
	hover,
	height = 32,
	width = 32,
}: NavbarIconProps) => {
	return section === ContentSection.Intro ? (
		<IntroIcon active={active} height={height} hover={hover} width={width} />
	) : section === ContentSection.Trailer ? (
		<TrailerIcon active={active} height={height} hover={hover} width={width} />
	) : section === ContentSection.GameOverview ? (
		<GameOverviewIcon
			active={active}
			height={height}
			hover={hover}
			width={width}
		/>
	) : section === ContentSection.Radar ? (
		<RadarIcon active={active} height={height} hover={hover} width={width} />
	) : section === ContentSection.Roadmap ? (
		<RoadmapIcon active={active} height={height} hover={hover} width={width} />
	) : section === ContentSection.Genesis ? (
		<GenesisIcon active={active} height={height} hover={hover} width={width} />
	) : section === ContentSection.CompanyOverview ? (
		<CompanyOverviewIcon
			active={active}
			height={height}
			hover={hover}
			width={width}
		/>
	) : (
		<></>
	);
};

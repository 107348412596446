const START_DELAY = 0.4;
const VIDEO_TRANSITION_DURATION = 1.2;

const CLIP_PATH_ENTRY = [
	'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
];

const CLIP_PATH_EXIT = [
	'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
	'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
];

const CLIP_PATH_FRAME_ENTRY = [
	'polygon(0% 0%, 100% 0%, 50% 0%, 50% 50%, 50% 50%, 50% 0%)',
	'polygon(0% 0%, 100% 0%, 75% 0%, 50% 50%, 50% 50%, 25% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 0%, 50% 50%, 50% 50%, 0% 0%)',
	'polygon(0% 0%, 100% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
	'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 0% 100%, 0% 100%)',
];

const CLIP_PATH_FRAME_EXIT = [
	'polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)',
	'polygon(25% 0%, 75% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%)',
	'polygon(50% 0%, 50% 0%, 100% 100%, 100% 100%, 0% 100%, 0% 100%)',
	'polygon(50% 0%, 50% 0%, 50% 100%, 100% 100%, 0% 100%, 50% 100%)',
];

export const VIDEO_TRANSITION = {
	initial: {
		clipPath: CLIP_PATH_ENTRY[0],
		opacity: 0,
	},

	animate: {
		clipPath: CLIP_PATH_ENTRY,
		opacity: 1,
		transition: {
			duration: VIDEO_TRANSITION_DURATION,
			ease: [0.76, 0, 0.24, 1],
			delay: START_DELAY,
		},
	},

	exit: {
		clipPath: CLIP_PATH_EXIT,
		opacity: 0,
		transition: {
			duration: 0.8,
			ease: [0.32, 0, 0.67, 0],
		},
	},
};

export const FRAME_TRANSITION = {
	initial: {
		clipPath: CLIP_PATH_FRAME_ENTRY[0],
		opacity: 1,
	},

	animate: {
		clipPath: CLIP_PATH_FRAME_ENTRY,
		opacity: 1,
		transition: {
			duration: VIDEO_TRANSITION_DURATION - 0.4,
			ease: 'linear',
			delay: START_DELAY,
		},
	},

	exit: {
		clipPath: CLIP_PATH_FRAME_EXIT,
		opacity: 1,
		transition: {
			duration: 0.7,
			ease: 'linear',
		},
	},
};

import React from 'react';

export const PortraitIcon = () => {
	return (
		<svg
			fill="none"
			height="120"
			viewBox="0 0 118 120"
			width="118"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				clipRule="evenodd"
				d="M0 49C5.16642 21.1164 29.6142 0 58.9939 0C88.3737 0 112.821 21.1164 117.988 49H114.988C115.649 52.566 115.994 56.2427 115.994 60C115.994 64.464 115.506 68.8141 114.582 73H117.582C111.643 99.8871 87.667 120 58.9939 120C30.3208 120 6.34529 99.8871 0.406046 73H3.40604C2.48139 68.8141 1.9939 64.464 1.9939 60C1.9939 56.2427 2.33927 52.566 2.99999 49H0ZM3.02914 49H6.02914C5.33357 52.5593 4.9691 56.2371 4.9691 60C4.9691 64.4723 5.48393 68.8243 6.45757 73H3.45757C9.34032 98.23 31.9722 117.025 58.9939 117.025C86.0156 117.025 108.647 98.23 114.53 73H111.53C112.504 68.8243 113.019 64.4723 113.019 60C113.019 56.2371 112.654 52.5593 111.959 49H114.959C109.833 22.7696 86.7249 2.97521 58.9939 2.97521C31.2629 2.97521 8.15513 22.7696 3.02914 49Z"
				fill="#BF3054"
				fillRule="evenodd"
			/>
			<path
				clipRule="evenodd"
				d="M61.9939 56L61.9939 80L33.9939 80L33.9939 56L61.9939 56ZM59.9939 58L35.9939 58L35.9939 78L59.9939 78L59.9939 58Z"
				fill="#BF3054"
				fillRule="evenodd"
			/>
			<path
				clipRule="evenodd"
				d="M53.701 42L48.701 37V39.8284L50.5797 41.7071H48.9939C44.5756 41.7071 40.9939 45.2888 40.9939 49.7071H42.9939C42.9939 46.3934 45.6802 43.7071 48.9939 43.7071H50.5797L48.701 45.5858V48.4142L53.701 43.4142C54.0915 43.0237 54.0915 42.3905 53.701 42Z"
				fill="#FFF1DF"
				fillRule="evenodd"
			/>
			<path
				clipRule="evenodd"
				d="M59.9939 40H83.9939V80H59.9939L59.9939 40ZM61.9939 42L61.9939 78H81.9939V42H61.9939Z"
				fill="#FFF1DF"
				fillRule="evenodd"
			/>
			<path
				d="M71.9939 69L74.3223 71.3284L71.9939 73.6569L69.6655 71.3284L71.9939 69Z"
				fill="#FFF1DF"
			/>
		</svg>
	);
};

import { motion } from 'framer-motion';
import { FC, ReactNode } from 'react';

export interface ModalProps {
	closeable?: boolean;
	children: ReactNode | string;
}

const modalFramerVariants = {
	initial: { opacity: 0, y: 24 },
	animate: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.6 },
	},
	exit: { opacity: 0, y: -12, transition: { duration: 0.3 } },
};

export const Modal: FC<ModalProps> = (props: ModalProps) => {
	return (
		<motion.div variants={modalFramerVariants}>
			<div>{props.children}</div>
		</motion.div>
	);
};

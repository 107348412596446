import { motion } from 'framer-motion';
import { FC } from 'react';

import { Svg } from '@home/components/atoms';

import { ANIMATION_VARIANTS, getMobileVariant } from '../animations';

export interface GameFeatureFrameAnimatorProps {
	position: 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight';
	mobile?: boolean;
}

export const GameFeatureFrameAnimator: FC<GameFeatureFrameAnimatorProps> = (
	props: GameFeatureFrameAnimatorProps,
) => {
	return props.mobile ? (
		<motion.div
			className="absolute inset-0"
			variants={getMobileVariant(props.position)}
		>
			<Svg.GameOverviewFeatureFrameMobile />
		</motion.div>
	) : (
		<motion.div
			className="absolute inset-0"
			variants={ANIMATION_VARIANTS[props.position](false)}
		>
			{props.position === 'TopLeft' && <Svg.GameOverviewFeatureFrameTopLeft />}

			{props.position === 'TopRight' && (
				<Svg.GameOverviewFeatureFrameTopRight />
			)}

			{props.position === 'BottomLeft' && (
				<Svg.GameOverviewFeatureFrameBottomLeft />
			)}

			{props.position === 'BottomRight' && (
				<Svg.GameOverviewFeatureFrameBottomRight />
			)}
		</motion.div>
	);
};

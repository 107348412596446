import React from 'react';
import styled from 'styled-components';

const Border = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
`;

export const RoadmapBorder = () => (
	<Border
		fill="none"
		height="100%"
		viewBox="0 0 198 229"
		width="100%"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M36.5703 219L42.4703 224H179.43L183.52 219H36.5703Z"
			fill="#FFF1DF"
		/>
		<path
			d="M186.66 10.1096L192.02 14.8996V207.5L179.1 223H44.0995C42.9295 223 41.7795 222.58 40.8895 221.83L16.8095 201.63C15.6795 200.68 15.0195 199.28 15.0195 197.8V37.7896C15.0195 36.1496 15.8195 34.6196 17.1695 33.6796L50.6095 10.4596C50.9495 10.2196 51.3495 10.0996 51.7595 10.0996H186.67L186.66 10.1096ZM187.43 8.09961H51.7595C50.9295 8.09961 50.1395 8.34961 49.4595 8.82961L16.0295 32.0396C14.1495 33.3496 13.0195 35.4996 13.0195 37.7896V197.79C13.0195 199.86 13.9295 201.82 15.5195 203.15L39.5995 223.35C40.8595 224.41 42.4595 224.99 44.0995 224.99H180.04L194.02 208.21V13.9996L187.43 8.1096V8.09961Z"
			fill="#FFF1DF"
		/>
		<path
			d="M182.14 227.93C181.57 228.61 180.73 229 179.84 229H31.89C29.77 229 27.72 228.25 26.1 226.89L4.28998 208.59C1.56998 206.31 0 202.94 0 199.4V36.25C0 32.33 1.91 28.67 5.12 26.42L40.52 1.63C42.03 0.570005 43.84 0 45.68 0H184.54L198 12V209L182.12 227.93H182.14ZM196.06 13L188.23 6H51.75C50.52 6 49.32 6.38 48.31 7.08L14.88 30.29C12.46 31.97 11.01 34.73 11.01 37.68V197.79C11.01 200.45 12.19 202.97 14.22 204.68L38.3 224.88C39.92 226.24 41.97 226.99 44.09 226.99H181.01L196.01 208.99H196.06V13Z"
			fill="url(#paint0_linear_731_14473)"
		/>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_731_14473"
				x1="0.02"
				x2="198.02"
				y1="114.5"
				y2="114.5"
			>
				<stop stopColor="#BF3054" />
				<stop offset="1" stopColor="#741F7A" />
			</linearGradient>
		</defs>
	</Border>
);

import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SMenuItem = styled(motion.div)`
	display: flex;
	align-items: center;
	column-gap: 16px;
	transition: all 0.3s ease;
`;

interface MenuItemProps {
	active: boolean;
	children: ReactNode;
	onClick?: () => void;
	onHoverStart?: () => void;
	onHoverEnd?: () => void;
}

export const MenuItem = ({
	active,
	children,
	onClick,
	onHoverStart,
	onHoverEnd,
}: MenuItemProps) => {
	const baseClasses = 'text-offWhite';
	const activeClasses = ``;
	const inActiveClasses = `cursor-pointer opacity-50`;

	return (
		<SMenuItem
			className={`${baseClasses} ${active ? activeClasses : inActiveClasses}`}
			onClick={onClick}
			onHoverEnd={onHoverEnd}
			onHoverStart={onHoverStart}
		>
			{children}
		</SMenuItem>
	);
};

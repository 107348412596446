import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { FugitiveAlertContent } from '@home/types';

const SImageContainer = styled(motion.div)<{
	image: string;
	clipPath: string;
	top?: boolean;
}>`
	background-image: url(${(props) => props.image});
	background-size: ${({ top }) => (top ? 'contain' : 'cover')};
	background-repeat: no-repeat;
	background-position: ${({ top }) => (top ? 'top' : 'bottom')} left;
	clip-path: ${(props) => props.clipPath};
	aspect-ratio: 1;
`;

const STopImage = styled.div``;

const SLeftImage = styled.div`
	width: 55%;
	margin-left: 1%;
`;

const SRightImage = styled.div`
	width: 62%;
	left: 36.7%;
	top: -1.5%;
`;

const SMobileContainer = styled.div`
	width: 90%;
	margin-left: 5%;
`;

const SMobileSecondRowContainer = styled.div`
	top: 19.5%;
`;

const STopImageMobile = styled.div``;

const SLeftImageMobile = styled.div`
	width: 56%;
`;

const SRightImageMobile = styled.div`
	width: 56%;
	left: 44%;
`;

export interface FugitiveAlertGalleryProps {
	content: FugitiveAlertContent;
}

export const FugitiveAlertGallery: FC<FugitiveAlertGalleryProps> = (
	props: FugitiveAlertGalleryProps,
) => {
	return (
		<div className="mt-4 md:mt-0 md:max-w-sm md:ml-auto md:mr-12 relative">
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath clipPathUnits="objectBoundingBox" id="CLIP_PATH_TOP_LARGE">
						<path d="M0.866594 0H0.116946C0.114459 0 0.112071 0.000751991 0.110009 0.00219199L0.0090639 0.072281C0.0033704 0.076241 0 0.082719 0 0.089656V0.999997H0.983541V0.089656C0.983541 0.082719 0.980138 0.076241 0.974477 0.072281L0.873531 0.00219199C0.87147 0.000751991 0.869081 0 0.866594 0Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_LEFT_BOTTOM_LARGE"
					>
						<path d="M0.983888 0H0V0.854002C0 0.865112 0.00488598 0.87564 0.0134365 0.882795L0.142683 0.991217C0.14943 0.996917 0.158039 1 0.166822 1H0.619939L0.983888 0Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_RIGHT_BOTTOM_LARGE"
					>
						<path d="M0.999952 0H0.328313L0 0.902088H0.849512C0.857487 0.902088 0.865201 0.899255 0.871287 0.894165L0.987879 0.796359C0.995592 0.789905 1 0.78046 1 0.770385V0H0.999952Z" />
					</clipPath>

					<clipPath clipPathUnits="objectBoundingBox" id="CLIP_PATH_TOP_MOBILE">
						<path d="M0.88735 0H0.112654L0 0.078704V0.364198H1V0.078704L0.88735 0Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_LEFT_BOTTOM_MOBILE"
					>
						<path d="M0.759003 1H0.202216L0 0.858727V0.340721H1L0.759003 1Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_RIGHT_BOTTOM_MOBILE"
					>
						<path d="M0.797222 1H0L0.241667 0.33889H1V0.858334L0.797222 1Z" />
					</clipPath>
				</defs>
			</svg>

			{/* MOBILE */}
			<SMobileContainer className="md:hidden aspect-square relative">
				<STopImageMobile className="w-full aspect-square relative">
					<SImageContainer
						top
						clipPath="url(#CLIP_PATH_TOP_MOBILE)"
						image={
							props.content.mainImageMobile
								? props.content.mainImageMobile.url
								: props.content.mainImage.url
						}
						style={{ transform: 'scale(0.99)' }}
					/>

					<svg
						className="absolute top-0"
						fill="none"
						viewBox="0 0 327 121"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clipRule="evenodd"
							d="M36.109 0.991325C37.0326 0.346043 38.1321 0 39.2589 0H287.741C288.868 0 289.967 0.346044 290.891 0.991325L323.795 23.9792C325.804 25.3823 327 27.6777 327 30.1274V121H0V30.1274C0 27.6777 1.19645 25.3823 3.20469 23.9792L36.109 0.991325ZM39.2589 3C38.7467 3 38.2469 3.15729 37.8271 3.4506L4.92281 26.4385C3.71787 27.2803 3 28.6576 3 30.1274V118H324V30.1274C324 28.6576 323.282 27.2803 322.077 26.4385L289.173 3.4506C288.753 3.15729 288.253 3 287.741 3H39.2589Z"
							fill="#BF3054"
							fillRule="evenodd"
						/>
					</svg>
				</STopImageMobile>

				<SMobileSecondRowContainer className="flex flex-row absolute w-full">
					<SLeftImageMobile className="relative">
						<SImageContainer
							className="w-full"
							clipPath="url(#CLIP_PATH_LEFT_BOTTOM_MOBILE)"
							image={props.content.leftImage.url}
							style={{ transform: 'scale(0.985)' }}
						/>

						<svg
							className="absolute bottom-0"
							fill="none"
							viewBox="0 0 185 122"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clipRule="evenodd"
								d="M36.109 121.009C37.0326 121.654 38.1321 122 39.2589 122H139.549L184.145 0H0V91.8726C0 94.3223 1.19645 96.6177 3.20469 98.0208L36.109 121.009ZM39.2589 119C38.7467 119 38.2469 118.843 37.8271 118.549L4.92281 95.5615C3.71787 94.7197 3 93.3424 3 91.8726V3H179.855L137.451 119H39.2589Z"
								fill="#BF3054"
								fillRule="evenodd"
							/>
						</svg>
					</SLeftImageMobile>

					<SRightImageMobile className="absolute">
						<SImageContainer
							className="w-full"
							clipPath="url(#CLIP_PATH_RIGHT_BOTTOM_MOBILE)"
							image={props.content.rightImage.url}
							style={{ transform: 'scale(0.99)' }}
						/>

						<svg
							className="absolute bottom-0"
							fill="none"
							viewBox="0 0 184 122"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clipRule="evenodd"
								d="M0.354614 122H144.741C145.868 122 146.967 121.654 147.891 121.009L180.795 98.0208C182.804 96.6177 184 94.3223 184 91.8726V0H44.9513L0.354614 122ZM4.64541 119L47.0488 3H181V91.8726C181 93.3424 180.282 94.7197 179.077 95.5615L146.173 118.549C145.753 118.843 145.253 119 144.741 119H4.64541Z"
								fill="#BF3054"
								fillRule="evenodd"
							/>
						</svg>
					</SRightImageMobile>
				</SMobileSecondRowContainer>
			</SMobileContainer>

			{/* SCREENS BIGGER THAN  MOBILE */}
			<div className="hidden md:block relative">
				<STopImage className="relative">
					<SImageContainer
						className="bg-cover bg-center"
						clipPath="url(#CLIP_PATH_TOP_LARGE)"
						image={props.content.mainImage.url}
						style={{ transform: 'scale(0.99)', marginLeft: '1%' }}
					/>

					<svg
						className="absolute top-0"
						fill="none"
						height="100%"
						viewBox="0 0 302 306"
						width="100%"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M265.26 3.39965C265.41 3.39965 265.54 3.43965 265.67 3.52965L296.52 24.9496C297.45 25.5996 298.01 26.6596 298.01 27.7896V303H3.42001V27.7896C3.42001 26.6596 3.98 25.5896 4.91 24.9496L35.77 3.51965C35.89 3.42965 36.02 3.38965 36.17 3.38965H265.27L265.26 3.39965ZM265.27 0.389648H36.17C35.41 0.389648 34.68 0.619647 34.05 1.05965L3.20001 22.4796C1.46001 23.6896 0.429993 25.6696 0.429993 27.7896V306H301.01V27.7896C301.01 25.6696 299.97 23.6896 298.24 22.4796L267.39 1.05965C266.76 0.619647 266.03 0.389648 265.27 0.389648Z"
							fill="#BF3054"
						/>
					</svg>
				</STopImage>

				<div className="flex flex-row mt-1 relative">
					<SLeftImage className="relative">
						<SImageContainer
							className="bg-cover bg-center w-full"
							clipPath="url(#CLIP_PATH_LEFT_BOTTOM_LARGE)"
							image={props.content.leftImage.url}
							style={{ transform: 'scale(0.99)', marginLeft: '1%' }}
						/>

						<svg
							className="absolute top-0"
							fill="none"
							height="100%"
							viewBox="0 0 170 172"
							width="100%"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M165.29 3L104.9 168.92H29.1C28.29 168.92 27.5 168.63 26.88 168.11L4.66 149.47C3.88 148.81 3.42001 147.85 3.42001 146.82V3H165.29ZM169.57 0H0.420013V146.82C0.420013 148.73 1.26001 150.54 2.73001 151.77L24.95 170.41C26.11 171.39 27.59 171.92 29.1 171.92H107L169.57 0Z"
								fill="#BF3054"
							/>
						</svg>
					</SLeftImage>

					<SRightImage className="absolute">
						<SImageContainer
							className="bg-cover bg-center w-full"
							clipPath="url(#CLIP_PATH_RIGHT_BOTTOM_LARGE)"
							image={props.content.rightImage.url}
							style={{ transform: 'scale(0.98)', marginLeft: '0%' }}
						/>

						<svg
							className="absolute top-0"
							fill="none"
							height="100%"
							style={{ marginTop: '-4.5%' }}
							viewBox="0 0 192 172"
							width="100%"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M188 3V146.82C188 147.85 187.55 148.81 186.76 149.47L164.54 168.11C163.92 168.63 163.13 168.92 162.32 168.92H4.70999L65.1 3H188ZM191 0H63L0.429993 171.92H162.33C163.85 171.92 165.32 171.38 166.48 170.41L188.7 151.77C190.17 150.54 191.01 148.74 191.01 146.82V0H191Z"
								fill="#BF3054"
							/>
						</svg>
					</SRightImage>
				</div>
			</div>
		</div>
	);
};

import { AnimatePresence, motion } from 'framer-motion';
import { MouseEvent, ReactNode, createContext, useState } from 'react';
import styled from 'styled-components';

import { zIndex } from '@common/enums';
import { ModalContextProps } from '@common/hooks';
import { Modal } from '@home/components/atoms';

interface SModalContainerProps {
	zIndex: number;
}

const SModalContainer = styled(motion.div)<SModalContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: ${(props) => props.zIndex};
`;

interface ModalContextInterface {
	visible: boolean;
	modal: ModalContextProps | undefined;
	set: (visibile: boolean, modal: ModalContextProps | undefined) => void;
}

export interface ModalProviderProps {
	children: ReactNode | ReactNode[];
}

export const ModalContext = createContext<ModalContextInterface>({
	visible: false,
	modal: undefined,
	set: () => undefined,
});

const modalContainerFramerVariants = {
	initial: { opacity: 0 },
	animate: {
		opacity: 1,
		transition: {
			duration: 0.6,
			staggerChildren: 0.08,
			delayChildren: 0.1,
		},
	},
	exit: { opacity: 0 },
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
	const [modalContext, setModalContext] = useState<{
		visible: boolean;
		modal: ModalContextProps | undefined;
	}>({ visible: false, modal: undefined });

	function _set(visible: boolean, modal: ModalContextProps | undefined) {
		setModalContext({ visible, modal });
	}

	function _close(e: MouseEvent<HTMLElement>) {
		if (
			(modalContext.modal?.closeable ||
				modalContext.modal?.closeable === undefined) &&
			(e.target as HTMLElement).dataset.modalclick === 'modal-background'
		) {
			setModalContext({ ...modalContext, visible: false });
		}
	}

	return (
		<ModalContext.Provider
			value={{
				set: _set,
				visible: modalContext.visible,
				modal: modalContext.modal,
			}}
		>
			{children}

			<AnimatePresence>
				{modalContext.visible && (
					<SModalContainer
						animate="animate"
						data-modalclick="modal-background"
						exit="exit"
						initial="initial"
						variants={modalContainerFramerVariants}
						zIndex={zIndex.MODAL}
						onClick={_close}
					>
						{modalContext.modal ? (
							<Modal>{modalContext.modal.content}</Modal>
						) : null}
					</SModalContainer>
				)}
			</AnimatePresence>
		</ModalContext.Provider>
	);
};

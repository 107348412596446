import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { PlayButton, Text } from '@home/components/atoms';

const Container = styled(motion.div)`
	& #content {
		transition: all 0.3s ease;
	}

	&:hover #content {
		transform: scale(1.1);
	}
`;

const Overlay = styled(motion.div)`
	position: absolute;
	background: #191919;
	opacity: 0.5;
	width: calc(100% - 1.5rem);
	height: calc(100% - 1.5rem);

	@media (min-width: ${Breakpoint.MEDIUM}px) {
		width: calc(100% - 2.5rem);
		height: calc(100% - 2.5rem);
	}
`;

interface VideoOverlayProps {
	text: string;
}

export const VideoOverlay = ({ text }: VideoOverlayProps) => {
	const size = useWindowSize();

	const isMobile = () => size.width < Breakpoint.LARGE;

	return (
		<Container className="absolute w-full h-full inset-0 p-3 md:p-5 z-10 flex items-center justify-center cursor-pointer">
			<motion.div className="w-full h-full z-20 border-2 border-red">
				<motion.div
					className="w-full h-full flex flex-col items-center justify-center gap-y-[12px] lg:gap-y-[15px]"
					id="content"
				>
					<PlayButton
						height={isMobile() ? 48 : 57}
						width={isMobile() ? 48 : 57}
					/>
					<Text>{text}</Text>
				</motion.div>
			</motion.div>
			<Overlay />
		</Container>
	);
};

export const VerticalLine = () => (
	<svg
		fill="none"
		height="49"
		viewBox="0 0 3 49"
		width="3"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1003_14748)">
			<path
				d="M1.16016 1.15991V47.5599"
				stroke="#FFF1DF"
				strokeLinecap="round"
				strokeMiterlimit="10"
				strokeWidth="2.32"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1003_14748">
				<rect fill="white" height="48.72" width="2.32" />
			</clipPath>
		</defs>
	</svg>
);

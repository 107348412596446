import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { Text, Title } from '@home/components/atoms';

const Container = styled(motion.div)`
	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		h2 {
			font-size: 14px;
			line-height: 14px;
		}
		p {
			font-size: 10px;
			line-height: 10px;
			max-width: 75%;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export interface SectionHeaderProps {
	title: string;
	subtitle?: string;
	animate?: boolean;
}

export const SectionHeader: FC<SectionHeaderProps> = ({
	title,
	subtitle,
	animate = true,
}) => {
	return (
		<Container
			animate={
				animate
					? { opacity: 1, transition: { duration: 1, delay: 0.5 } }
					: undefined
			}
			className="pt-[25px] md:pt-[12.5px]"
			initial={animate ? { opacity: 0 } : undefined}
		>
			<Title className="text-offWhite text-center" level={2}>
				{title}
			</Title>
			{subtitle && (
				<Text className="text-red text-center px-4 md:px-0 mt-[8px] lg:mb-[12px]">
					{subtitle}
				</Text>
			)}
			<div className="flex flex-row justify-center mt-3">
				<svg
					fill="none"
					height="2"
					viewBox="0 0 169 2"
					width="169"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M89 0H169L167 2H91L89 0Z" fill="#BF3054" />
					<path d="M0 0H81L79 2H2L0 0Z" fill="#BF3054" />
				</svg>
			</div>
		</Container>
	);
};

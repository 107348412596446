import { ReactNode } from 'react';

export const Date = ({ children }: { children: ReactNode }) => (
	<div className="absolute flex items-center justify-center w-[124px] h-[23px] mt-[14px] bottom-[-39px]">
		<svg
			className="absolute inset-0 z-[-10]"
			fill="none"
			height="23"
			viewBox="0 0 124 23"
			width="124"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1040_14429)">
				<path
					d="M114.61 22.64C115.07 22.64 115.52 22.48 115.88 22.18L122.66 16.49C123.11 16.11 123.36 15.56 123.36 14.98V7.66C123.36 7.08 123.1 6.53 122.66 6.15L115.88 0.46C115.53 0.16 115.08 0 114.61 0H8.75C8.29 0 7.84 0.16 7.48 0.46L0.7 6.15C0.25 6.53 0 7.08 0 7.66V14.98C0 15.56 0.26 16.11 0.7 16.49L7.48 22.18C7.83 22.48 8.28 22.64 8.75 22.64H114.61V22.64Z"
					fill="#BF3054"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1040_14429">
					<rect fill="white" height="22.64" width="123.36" />
				</clipPath>
			</defs>
		</svg>

		{children}
	</div>
);

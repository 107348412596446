import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import tiles from '@/public/static/images/lines_tile@2x.png';
import { Breakpoint } from '@common/enums';
import { Title } from '@home/components/atoms';

const SGroup = styled.div`
	max-width: 480px;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		max-width: none;
	}
`;

const SCorner = styled.svg<{ flip?: boolean }>`
	transform: ${(props) => (props.flip ? 'scaleX(-1)' : '')};
`;

const SContentContainer = styled.div<{ alert?: boolean }>`
	padding: ${({ alert }) => (alert ? '0 32px 32px 32px' : '0 32px')};
`;

const STopRow = styled.div`
	height: 30px;
`;

const SBottomRow = styled.div`
	height: 27px;
`;

const STiles = styled.div<{ image: string }>`
	height: 26px;
	background-image: url(${(props) => props.image});
	clip-path: polygon(0 0, 100% 0, calc(100% - 32px) 100%, calc(0% + 32px) 100%);
`;

const Divider = () => (
	<svg
		fill="none"
		height="2"
		viewBox="0 0 169 2"
		width="169"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M0 0H81L79 2H2L0 0Z" fill="#BF3054" />
		<path d="M89 0H169L167 2H91L89 0Z" fill="#BF3054" />
	</svg>
);

export interface BaseModalContentContainerProps {
	title: string;
	content: ReactNode | string;
	alert?: boolean;
}

export const BaseModalContentContainer: FC<BaseModalContentContainerProps> = (
	props: BaseModalContentContainerProps,
) => {
	return (
		<SGroup className="relative">
			<STopRow className="hidden md:flex flex-row">
				<CornerTop />
				<div className="flex-1 bg-wine bg-opacity-75 border-red border-t-2 box-border md:border-opacity-50" />
				<CornerTop flip />
			</STopRow>

			<SContentContainer
				alert={props.alert}
				className="box-border md:border-opacity-50 w-full md:w-auto md:bg-wine md:bg-opacity-75 md:border-red md:border-l-2 md:border-r-2"
			>
				<Title className="text-center mb-0" level={3}>
					{props.title}
				</Title>
				<div className="w-full flex justify-center pt-3 pb-[15px]">
					<Divider />
				</div>
				<div>{props.content}</div>
			</SContentContainer>

			<div className="relative">
				<SBottomRow className="hidden md:flex flex-row">
					<CornerBottom />
					<div className="flex-1 bg-wine bg-opacity-75 border-red border-b-2 box-border md:border-opacity-50" />
					<CornerBottom flip />
				</SBottomRow>

				{props.alert && (
					<STiles
						className="hidden md:block absolute top-0 w-full bg-repeat"
						image={tiles.src}
					/>
				)}
			</div>

			<div className="hidden md:flex flex-row justify-center mt-1">
				<svg
					className=""
					fill="none"
					viewBox="0 0 343 5"
					width="89%"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0.310059 0L6.22003 5H338.31L342.41 0H0.310059Z"
						fill="#BF3054"
					/>
				</svg>
			</div>
		</SGroup>
	);
};

const CornerTop = ({ flip }: { flip?: boolean }) => {
	return (
		<SCorner
			fill="none"
			flip={flip}
			height="30"
			viewBox="0 0 39 30"
			width="39"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M36.6846 0.737618C37.3691 0.252606 38.1644 0 39 0V30H0C0 27.6861 1.13759 25.5136 3.03021 24.19L36.6846 0.737618Z"
				fill="#3B113F"
				opacity="0.75"
			/>
			<path
				d="M4.17782 25.8471C2.81876 26.7969 2.01342 28.3429 2.01342 30H0C0 27.6861 1.13759 25.5136 3.03021 24.19L36.6846 0.737618C37.3691 0.252606 38.1644 0 39 0V2.02088C38.5872 2.02088 38.1845 2.14214 37.8423 2.38464L4.17782 25.8471Z"
				fill="#BF3054"
				opacity="0.5"
			/>
		</SCorner>
	);
};

const CornerBottom = ({ flip }: { flip?: boolean }) => {
	return (
		<SCorner
			fill="none"
			flip={flip}
			height="27"
			viewBox="0 0 31 27"
			width="31"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.49356 5.32057C0.907657 4.00035 0 2.05478 0 0H31V27C29.3642 27 27.7683 26.4243 26.5116 25.372L2.49356 5.32057Z"
				fill="#3B113F"
				opacity="0.75"
			/>
			<path
				d="M27.87 23.8533C28.76 24.5978 29.83 25.0147 31 25.0147V27C29.36 27 27.84 26.4243 26.58 25.372L2.5 5.32057C0.91 4.00035 0 2.05478 0 0H2C2 1.46912 2.65998 2.85881 3.78998 3.80182L27.87 23.8533Z"
				fill="#BF3054"
				opacity="0.5"
			/>
		</SCorner>
	);
};

import { FRAME_DURATION, START_DELAY } from '@common/constants';

export const BUTTON_VARIANTS = {
	initial: {
		opacity: 0,
	},

	animate: {
		opacity: 1,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: START_DELAY + 0.4 + FRAME_DURATION,
		},
	},

	exit: {
		opacity: 1,
		transition: {
			duration: FRAME_DURATION,
			ease: 'linear',
			delay: 0,
		},
	},
};

export const IndicatorMarker = () => (
	<svg
		fill="none"
		height="16"
		viewBox="0 0 17 16"
		width="17"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1003_14744)">
			<path
				d="M8.12 2.33L12.75 6.96L8.12 11.59L3.49 6.96L8.12 2.33ZM8.12 0L0 6.96L8.12 15.08L16.24 6.96L8.12 0Z"
				fill="#FFF1DF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1003_14744">
				<rect fill="white" height="15.08" width="16.24" />
			</clipPath>
		</defs>
	</svg>
);

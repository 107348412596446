import React, { createContext } from 'react';
import styled, { css } from 'styled-components';

import { useWindowSize } from '@common/hooks';
import { Size } from '@home/types';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const WindowSizeContext = createContext<Size>(null!);

const SVG = styled.svg<Partial<FullscreenSVGProps>>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${(props) => props.zIndex};
	${(props) =>
		props.background &&
		css`
			background: ${props.background};
		`}
	will-change: transform;
	pointer-events: none;
`;

interface FullscreenSVGProps {
	readonly zIndex: number;
	background?: string;
	children: React.ReactNode;
}

// TODO: pass props to children
export const FullscreenSVG = (props: FullscreenSVGProps) => {
	const { zIndex, background, children } = props;
	const windowSize = useWindowSize();

	return (
		<SVG
			background={background}
			viewBox={`0 0 ${windowSize.width !== -1 ? windowSize.width : 0} ${
				windowSize.height !== -1 ? windowSize.height : 0
			}`}
			zIndex={zIndex}
		>
			{children}
		</SVG>
	);
};

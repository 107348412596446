import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
`;

const Mobile = styled(SVG)`
	filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.5));
`;

const Desktop = styled(SVG)`
	& .g-1 {
		opacity: 0.75;
		transition: all 0.3s ease;
	}

	& .path-1 {
		fill: #3b113f;
		opacity: 0.75;
		transition: all 0.3s ease;
	}

	& .path-2 {
		fill: #bf3054;
		transition: all 0.3s ease;
	}

	& .path-3 {
		fill: #bf3054;
		transition: all 0.3s ease;
	}

	& .path-4 {
		fill: #fff1df;
		transition: all 0.3s ease;
	}

	& .path-5 {
		fill: #fff1df;
		transition: all 0.3s ease;
	}

	&:hover {
		& .g-1 {
			opacity: 1;
		}

		& .path-1 {
			opacity: 1;
			fill: #bf3054;
		}

		& .path-2 {
			fill: #fff1df;
		}

		& .path-3 {
			fill: #fff1df;
		}

		& .path-4 {
			fill: #bf3054;
		}

		& .path-5 {
			fill: #bf3054;
		}
	}
`;

interface FAQButtonProps {
	size: 'desktop' | 'mobile';
}

export const FAQButton = ({ size }: FAQButtonProps) =>
	size === 'desktop' ? (
		<Desktop
			fill="none"
			height="38"
			viewBox="0 0 384 38"
			width="384"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g className="g-1">
				<path
					className="path-1"
					d="M382.78 11.6002L371.09 1.79022C370.48 1.28022 369.7 0.990234 368.91 0.990234H15.08C14.28 0.990234 13.51 1.27022 12.9 1.79022L1.20999 11.6002C0.439991 12.2502 0 13.2003 0 14.2003V24.0903C0 25.1003 0.439991 26.0502 1.20999 26.6902L12.9 36.5002C13.51 37.0102 14.29 37.3002 15.08 37.3002H368.91C369.71 37.3002 370.48 37.0202 371.09 36.5002L382.78 26.6902C383.55 26.0402 383.99 25.0903 383.99 24.0903V14.2003C383.99 13.1903 383.55 12.2402 382.78 11.6002Z"
				/>
			</g>
			<path
				className="path-2"
				d="M368.9 3C369.23 3 369.55 3.12002 369.8 3.33002L381.49 13.14C381.81 13.41 381.99 13.8 381.99 14.21V24.1C381.99 24.51 381.81 24.9 381.49 25.17L369.8 34.98C369.55 35.19 369.23 35.31 368.9 35.31H15.08C14.75 35.31 14.43 35.19 14.18 34.98L2.48999 25.17C2.16999 24.9 1.98999 24.51 1.98999 24.1V14.21C1.98999 13.8 2.16999 13.41 2.48999 13.14L14.18 3.33002C14.43 3.12002 14.75 3 15.08 3H368.91H368.9ZM368.91 1H15.08C14.28 1 13.51 1.27999 12.9 1.79999L1.20999 11.61C0.439991 12.26 0 13.21 0 14.21V24.1C0 25.11 0.439991 26.06 1.20999 26.7L12.9 36.51C13.51 37.02 14.29 37.31 15.08 37.31H368.91C369.71 37.31 370.48 37.03 371.09 36.51L382.78 26.7C383.55 26.05 383.99 25.1 383.99 24.1V14.21C383.99 13.2 383.55 12.25 382.78 11.61L371.09 1.79999C370.48 1.28999 369.7 1 368.91 1Z"
			/>
			<path
				className="path-3"
				d="M22.9902 32.2998C30.1699 32.2998 35.9902 26.4795 35.9902 19.2998C35.9902 12.1201 30.1699 6.2998 22.9902 6.2998C15.8105 6.2998 9.99023 12.1201 9.99023 19.2998C9.99023 26.4795 15.8105 32.2998 22.9902 32.2998Z"
			/>
			<path
				className="path-4"
				d="M22.4707 16.6996H25.5907V19.8196H27.6707V14.6196H22.4707V16.6996Z"
			/>
			<path
				className="path-5"
				d="M23.5104 21.8998H20.3904V18.7798H18.3105V23.9797H23.5104V21.8998Z"
			/>
		</Desktop>
	) : (
		<Mobile
			fill="none"
			height="40"
			viewBox="0 0 168 40"
			width="168"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M166.03 11.24L166.028 11.2385L152.823 1.52172C152.823 1.52163 152.823 1.52154 152.823 1.52145C152.363 1.18325 151.793 1 151.213 1H16.7869C16.2073 1 15.6368 1.18325 15.1775 1.52145C15.1774 1.52154 15.1772 1.52163 15.1771 1.52172L1.97214 11.2385L1.97214 11.2385L1.97005 11.24C1.3799 11.6766 1 12.3493 1 13.1037V26.8962C1 27.6506 1.37985 28.3233 1.97005 28.76L1.97214 28.7615L15.1771 38.4783C15.1772 38.4784 15.1774 38.4785 15.1775 38.4786C15.6368 38.8168 16.2073 39 16.7869 39H151.213C151.793 39 152.363 38.8168 152.823 38.4786C152.823 38.4785 152.823 38.4784 152.823 38.4783L166.028 28.7615L166.03 28.76C166.62 28.3233 167 27.6506 167 26.8962V13.1037C167 12.3493 166.62 11.6766 166.03 11.24Z"
				fill="#3B113F"
				fillOpacity="0.75"
				stroke="#BF3054"
				strokeWidth="2"
			/>
			<path
				d="M22 32C28.6274 32 34 26.6274 34 20C34 13.3726 28.6274 8 22 8C15.3726 8 10 13.3726 10 20C10 26.6274 15.3726 32 22 32Z"
				fill="#BF3054"
			/>
			<path d="M22 17H25V20H27V15H22V17Z" fill="#FFF1DF" />
			<path d="M22 23H19V20H17V25H22V23Z" fill="#FFF1DF" />
		</Mobile>
	);

import React from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
	margin: -6px;
	width: calc(100% + 12px);
	height: calc(100% + 12px);
	position: absolute;
`;

const CornerVector = styled.svg<{ rotation?: number }>`
	${({ rotation }) => (rotation ? `transform: rotate(${rotation}deg);` : '')}
`;

export const MenuBorders = () => {
	return (
		<SDiv className="flex flex-col justify-between">
			<div className="flex justify-between">
				<CornerVector
					fill="none"
					height="76"
					rotation={-90}
					viewBox="0 0 76 76"
					width="76"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M4 0L0 4H72V76L76 72V0H4Z" fill="#BF3054" />
				</CornerVector>

				<svg
					fill="none"
					height="76"
					viewBox="0 0 76 76"
					width="76"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M4 0L0 4H72V76L76 72V0H4Z" fill="#BF3054" />
				</svg>
			</div>

			<div className="flex justify-between flex-row-reverse">
				<CornerVector
					fill="none"
					height="76"
					rotation={90}
					viewBox="0 0 76 76"
					width="76"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M4 0L0 4H72V76L76 72V0H4Z" fill="#BF3054" />
				</CornerVector>

				<CornerVector
					fill="none"
					height="76"
					rotation={180}
					viewBox="0 0 76 76"
					width="76"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M4 0L0 4H72V76L76 72V0H4Z" fill="#BF3054" />
				</CornerVector>
			</div>
		</SDiv>
	);
};

import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

import fallbackImage from '@/public/static/images/radar_screen_fallback_3x.png';
import { AnimationManager } from '@common/animations';
import { Breakpoint } from '@common/enums';
import { ImageAsset } from '@home/components/atoms';

import { RadarPlanetLottie } from './components';

const SDiv = styled.div<{ zIndex: number }>`
	z-index: ${(props) => props.zIndex};
`;

const SvgContainer = styled(motion.div)`
	width: 1600px;
	margin-bottom: -460px;
	min-width: 1600px;

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		min-width: 960px;
		margin-bottom: -300px;
	}
`;

export interface RadarPlanetProps {
	visible: boolean;
	zIndex: number;
}

export const RadarPlanet: FC<RadarPlanetProps> = ({ visible, zIndex }) => {
	const gpuTier = AnimationManager.getGPUTier();

	useEffect(() => {
		if (visible) {
			AnimationManager.giveMePerformance();
			AnimationManager.startRadarAnimationLoop(1300);
		} else {
			AnimationManager.stopRadarAnimationLoop(500);
		}
	}, [visible]);

	return (
		<SDiv
			className="fixed h-full w-full inset-0 flex flex-col justify-end"
			zIndex={zIndex}
		>
			<AnimatePresence>
				{visible && (
					<div className="flex flex-row justify-center w-full h-full items-end">
						<SvgContainer
							animate={{
								opacity: 1,
								transition: {
									duration: 0.8,
									delay: 1.2,
									ease: [0.5, 1, 0.89, 1],
								},
							}}
							exit={{ opacity: 0, transition: { duration: 0.6 } }}
							initial={{ opacity: 0 }}
						>
							{gpuTier && gpuTier.tier > 2 && !gpuTier.isMobile ? (
								<RadarPlanetLottie />
							) : (
								<div className="mb-[40px] md:mb-[60px]">
									<ImageAsset
										aspectRatio="1"
										id="fallback_planet_image"
										url={fallbackImage.src}
									/>
								</div>
							)}
						</SvgContainer>
					</div>
				)}
			</AnimatePresence>
		</SDiv>
	);
};

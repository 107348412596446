import { PanInfo, motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { ChevronIcon } from '@home/components/atoms';
import { CarouselContent, Interval } from '@home/types';

import { VARIANTS } from './animations';
import { AnimateCards, Indicator } from './components';

const ImageContainer = styled(motion.div)``;

const ImagePlacer = styled(motion.div)<{ visible: Interval }>`
	width: ${({ visible }) => (visible.end - visible.start + 1) * 218}px;
	transform: translateX(-${({ visible }) => visible.start * 214}px);
	transition: all 0.5s linear;

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		width: ${({ visible }) => (visible.end - visible.start + 1) * 170}px;
		transform: translateX(-${({ visible }) => visible.start * 165}px);
	}
`;

export interface CarouselProps {
	content: CarouselContent;
	className?: string;
	minCardWidth?: number;
}

export const Carousel = ({
	content,
	className = '',
	minCardWidth = 200,
}: CarouselProps) => {
	const wSize = useWindowSize();
	const container = useRef<HTMLDivElement>(null);
	const prevButton = useRef<HTMLDivElement>(null);
	const nextButton = useRef<HTMLDivElement>(null);
	const [visible, setVisible] = useState({
		start: 0,
		end: content.pageSize - 1,
	});

	const recalcPageSize = useCallback(() => {
		if (!container.current) return;

		const containerWidth =
			container.current.clientWidth -
			64 -
			(prevButton.current?.clientWidth || 0) -
			(nextButton.current?.clientWidth || 0);

		const scaleEnd = Math.floor(containerWidth / (minCardWidth + 32));

		setVisible((v) => ({
			start: v.start,
			end:
				v.start -
				1 +
				(scaleEnd === 0
					? 1
					: scaleEnd < content.pageSize
					? scaleEnd
					: content.pageSize),
		}));
	}, [content.pageSize, minCardWidth]);

	useEffect(() => {
		recalcPageSize();
		window.addEventListener('resize', recalcPageSize);
	}, [recalcPageSize]);

	const paginate = (steps: number) => {
		setVisible((v) => ({
			start: v.start + steps,
			end: v.end + steps,
		}));
	};

	/**
	 * Experimenting with distilling swipe offset and velocity into a single variable, so the
	 * less distance a user has swiped, the more velocity they need to register as a swipe.
	 * Should accomodate longer swipes and short flicks without having binary checks on
	 * just distance thresholds and velocity > 0.
	 * https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?from-embed=&file=/src/Example.tsx
	 */
	const swipeConfidenceThreshold = 10000;
	const swipePower = (offset: number, velocity: number) => {
		return Math.abs(offset) * velocity;
	};

	const onDragEnd = (
		_: MouseEvent | TouchEvent | PointerEvent,
		{ offset, velocity }: PanInfo,
	) => {
		const swipe = swipePower(offset.x, velocity.x);

		const pageSize = visible.end - visible.start + 1;

		if (swipe < -swipeConfidenceThreshold && !isAtEnd()) {
			paginate(pageSize);
		} else if (swipe > swipeConfidenceThreshold && !isAtStart()) {
			paginate(-pageSize);
		}
	};

	const isAtStart = () => visible.start <= 0;

	const isAtEnd = () => visible.end >= content.cards.length - 1;

	const isMobile = () => wSize.width <= Breakpoint.MEDIUM;

	const classes = `w-full flex gap-x-8 justify-center items-center ${className}`;

	return (
		<div className="w-full h-full lg:h-auto flex flex-col justify-center items-center">
			<motion.div
				ref={container}
				animate={{
					opacity: 1,
					transition: {
						delay: 0.3,
					},
				}}
				className={classes}
				initial={{ opacity: 0 }}
			>
				<motion.div
					ref={prevButton}
					animate="entry"
					className="hidden md:block z-10"
					exit="hide"
					variants={VARIANTS(0)}
				>
					<ChevronIcon
						direction="left"
						disabled={isAtStart()}
						onClick={!isAtStart() ? () => paginate(-1) : undefined}
					/>
				</motion.div>
				<ImageContainer
					dragSnapToOrigin
					className="my-10 relative"
					drag={isMobile() ? 'x' : undefined}
					dragElastic={isMobile() ? 0.2 : undefined}
					onDragEnd={isMobile() ? onDragEnd : undefined}
				>
					<ImagePlacer
						className="relative flex gap-x-4 justify-start items-center"
						visible={visible}
					>
						<AnimateCards active={visible} cards={content.cards} />
					</ImagePlacer>
				</ImageContainer>
				<motion.div
					ref={nextButton}
					animate="entry"
					className="hidden md:block z-10"
					exit="hide"
					variants={VARIANTS(0)}
				>
					<ChevronIcon
						direction="right"
						disabled={isAtEnd()}
						onClick={!isAtEnd() ? () => paginate(1) : undefined}
					/>
				</motion.div>
			</motion.div>
			{content.pageIndicator && (
				<Indicator
					active={visible}
					dates={content.cards.map((item) => item.description)}
					length={content.cards.length}
				/>
			)}
		</div>
	);
};

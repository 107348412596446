import React from 'react';

interface UpButtonProps {
	onClick: () => void;
}

export const UpButton = ({ onClick }: UpButtonProps) => {
	return (
		<svg
			className="cursor-pointer"
			fill="none"
			height="32"
			viewBox="0 0 32 32"
			width="32"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<rect fill="#BF3054" height="32" rx="16" width="32" />
			<path d="M12 18L16 14L20 18" stroke="#FFF1DF" strokeWidth="2" />
		</svg>
	);
};

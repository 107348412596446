import { FC } from 'react';

import { Text } from '@home/components/atoms';
import {
	BaseModal,
	BaseModalContentContainer,
	FugitiveAlertGallery,
} from '@home/components/molecules';
import { FugitiveAlertContent } from '@home/types';

export interface FugitiveAlertModalProps {
	onClose: () => void;
	onChangeIndex?: (newIndex: number) => void;
	index: number;
	contents: FugitiveAlertContent[];
}

export const FugitiveAlertModal: FC<FugitiveAlertModalProps> = (
	props: FugitiveAlertModalProps,
) => {
	return (
		<BaseModal
			content={props.contents.map((c) => ({
				leftContent: <FugitiveAlertGallery content={c} />,
				rightContent: (
					<BaseModalContentContainer
						alert
						content={
							<Text box innerHtml>
								{c.expandedDescription.html}
							</Text>
						}
						title={c.title}
					/>
				),
			}))}
			index={props.index}
			onChangeIndex={props.onChangeIndex}
			onClose={props.onClose}
		/>
	);
};

import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';

import { ArchQuaterHudElement } from './ArchQuaterHudElement';

const QUATER_HEIGHT = 400;
const QUATER_WIDTH = 308;

const HEIGHT = QUATER_HEIGHT * 2 + 60 + 120;
const WIDTH = QUATER_WIDTH;

interface SArchHudElementAnimationContainerProps {
	width: number;
	height: number;
	scale: number;
}

const SArchHudElement = styled.div<{ position: 'left' | 'right' }>`
	justify-content: ${(props) => (props.position === 'left' ? 'start' : 'end')};
`;

const SArchHudElementAnimationContainer = styled(
	motion.div,
)<SArchHudElementAnimationContainerProps>`
	width: ${(props) => props.width}px;
	height: 100%;
	min-height: ${(props) => props.height}px;
	margin-left: -34px;
	margin-right: -34px;
	padding-top: ${({ scale }) => 60 * scale}px;
	padding-bottom: ${({ scale }) => 120 * scale}px;
`;

export interface ArchHudElementProps {
	id: string[];
	filename: string;
	position: 'left' | 'right';
	open: boolean;
}

const animationVariants = {
	initialLeft: { x: 300 },
	initialRight: { x: -300 },
	animate: {
		x: 0,
		transition: { delay: 0.48, duration: 0.64, ease: 'easeOut' },
	},
};

export const ArchHudElement: FC<ArchHudElementProps> = (
	props: ArchHudElementProps,
) => {
	const wSize = useWindowSize();
	const SCALE = wSize.hudScale;

	if (wSize.width < Breakpoint.LARGE) return null;

	return (
		<SArchHudElement
			className="h-full flex items-center w-full inset-0 absolute"
			position={props.position}
		>
			<SArchHudElementAnimationContainer
				animate={props.open ? 'animate' : ''}
				className="flex flex-col justify-between h-full"
				height={HEIGHT * SCALE}
				initial={props.position === 'left' ? 'initialLeft' : 'initialRight'}
				scale={SCALE}
				variants={animationVariants}
				width={WIDTH * SCALE}
			>
				<ArchQuaterHudElement
					filename={props.filename}
					flipX={props.position === 'right'}
					height={QUATER_HEIGHT * SCALE}
					id={props.id[0]}
					width={QUATER_WIDTH * SCALE}
				/>

				<ArchQuaterHudElement
					flipY
					filename={props.filename}
					flipX={props.position === 'right'}
					height={QUATER_HEIGHT * SCALE}
					id={props.id[1]}
					width={QUATER_WIDTH * SCALE}
				/>
			</SArchHudElementAnimationContainer>
		</SArchHudElement>
	);
};

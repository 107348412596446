import { motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useSettings, useWindowSize } from '@common/hooks';

const transition = { duration: 0.8, ease: 'easeInOut' };

const GridSVGgroup = styled.g`
	opacity: 0.25;
`;

interface HudGridLinesSettings {
	strokeWidth: number;
	gridCellSize: { x: number; y: number };
	curvatureFactor: { x: number; y: number };
}
const SETTINGS: HudGridLinesSettings = {
	gridCellSize: { x: 200, y: 100 },
	curvatureFactor: { x: 0.2, y: 0.6 },
	strokeWidth: 2,
};

export interface HudGridLinesAnimationSettings {
	baseDelay: number;
	duration: number;
	lineStaggerDelay: number;
	verticalLinesDelay: number;
}
const ANIMATION_SETTINGS: HudGridLinesAnimationSettings = {
	baseDelay: 0.7,
	duration: 0.6,
	lineStaggerDelay: 0.03,
	verticalLinesDelay: 0.1,
};

interface HudGridLinesProps {
	readonly color: string;
	animation?: HudGridLinesAnimationSettings;
}

export const HudGridLines = ({ color, animation }: HudGridLinesProps) => {
	const { width, height } = useWindowSize();

	const { gridCellSize, strokeWidth, curvatureFactor } = useSettings(
		'HudGridLines',
		SETTINGS,
	) as HudGridLinesSettings;

	const animationProps = useMemo(() => {
		return Object.assign({}, ANIMATION_SETTINGS, animation);
	}, [animation]);

	/**
	 * Curvature factor (in pixels) for each axis
	 * The value is used as an offset from the straight line (e.g. ), and is inserted into the control point for the quadratic bezier curve command
	 * https://vanseodesign.com/web-design/svg-paths-curve-commands/
	 */
	const curvature = useMemo(() => {
		return {
			x: width * curvatureFactor.x,
			y: height * curvatureFactor.y,
		};
	}, [curvatureFactor, width, height]);

	/**
	 * Number of lines for each axis
	 * [NOTE] x axis represents vertical lines, y axis represents horizontal lines
	 */
	const numOfLines = useMemo(() => {
		let numLinesWidth = Math.ceil(width / gridCellSize.x);
		numLinesWidth = numLinesWidth % 2 === 0 ? numLinesWidth + 1 : numLinesWidth; // make sure it's odd, since we're anchoring the lines to the center of the screen
		numLinesWidth += 4; // add some extra lines to make sure the lines are visible

		let numLinesHeight = Math.ceil(height / gridCellSize.y);
		numLinesHeight =
			numLinesHeight % 2 === 0 ? numLinesHeight + 1 : numLinesHeight;
		numLinesHeight += 4;

		return { x: numLinesWidth, y: numLinesHeight };
	}, [width, gridCellSize, height]);

	/**
	 * Length of line spaces = distance in pixels from the first line to the last line
	 */
	const lineSpaceLength = useMemo(() => {
		return {
			x: (numOfLines.x - 1) * gridCellSize.x,
			y: (numOfLines.y - 1) * gridCellSize.y,
		};
	}, [numOfLines, gridCellSize]);

	/**
	 * Get curvature offset based on the line index and axis
	 * The offset is used in the control point for the quadratic bezier curve command
	 * Close to the center of the screen, the offset is 0
	 * Close to the edge of the screen, the offset is the curvature value
	 */
	const getCurvatureOffset = useCallback(
		(lineIndex = 0, axis: 'x' | 'y') => {
			const indexDistFromCenter =
				lineIndex - Math.floor(numOfLines[axis] * 0.5);
			const curveControlPointOffsetFromLine =
				(Math.abs(indexDistFromCenter) / Math.floor(numOfLines[axis] * 0.5)) *
				curvature[axis] *
				-Math.sign(indexDistFromCenter) *
				0.5;

			return curveControlPointOffsetFromLine;
		},
		[numOfLines, curvature],
	);

	return (
		<GridSVGgroup>
			{Array.from({ length: numOfLines.x }).map((_, i) => {
				const x = -lineSpaceLength.x * 0.5 + i * gridCellSize.x + width * 0.5;
				const t = {
					...transition,
					delay:
						animationProps.baseDelay +
						animationProps.verticalLinesDelay +
						i * animationProps.lineStaggerDelay,
					duration: animationProps.duration,
				};

				return (
					<motion.path
						key={i}
						animate={{ pathLength: 1 }}
						d={`
              M${x},0 
              Q${x + getCurvatureOffset(i, 'x')},${height * 0.5} 
              ${x},${height}
            `}
						fill="none"
						initial={{ pathLength: 0 }}
						stroke={color}
						strokeWidth={strokeWidth}
						transition={t}
					/>
				);
			})}

			{Array.from({ length: numOfLines.y }).map((_, i) => {
				const y = -lineSpaceLength.y * 0.5 + i * gridCellSize.y + height * 0.5;
				const t = {
					...transition,
					delay: animationProps.baseDelay + i * animationProps.lineStaggerDelay,
					duration: animationProps.duration * 1.3,
				};

				return (
					<motion.path
						key={i}
						animate={{ pathLength: 1 }}
						d={`
              M0,${y} 
              Q${width * 0.5},${y + getCurvatureOffset(i, 'y')} 
              ${width},${y}
            `}
						fill="none"
						initial={{ pathLength: 0 }}
						stroke={color}
						strokeWidth={strokeWidth}
						transition={t}
					/>
				);
			})}
		</GridSVGgroup>
	);
};

// TODO: replace with theme colors
HudGridLines.defaultProps = {
	zIndex: 1,
	color: '#bf3054',
};

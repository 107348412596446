import React from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';

const Background = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	@media (max-height: ${Breakpoint.SMALL_PHONE_HEIGHT}px) {
		width: 150px;
		height: 173px;
	}
`;

export const RoadmapBackground = () => (
	<Background
		fill="none"
		height="229"
		viewBox="0 0 198 229"
		width="198"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M51.7498 8.10938C50.9198 8.10938 50.1298 8.35937 49.4498 8.83937L16.0198 32.0494C14.1398 33.3594 13.0098 35.5094 13.0098 37.7994V197.799C13.0098 199.869 13.9198 201.829 15.5098 203.159L39.5898 223.359C40.8498 224.419 42.4498 224.999 44.0898 224.999H180.03L194.01 208.219V13.9994L187.42 8.10938H51.7498Z"
			fill="#3B113F"
		/>
		<path
			d="M51.7498 8.10938C50.9198 8.10938 50.1298 8.35937 49.4498 8.83937L16.0198 32.0494C14.1398 33.3594 13.0098 35.5094 13.0098 37.7994V197.799C13.0098 199.869 13.9198 201.829 15.5098 203.159L39.5898 223.359C40.8498 224.419 42.4498 224.999 44.0898 224.999H180.03L194.01 208.219V13.9994L187.42 8.10938H51.7498Z"
			fill="#3B113F"
		/>
	</Background>
);

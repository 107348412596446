import React from 'react';
import styled from 'styled-components';

type Direction = 'right' | 'left' | 'up' | 'down';

const directionInDeg = (direction: Direction) =>
	direction === 'right'
		? '0deg'
		: direction === 'left'
		? '180deg'
		: direction === 'down'
		? '90deg'
		: '-90deg';

const Icon = styled.svg<{ direction: Direction; $disabled?: boolean }>`
	transition: all 0.3s ease;
	transform: rotate(${({ direction }) => directionInDeg(direction)});
	${({ $disabled, direction }) =>
		!$disabled
			? `
    :hover {
      transform: scale(1.1)
        rotate(${directionInDeg(direction)});
    }
  `
			: ''}
`;

interface ChevronIconProps {
	direction?: Direction;
	height?: number;
	width?: number;
	color?: string;
	onClick?: () => void;
	disabled?: boolean;
}

export const ChevronIcon = ({
	direction = 'right',
	width = 20,
	height = 40,
	color = '#FFF1DF',
	onClick,
	disabled,
}: ChevronIconProps) => {
	const _onClick = () => {
		if (onClick && !disabled) onClick();
	};

	return (
		<Icon
			$disabled={disabled}
			className={`
        ${onClick && !disabled ? 'cursor-pointer' : ''}
      `}
			direction={direction}
			fill="none"
			height={height}
			viewBox="0 0 24 48"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			onClick={_onClick}
		>
			<path
				d="M7.71832 0H0L16.7746 24L0 48H7.71832L24 24.9035V24V23.0965L7.71832 0Z"
				fill={color}
				opacity={disabled ? 0.5 : 1}
			/>
		</Icon>
	);
};

import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint, HudAnimation } from '@common/enums';
import { useWindowSize } from '@common/hooks';
import { FooterContent } from '@home/components/molecules';
import { FooterSectionContent } from '@home/sections';

import { HudContent } from '../Hud';

import { DiscordHudElement, SpeedMeterHudElement } from '.';

export interface HudBottomRowProps {
	animations: { [key: string]: string };
	content: HudContent;
	footer: FooterSectionContent;
}

const SHudBottomRowCenter = styled.div``;

const SHudBottomRow = styled.div<{ scale: number }>`
	height: ${({ scale }) => 180 * scale}px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		height: auto;
	}
`;

export const HudBottomRow: FC<HudBottomRowProps> = (props) => {
	const wSize = useWindowSize();

	return (
		<SHudBottomRowCenter className="absolute bottom-0 w-full flex flex-row justify-center">
			<SHudBottomRow
				className="flex flex-row justify-between relative"
				scale={wSize.hudScale}
			>
				{wSize.width > Breakpoint.LARGE && (
					<SpeedMeterHudElement
						filename={props.animations.speedmeter}
						id={HudAnimation.SPEED_METER_LEFT}
					/>
				)}
				<DiscordHudElement
					cta={props.content.bottomCta}
					filename={props.animations.discord}
					id={HudAnimation.DISCORD}
				/>
				{wSize.width > Breakpoint.LARGE && (
					<SpeedMeterHudElement
						flipX
						filename={props.animations.speedmeter}
						id={HudAnimation.SPEED_METER_RIGHT}
					/>
				)}

				<div className="absolute bottom-0 w-full hidden lg:flex flex-col items-center pointer-events-auto">
					<FooterContent hud content={props.footer} />
				</div>
			</SHudBottomRow>
		</SHudBottomRowCenter>
	);
};

export const isWebglSupported = (function () {
	try {
		return (
			!!window.WebGLRenderingContext &&
			(!!document.createElement('canvas').getContext('experimental-webgl') ||
				!!document.createElement('canvas').getContext('webgl'))
		);
	} catch (e) {
		return false;
	}
})();

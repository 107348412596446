import { FC } from 'react';

export const GameOverviewFeatureFrameBottomRight: FC = () => {
	return (
		<svg
			fill="none"
			preserveAspectRatio="xMidYMid"
			viewBox="0 0 413 157"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M45.2998 142L52.4498 147H401L406.83 142H45.2998Z"
				fill="#FFF1DF"
			/>
			<path
				d="M394.13 6L407.05 21.5V141.21L401.69 146H52.74C52.33 146 51.93 145.87 51.58 145.63L18.15 122.42C16.8 121.49 16 119.95 16 118.31V31.2C16 29.72 16.65 28.32 17.79 27.37L41.87 7.17001C42.77 6.42001 43.91 6 45.08 6H394.13V6ZM395.07 4H45.08C43.44 4 41.84 4.58001 40.58 5.64001L16.5 25.84C14.91 27.17 14 29.13 14 31.2V118.31C14 120.6 15.12 122.75 17.01 124.06L50.44 147.27C51.12 147.75 51.91 148 52.74 148H402.46L409.05 142.11V20.78L395.07 4V4Z"
				fill="#FFF1DF"
			/>
			<path
				d="M397.12 1.07001C396.55 0.390007 395.71 0 394.82 0H32.8705C30.7505 0 28.7005 0.749985 27.0805 2.10999L5.27051 20.41C2.55051 22.69 0.980469 26.06 0.980469 29.6V119.76C0.980469 123.68 2.89046 127.34 6.10046 129.59L41.5005 154.38C43.0105 155.44 44.8205 156.01 46.6605 156.01H399.521L412.98 144.01V20.01L397.1 1.07999L397.12 1.07001ZM411.041 143L403.21 150H52.7305C51.5005 150 50.3005 149.62 49.2905 148.92L15.8605 125.71C13.4405 124.03 11.9905 121.27 11.9905 118.32V31.21C11.9905 28.55 13.1705 26.03 15.2005 24.32L39.2805 4.12C40.9005 2.76 42.9505 2.01001 45.0705 2.01001H395.99L410.99 20.01H411.041V143.01V143Z"
				fill="url(#paint0_linear_188_3812)"
			/>
			<path
				d="M55.5801 19.77V119.54C55.5801 119.96 55.1301 120.31 54.5801 120.31C54.0301 120.31 53.5801 119.97 53.5801 119.54V19.77C53.5801 19.35 54.0301 19 54.5801 19C55.1301 19 55.5801 19.34 55.5801 19.77Z"
				fill="url(#paint1_linear_188_3812)"
			/>
			<path
				d="M50.83 124.42L54.58 120.67L58.33 124.42L54.58 128.17L50.83 124.42ZM48 124.42L54.58 131L61.16 124.42L54.58 117.84L48 124.42Z"
				fill="#FFF1DF"
			/>
			<path
				d="M372.5 136C379.404 136 385 130.404 385 123.5C385 116.596 379.404 111 372.5 111C365.596 111 360 116.596 360 123.5C360 130.404 365.596 136 372.5 136Z"
				fill="#BF3054"
			/>
			<path d="M372 121H375V124H377V119H372V121Z" fill="#FFF1DF" />
			<path d="M373 126H370V123H368V128H373V126Z" fill="#FFF1DF" />
			<defs>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint0_linear_188_3812"
					x1="1.00047"
					x2="413"
					y1="78"
					y2="78"
				>
					<stop stopColor="#BF3054" />
					<stop offset="1" stopColor="#741F7A" />
				</linearGradient>
				<linearGradient
					gradientUnits="userSpaceOnUse"
					id="paint1_linear_188_3812"
					x1="54.5801"
					x2="54.5801"
					y1="120.31"
					y2="19"
				>
					<stop stopColor="#C935D4" />
					<stop offset="1" stopColor="#9928A1" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

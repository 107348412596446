import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ContentSection, HudBreakpoint, zIndex } from '@common/enums';
import { useMenu, useWindowSize } from '@common/hooks';
import { MenuIcon, NavbarIcon, Text } from '@home/components/atoms';

const MobileNavbar = styled(motion.div)<{ zindex: zIndex }>`
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: ${({ zindex }) => zindex};
`;

const DesktopNavbar = styled(motion.nav)<{
	zindex: zIndex;
	scale: number;
	length: number;
}>`
	position: absolute;
	bottom: ${({ scale, length }) =>
		48 * scale + 42 * scale * (7 - length) + 8 * (7 - length)}px;
	right: 0;
	z-index: ${({ zindex }) => zindex};
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;

	@media (max-height: ${HudBreakpoint.Medium}px) {
		bottom: ${({ scale, length }) =>
			48 * scale - 7 + 42 * scale * (7 - length) + 8 * (7 - length)}px;
	}

	@media (max-height: ${HudBreakpoint.Small}px) {
		bottom: ${({ scale, length }) =>
			48 * scale - 20 + 42 * scale * (7 - length) + 8 * (7 - length)}px;
	}

	@media (min-height: ${HudBreakpoint.Large}px) {
		bottom: ${({ scale, length }) =>
			60 * scale + 42 * scale * (7 - length) + 8 * (7 - length)}px;
	}
`;

const DesktopNavbarItem = styled(motion.a)<{ marginRight?: number }>`
	${({ marginRight }) => (marginRight ? `margin-right: ${marginRight}px;` : '')}
	padding: 4px 0;
	column-gap: 15px;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	transition: all 0.3s ease;
	font-size: 12px;
`;

export interface NavBarItem {
	label: string;
	key: ContentSection;
}

export interface NavBarProps {
	items: NavBarItem[];
	onChange?: (section: ContentSection) => void;
	zindex: zIndex;
}

const containerFramerVariants = {
	hidden: { opacity: 1 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.08,
			delayChildren: 4,
		},
	},
};

const itemFramerVariants = {
	hidden: { opacity: 0, y: 8, x: 18, rotate: 8 },
	show: {
		opacity: 1,
		y: 0,
		x: 0,
		rotate: 0,
	},
};

export const NavBar: FC<NavBarProps> = (props: NavBarProps) => {
	const { onChange } = props;
	const [menu] = useMenu();
	const { hudScale } = useWindowSize();

	const [hover, setHover] = useState<NavBarItem>();

	useEffect(() => {
		menu.setItems(props.items);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.items]);

	const _onClick = (key: ContentSection) => {
		if (!onChange) return;
		onChange(key);
	};

	const onMobileMenuClick = () => {
		menu.open();
	};

	const isActive = (section: ContentSection) => menu.active() === section;
	const isHovering = (item: NavBarItem) => hover?.key === item.key;
	const isHome = (section: ContentSection) =>
		isActive(section) && section === ContentSection.Intro;

	const baseClasses = 'text-offWhite';
	const activeClasses = ``;
	const inActiveClasses = `opacity-50 cursor-pointer`;

	const getMargin = (index: number) => {
		switch (index) {
			case 0:
			default:
				return 0;
			case 1:
				return 8 * hudScale;
			case 2:
				return 20 * hudScale;
			case 3:
				return 36 * hudScale;
			case 4:
				return 52 * hudScale;
			case 5:
				return 76 * hudScale;
			case 6:
				return 100 * hudScale;
		}
	};

	return (
		<>
			<MobileNavbar className="block lg:hidden" zindex={props.zindex}>
				<MenuIcon onClick={onMobileMenuClick} />
			</MobileNavbar>
			<AnimatePresence>
				<DesktopNavbar
					animate="show"
					className="hidden lg:flex"
					initial="hidden"
					length={props.items.length}
					scale={hudScale}
					variants={containerFramerVariants}
					zindex={props.zindex}
				>
					{props.items.map((item, index) => (
						<DesktopNavbarItem
							key={item.key}
							className={`${baseClasses} ${
								isActive(item.key) ? activeClasses : inActiveClasses
							}`}
							marginRight={getMargin(index)}
							variants={itemFramerVariants}
							onClick={() => {
								_onClick(item.key);
							}}
							onHoverEnd={() => setHover(undefined)}
							onHoverStart={() => setHover(item)}
						>
							{(isHovering(item) || isHome(item.key)) && (
								<Text small>{item.label}</Text>
							)}
							<NavbarIcon
								active={isActive(item.key)}
								height={42 * hudScale}
								hover={isHovering(item)}
								section={item.key}
								width={42 * hudScale}
							/>
						</DesktopNavbarItem>
					))}
				</DesktopNavbar>
			</AnimatePresence>
		</>
	);
};

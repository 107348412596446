import '@common/styles/globals.css';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { FC, useEffect, useState } from 'react';

import { MenuProvider, ModalProvider } from '@common/contexts';
import { ContentSection } from '@common/enums';
import { useIsSSR } from '@common/hooks';
import { Meta } from '@home/components/molecules';

const MyApp: FC<AppProps> = ({ Component, pageProps }: AppProps) => {
	const isSsr = useIsSSR();
	const [hash, setHash] = useState(ContentSection.Intro);

	useEffect(() => {
		//only run in the browser but don't escape and return null b/c this breaks
		//static rendering of metadata tags
		if (!isSsr) {
			const currentHash = window.location.hash.replace('#', '') as ContentSection;
			const isValid = Object.values(ContentSection).includes(currentHash);

			if (isValid) {
				setHash(currentHash as ContentSection);
			}
		}
	}, [isSsr]);

	return (
		<>
			<Meta></Meta>
			<ModalProvider>
				<MenuProvider initialSection={hash}>
					<Component {...pageProps} />
				</MenuProvider>
			</ModalProvider>
		</>
	);
};

export default appWithTranslation(MyApp);

import create from 'zustand';

import { Breakpoint, HudBreakpoint } from '@common/enums';
import { debounce } from '@common/utils';
import { Size } from '@home/types';

const useWindowSize = create<Size>((set) => {
	const getScale = () => {
		if (window.innerWidth <= Breakpoint.LARGE) return 1;

		const aspect = window.innerWidth / window.innerHeight;

		if (aspect > 1 && window.innerHeight <= HudBreakpoint.Small) {
			return 0.7;
		}

		if (aspect > 1 && window.innerHeight <= HudBreakpoint.Medium) {
			return 0.78;
		}

		if (aspect > 1 && window.innerHeight <= HudBreakpoint.MediumLarge) {
			return 0.95;
		}

		if (aspect > 1 && window.innerHeight >= HudBreakpoint.Large) {
			return 1.2;
		}

		return 1;
	};

	const updateSize = debounce(function () {
		set({
			width: window.innerWidth,
			height: window.innerHeight,
			aspectRatio: window.innerWidth / window.innerHeight,
			hudScale: getScale(),
		});
	}, 100);

	if (typeof window !== 'undefined') {
		updateSize();
		window.addEventListener('resize', updateSize);
	}

	return {
		width: -1,
		height: -1,
		aspectRatio: 1,
		hudScale: 1,
	};
});

export { useWindowSize };

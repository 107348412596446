import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { ButtonVariant } from '@common/enums';

const SButton = styled.div``;

export interface ButtonProps {
	children: ReactNode;
	variant?: ButtonVariant;
	onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
	children,
	variant = ButtonVariant.Primary,
	onClick,
}: ButtonProps) => {
	const classes = `btn btn-${variant}`;

	return (
		<SButton className={classes} onClick={onClick}>
			{children}
		</SButton>
	);
};

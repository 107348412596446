/* eslint-disable @typescript-eslint/no-unused-vars */
// https://gist.github.com/pbeshai/72c446033a98f99ce1e1371c6eee9644
import { animate, useMotionValue } from 'framer-motion';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { lerp } from 'three/src/math/MathUtils';

import { useWindowSize } from '@common/hooks';
import {
	QuadraticCurve,
	getUUID,
	interpolateQuadraticBezierAngle,
	quadraticPath,
} from '@common/utils';

import type { RadarShipProps } from './RadarShip.types';

export const RadarShip = (props: RadarShipProps) => {
	const {
		id = getUUID(),
		startPosition,
		endPosition,
		curveOffset,
		shipWidth,
		labelOffset,
		duration,
		delay,
	} = props;

	const { width, height } = useWindowSize();
	const [rendered, setRendered] = useState(false);
	const [label, setLabel] = useState(props.idLabel);

	const pathRef = useRef<SVGPathElement>(null);
	const maskRef = useRef<SVGRectElement>(null);
	const shipContainerRef = useRef<SVGGElement>(null);
	const shipIconRef = useRef<SVGPolygonElement>(null);

	const quadraticBezierCurveParams = useMemo(() => {
		return {
			start: startPosition,
			end: endPosition,
			control: [
				lerp(startPosition[0], endPosition[0], 0.5) + curveOffset,
				lerp(startPosition[1], endPosition[1], 0.5),
			],
		} as QuadraticCurve;
	}, [startPosition, endPosition, curveOffset]);

	const quadraticBezierCurveAngleInterpolator = useMemo(
		() =>
			interpolateQuadraticBezierAngle(
				quadraticBezierCurveParams.start,
				quadraticBezierCurveParams.control,
				quadraticBezierCurveParams.end,
			),
		[quadraticBezierCurveParams],
	);

	const lookAtAngle = useMemo(
		() => quadraticBezierCurveAngleInterpolator(1),
		[quadraticBezierCurveAngleInterpolator],
	);

	/*
	 * Path length
	 */
	const length = useMemo(() => {
		if (pathRef.current) {
			return pathRef.current.getTotalLength();
		} else {
			return 0;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width, height, rendered]);

	useEffect(() => {
		setRendered(true);
	}, []);

	/*
	 * Path animation
	 */
	const animationValue = useMotionValue(0);

	const maskWidth = useMemo(() => {
		return Math.max(Math.abs(curveOffset * 1.2), 10);
	}, [curveOffset]);

	const spawnAnimation = useCallback(() => {
		animationValue.set(0);
		const maskRotation = Math.round(quadraticBezierCurveAngleInterpolator(0.5));

		const controls = animate(animationValue, 1, {
			duration,
			delay,
			ease: 'easeOut',
			onUpdate: (v) => {
				if (pathRef.current) {
					pathRef.current.setAttribute(
						'stroke-dashoffset',
						`${Math.round(length * (1 - v))}`,
					);
					// maskRef.current.setAttribute(
					//   'transform',
					//   `
					//       rotate(${maskRotation})
					//       translate(0 -${maskWidth * 0.5})
					//       scale(${v} 1)
					//     `,
					// )
				}

				if (shipContainerRef.current && pathRef.current) {
					const point = pathRef.current.getPointAtLength(length * v);
					shipContainerRef.current.setAttribute(
						'transform',
						`translate(${point.x}, ${point.y})`,
					);
				}
			},
		});

		return controls;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [length, curveOffset]);

	/*
	 * Spawn animation
	 */
	useEffect(() => {
		const animation = spawnAnimation();

		return () => {
			animation.stop();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spawnAnimation]);

	return (
		<>
			<defs>
				<style>
					{`
            .radar-ship-label {
              fill: #BF3054;
              font-family: Prohibition-Regular, Prohibition;
              font-size: 13px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
            }

            .transform-fix {
              transform-box: fill-box;
            }
          `}
				</style>
				<mask id={`path_mask_${id}`}>
					<path
						ref={pathRef}
						d={quadraticPath(quadraticBezierCurveParams)}
						fill="none"
						stroke="#fff"
						strokeDasharray={length}
						strokeDashoffset={length}
						strokeWidth={2}
					/>
				</mask>
			</defs>

			{/* Ship path */}
			<path
				key={props.id}
				d={quadraticPath(quadraticBezierCurveParams)}
				fill="none"
				mask={`url(#path_mask_${id})`}
				opacity={0.5}
				stroke="#BF3054"
				strokeDasharray="35,10"
				strokeWidth={1}
			/>

			{/* Ship container */}
			<g
				ref={shipContainerRef}
				className="pointer-events-auto cursor-pointer"
				style={{ transform: 'translate(0, -20)' }}
				onMouseOut={() => {
					setLabel(props.idLabel);
				}}
				onMouseOver={() => {
					setLabel(props.label);
				}}
			>
				{/* Ship icon */}

				<polygon
					ref={shipIconRef}
					fill="none"
					points={`
            ${-shipWidth / 2},${0} 
            ${shipWidth / 2},${0}  
            ${0},${shipWidth}  
          `}
					stroke="#BF3054"
					strokeWidth={2}
					style={{
						transformBox: 'fill-box',
						transformOrigin: 'top center',
						transform: `rotate(${lookAtAngle - 90}deg)`,
					}}
				/>

				{/* Shiplabel */}
				<text
					className="radar-ship-label"
					x={labelOffset[0]}
					y={labelOffset[1]}
				>
					{label}
				</text>
			</g>
		</>
	);
};

RadarShip.defaultProps = {
	startPosition: [0, 0],
	endPosition: [50, 50],
	curveOffset: 0,
	labelOffset: [14, 8],
	shipWidth: 10,
	label: '',
	delay: 0,
	duration: 2,
};

import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { ExternalAsset } from '@home/types';

const SWrapper = styled.div`
	width: 400px;
	height: 640px;

	@media (max-width: ${Breakpoint.LARGE}px) {
		width: 240px;
		height: 384px;
	}

	@media (max-width: ${Breakpoint.MEDIUM}px) {
		width: 100px;
		height: 110px;
	}

	@media (max-width: ${Breakpoint.SMALL}px) {
		width: 90%;
		margin-left: 5%;
		height: auto;
		aspect-ratio: 1;
	}
`;

const SContainer = styled.div``;

const SImageContainer = styled.div<{ image: string; clipPath: string }>`
	background-image: url(${(props) => props.image});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top left;
	clip-path: ${(props) => props.clipPath};
	aspect-ratio: 1;
	top: 3px;
	left: 3px;
	height: 99%;
`;

const SMobileImageContainer = styled.div<{ image: string; clipPath: string }>`
	background-image: url(${(props) => props.image});
	clip-path: ${(props) => props.clipPath};
	aspect-ratio: 1;
	width: 99%;
	margin-left: 2px;
	margin-top: 2px;
`;

export interface BaseModalGalleryProps {
	image: ExternalAsset;
	mobileImage?: ExternalAsset;
}

export const BaseModalGallery: FC<BaseModalGalleryProps> = (
	props: BaseModalGalleryProps,
) => {
	return (
		<SWrapper className="mt-4 md:mt-0 md:ml-auto md:mr-12 relative">
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_IMAGE_LARGE"
					>
						<path d="M0.551781 2.09808e-05C0.552091 2.09808e-05 0.552371 0.000104983 0.552641 0.000294983L0.617651 0.045435C0.619611 0.046805 0.620791 0.049039 0.620791 0.05142V0.953425C0.620791 0.955575 0.619851 0.957625 0.618181 0.959015L0.571361 0.998295C0.570051 0.999385 0.568381 0.999995 0.566681 0.999995H0.054097C0.05239 0.999995 0.050725 0.999385 0.049418 0.998295L0.00259197 0.959015C0.000947968 0.957625 0 0.955595 0 0.953425V0.05142C0 0.049039 0.00117997 0.046784 0.00313997 0.045435L0.068174 0.000274003C0.068427 8.40025e-05 0.068701 0 0.069017 0H0.551821L0.551781 2.09808e-05Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_IMAGE_SMALL"
					>
						<path d="M0.888828 0C0.889338 0 0.889778 0.000135998 0.890218 0.000441998L0.994938 0.073226C0.998098 0.075435 0.999998 0.079037 0.999998 0.082877V0.669013C0.999998 0.672479 0.998468 0.675775 0.995788 0.678017L0.920368 0.741354C0.918258 0.743124 0.915578 0.744104 0.912828 0.744104H0.087172C0.084422 0.744104 0.081741 0.743124 0.079636 0.741354L0.00420901 0.678017C0.00156101 0.675775 0 0.672513 0 0.669013V0.082877C0 0.079037 0.00190102 0.075401 0.00505802 0.073226L0.10978 0.000441998C0.110187 0.000135998 0.110628 0 0.111137 0H0.888828Z" />
					</clipPath>
				</defs>
			</svg>

			{/* MOBILE */}
			<SContainer className="md:hidden h-full aspect-square relative">
				<SMobileImageContainer
					className="bg-cover bg-center absolute"
					clipPath="url(#CLIP_PATH_IMAGE_SMALL)"
					image={props.mobileImage ? props.mobileImage.url : props.image.url}
				/>

				<div className="absolute w-full">
					<svg
						fill="none"
						height="100%"
						viewBox="0 0 327 245"
						width="100%"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M288.109 3.26686C288.272 3.26686 288.414 3.31042 288.555 3.40843L322.116 26.7338C323.127 27.4417 323.736 28.5959 323.736 29.8265V217.667C323.736 218.778 323.247 219.834 322.387 220.553L298.215 240.851C297.541 241.417 296.681 241.733 295.8 241.733H31.1998C30.3187 241.733 29.4593 241.417 28.7848 240.851L4.61253 220.553C3.764 219.834 3.26358 218.789 3.26358 217.667V29.8265C3.26358 28.5959 3.87278 27.4308 4.88449 26.7338L38.445 3.40843C38.5755 3.31042 38.717 3.26686 38.8801 3.26686H288.109ZM288.109 0H38.8801C38.0534 0 37.2592 0.25046 36.5739 0.7296L3.01337 24.055C1.1205 25.3726 0 27.5288 0 29.8374V217.667C0 219.747 0.913803 221.718 2.51296 223.058L26.6852 243.356C27.9471 244.423 29.5572 245 31.1998 245H295.8C297.454 245 299.053 244.412 300.315 243.356L324.487 223.058C326.086 221.718 327 219.758 327 217.667V29.8265C327 27.5179 325.869 25.3618 323.987 24.0441L290.415 0.7296C289.73 0.25046 288.936 0 288.109 0Z"
							fill="#BF3054"
						/>
					</svg>
				</div>
			</SContainer>

			{/* IMAGE, SCREENS BIGGER THAN MOBILE */}
			<SContainer className="hidden md:block w-full h-full">
				<SImageContainer
					className="absolute"
					clipPath="url(#CLIP_PATH_IMAGE_LARGE)"
					image={props.image.url}
				/>

				<div className="absolute w-full h-full">
					<svg
						fill="none"
						height="100%"
						viewBox="0 0 302 481"
						width="100%"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M265.37 3.39999C265.52 3.39999 265.65 3.43999 265.78 3.52999L296.63 24.95C297.56 25.6 298.12 26.66 298.12 27.79V455.81C298.12 456.83 297.67 457.8 296.88 458.46L274.66 477.1C274.04 477.62 273.25 477.91 272.44 477.91H29.2101C28.4001 477.91 27.6101 477.62 26.9901 477.1L4.77005 458.46C3.99005 457.8 3.54007 456.84 3.54007 455.81V27.79C3.54007 26.66 4.10006 25.59 5.03006 24.95L35.8901 3.51999C36.0101 3.42999 36.1401 3.38999 36.2901 3.38999H265.39L265.37 3.39999ZM265.39 0.389984H36.2901C35.5301 0.389984 34.8001 0.619983 34.1701 1.05999L3.32007 22.48C1.58007 23.69 0.550049 25.67 0.550049 27.79V455.81C0.550049 457.72 1.39005 459.53 2.86005 460.76L25.08 479.4C26.24 480.38 27.7201 480.91 29.2301 480.91H272.46C273.98 480.91 275.45 480.37 276.61 479.4L298.83 460.76C300.3 459.53 301.14 457.73 301.14 455.81V27.79C301.14 25.67 300.1 23.69 298.37 22.48L267.52 1.05999C266.89 0.619983 266.16 0.389984 265.4 0.389984H265.39Z"
							fill="#BF3054"
						/>
					</svg>
				</div>
			</SContainer>
		</SWrapper>
	);
};

import React from 'react';

interface PlayButtonProps {
	height?: number;
	width?: number;
}

export const PlayButton = ({ height = 48, width = 48 }: PlayButtonProps) => (
	<svg
		fill="none"
		height={height}
		viewBox="0 0 48 48"
		width={width}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24 47C36.7026 47 47 36.7026 47 24C47 11.2975 36.7026 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7026 11.2975 47 24 47Z"
			fill="#230B26"
			opacity="0.75"
		/>
		<path
			d="M19 24V29L26 24L19 19"
			stroke="#FFF1DF"
			strokeLinecap="round"
			strokeMiterlimit="10"
			strokeWidth="2"
		/>
		<path
			d="M19 34L33 24L19 14"
			stroke="#FFF1DF"
			strokeLinecap="round"
			strokeMiterlimit="10"
			strokeWidth="1.5"
		/>
		<path
			d="M44.458 24C44.458 26.0033 44.1415 27.9272 43.5801 29.752H45.7137C46.2241 27.9173 46.5099 25.9934 46.5099 24C46.5099 22.0066 46.2343 20.0826 45.7137 18.2479H43.5801C44.1517 20.0727 44.458 21.9967 44.458 24Z"
			fill="#BF3054"
		/>
		<path
			d="M3.54214 24C3.54214 21.9967 3.85858 20.0727 4.42005 18.2479H2.28646C1.77604 20.0826 1.49023 22.0066 1.49023 24C1.49023 25.9934 1.76583 27.9173 2.28646 29.752H4.42005C3.84837 27.9272 3.54214 26.0033 3.54214 24Z"
			fill="#BF3054"
		/>
		<path
			d="M45.8664 29.7521C43.2531 39.1141 34.4432 46.0166 23.9898 46.0166C13.5364 46.0166 4.72651 39.1141 2.11315 29.7521H0C2.6644 40.2149 12.4032 48 23.9796 48C35.5559 48 45.305 40.2149 47.9592 29.7521H45.846H45.8664Z"
			fill="#FFF1DF"
		/>
		<path
			d="M24.0001 0C12.4237 0 2.6747 7.78514 0.0205078 18.2479H2.13366C4.74701 8.88597 13.5569 1.98347 24.0103 1.98347C34.4637 1.98347 43.2736 8.88597 45.8869 18.2479H48.0001C45.3357 7.78514 35.5969 0 24.0205 0H24.0001Z"
			fill="#FFF1DF"
		/>
	</svg>
);

import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

import tiles from '@/public/static/images/lines_tile@2x.png';
import { Breakpoint, ContentSection } from '@common/enums';
import { useMenu, useModal, useWindowSize } from '@common/hooks';
import { MobileFrameAsset, Text } from '@home/components/atoms';
import { NotificationModal } from '@home/components/molecules';
import { NotificationContent } from '@home/types';

interface NotificationProps {
	content: NotificationContent;
	onClick: () => void;
}

const PATHNAME = 'legionsandlegends';

const STiles = styled.div<{ image: string }>`
	height: 10px;
	background-image: url(${(props) => props.image});
	clip-path: polygon(0 0, calc(100% - 20px) 0%, 100% 100%, calc(0% + 14px) 100%);
`;

const SFrame = styled.div<{ clipPath: string }>`
	// aspect-ratio: 630/112;
	aspect-ratio: 1; // Very important little f*cker
	width: 535.5px;

	clip-path: ${(props) => props.clipPath};

	&:before {
		content: '';
		aspect-ratio: 1;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}
`;

const SImageContainer = styled.div<{
	clipPath: string;
	image: string;
	width?: string;
}>`
	aspect-ratio: 1;
	width: ${({ width }) => width ?? '102px'};
	right: 16px;
	clip-path: ${(props) => props.clipPath};
	background-image: url(${(props) => props.image});
	background-size: contain;
	background-position: top left;
`;

const SContainer = styled.div`
	aspect-ratio: 630/112;
	width: 535.5px;

	div:nth-child(1) {
		width: 410px;
	}
`;

const BreakText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const SAnimator = styled(motion.div)``;

const VARIANTS = {
	initial: {
		clipPath: 'polygon(0% 0%, 0% 50%, 0% 50%, 0% 50%, 0% 50%, 0% 100%)',
	},

	animate: {
		clipPath: [
			'polygon(0% 0%, 0% 50%, 0% 50%, 0% 50%, 0% 50%, 0% 100%)',
			'polygon(0% 0%, 0% 0%, 12% 50%, 12% 50%, 0% 100%, 0% 100%)',
			'polygon(0% 0%, 88% 0%, 100% 50%, 100% 50%, 88% 100%, 0% 100%)',
			'polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%, 0% 100%)',
		],
		transition: {
			duration: 0.6,
			ease: 'linear',
			times: [0, 0.1, 0.9, 1],
			delay: 2.8,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.2,
			ease: 'linear',
			delay: 0,
		},
	},
};

export const Notification = (props: NotificationProps) => {
	const {
		title,
		disappear,
		url,
		collapsedDescription,
		expandedDescription,
		mainImage,
		mainImageMobile,
		leftImage,
		rightImage,
	} = props.content;

	const [modal] = useModal();
	const [menu] = useMenu();
	const wSize = useWindowSize();

	const onNotificationClick = () => {
		if (disappear) {
			props.onClick();
		}

		if (url) {
			const urlPath = new URL(url);

			if (urlPath.hostname.includes(PATHNAME)) {
				const currentHash = urlPath.hash.replace('#', '') as ContentSection;
				const contentSection = Object.values(ContentSection);
				const isValid = contentSection.includes(currentHash);
				const index = contentSection.indexOf(currentHash);

				if (isValid) {
					menu.setActive(contentSection[index]);
				}

				return;
			}

			window.open(url, '_blank');

			return;
		}

		modal.open({
			content: (
				<NotificationModal
					description={expandedDescription?.html || ''}
					leftImage={leftImage?.url || ''}
					mainImage={mainImage.url}
					mainImageMobile={mainImageMobile?.url}
					rightImage={rightImage?.url || ''}
					title={title}
					onClose={() => modal.close()}
				/>
			),
		});
	};

	if (wSize.width < Breakpoint.MEDIUM)
		return (
			<AnimatePresence>
				<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<clipPath
							clipPathUnits="objectBoundingBox"
							id="NOTIFICATION_IMAGE_CLIP_PATH"
						>
							<path d="M0.279066 0.0710152C0.342528 0.0248612 0.418981 0 0.497451 0V0C0.574838 0 0.65029 0.0241799 0.713262 0.0691598L0.962014 0.24684C0.985852 0.263867 1 0.291359 1 0.320654V0.320654C1 0.347502 0.988106 0.372971 0.96752 0.390206L0.834359 0.50169C0.751102 0.571394 0.645979 0.609589 0.537396 0.609589H0.462604C0.354021 0.609589 0.248898 0.571394 0.165641 0.50169L0.0326594 0.390356C0.0119594 0.373026 0 0.347416 0 0.32042V0.32042C0 0.291238 0.013963 0.263818 0.0375633 0.246654L0.279066 0.0710152Z" />
						</clipPath>
					</defs>
				</svg>

				<SAnimator
					className="cursor-pointer relative px-4 max-w-[328px]"
					variants={VARIANTS}
					onClick={() => onNotificationClick()}
				>
					<MobileFrameAsset className="top-0 left-0" />
					<div className="absolute top-0 left-0 flex flex-row justify-between w-full pr-[7.5%]">
						<Text small className="text-offWhite mt-[16px] ml-[22%]">
							{title}
						</Text>
						<SImageContainer
							className="mt-[2px]"
							clipPath="url(#NOTIFICATION_IMAGE_CLIP_PATH)"
							image={mainImage.url}
							width="22%"
						/>
					</div>
				</SAnimator>
			</AnimatePresence>
		);

	return (
		<AnimatePresence>
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath clipPathUnits="objectBoundingBox" id="NOTIFICATION_ALERT_CLIP_PATH">
						<path d="M0.909973 0C0.909973 0 0.919973 0 0.909973 0L0.98999 0.0499878V0.0599976V0.0999756V0.109985L0.940002 0.159973C0.940002 0.159973 0.939993 0.159973 0.929993 0.159973H0.630005H0.619995H0.609985H0.409973H0.399963H0.389954H0.0599976H0.0499878L0 0.109985V0.0999756V0.0599976V0.0499878L0.0799561 0H0.369995H0.639954H0.909973Z" />
					</clipPath>

					<clipPath clipPathUnits="objectBoundingBox" id="NOTIFICATION_IMAGE_CLIP_PATH">
						<path d="M0.650024 0.769997C0.650024 0.769997 0.680012 0.77 0.700012 0.75L0.950012 0.540001C0.950012 0.540001 0.980042 0.509996 0.980042 0.479996V0.299995C0.980042 0.299995 0.970012 0.249998 0.950012 0.239998L0.610046 0H0.590027H0.390015H0.369995L0.0300293 0.239998C0.0300293 0.239998 0 0.279995 0 0.299995V0.479996C0 0.479996 2.92975e-05 0.520001 0.0300293 0.540001L0.280029 0.75C0.280029 0.75 0.310017 0.769997 0.330017 0.769997H0.690002H0.650024Z" />
					</clipPath>
				</defs>
			</svg>

			<SAnimator
				className="relative cursor-pointer"
				variants={VARIANTS}
				onClick={() => onNotificationClick()}
			>
				<SFrame
					className="absolute top-0 bg-wine bg-opacity-90"
					clipPath="url(#NOTIFICATION_ALERT_CLIP_PATH)"
				></SFrame>
				<svg
					className="absolute"
					fill="none"
					height={112 * 0.85}
					viewBox="0 0 630 112"
					width={630 * 0.85}
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clipPath="url(#clip0_35_64)">
						<path
							d="M625.29 30.26L581.72 0H569.41L614.04 30.99C616.75 32.87 618.37 35.97 618.37 39.26V64.36C618.37 67.35 617.06 70.16 614.77 72.08L580.13 101.15C578.32 102.67 576.02 103.51 573.66 103.51H480.88V103.18L418.3 103.51H56.18C53.81 103.51 51.52 102.67 49.71 101.15L15.06 72.08C12.77 70.16 11.46 67.35 11.46 64.36V39.26C11.46 35.96 13.08 32.87 15.79 30.99L60.42 0H48.11L4.54 30.26C1.7 32.23 0 35.47 0 38.93V64.87C0 68 1.37 70.95 3.77 72.96L40.08 103.43C41.98 105.02 44.39 105.9 46.87 105.9H582.96C585.44 105.9 587.85 105.02 589.75 103.43L626.06 72.96C628.46 70.95 629.83 68 629.83 64.87V38.93C629.83 35.47 628.13 32.23 625.29 30.26V30.26Z"
							fill="#FFF1DF"
						/>
						<path
							d="M539.14 0.299988H68.2C67.1 0.299988 66.05 0.629988 65.14 1.26999L24.83 29.26H497.14L539.14 0.299988Z"
							fill="#FFF1DF"
						/>
						<path
							d="M561.87 2.95999C562.42 2.95999 562.95 3.12999 563.41 3.44999L607.87 34.32C609.66 35.56 610.73 37.61 610.73 39.78V63.56C610.73 65.53 609.86 67.39 608.35 68.65L576.33 95.52C575.14 96.52 573.62 97.08 572.06 97.08H58.0101C56.4501 97.08 54.9301 96.53 53.7401 95.52L21.7201 68.65C20.2101 67.38 19.3501 65.53 19.3501 63.56V39.78C19.3501 37.6 20.4201 35.56 22.2101 34.32L66.6701 3.43999C67.1201 3.11999 67.6501 2.95999 68.2001 2.95999H561.87ZM561.87 0.299988H68.2001C67.1001 0.299988 66.0501 0.629988 65.1401 1.25999L20.6801 32.14C18.1801 33.88 16.6801 36.74 16.6801 39.79V63.57C16.6801 66.33 17.8901 68.93 20.0101 70.7L52.0301 97.57C53.7001 98.98 55.8301 99.75 58.0101 99.75H572.06C574.25 99.75 576.37 98.98 578.05 97.57L610.07 70.7C612.18 68.93 613.4 66.33 613.4 63.57V39.79C613.4 36.74 611.9 33.88 609.4 32.14L564.93 1.25999C564.03 0.629988 562.97 0.299988 561.87 0.299988V0.299988Z"
							fill="#BF3054"
						/>
						<path
							d="M568.65 111.72L570.83 109.06H63.37L66.51 111.72H568.65Z"
							fill="#BF3054"
						/>
						<path
							d="M562.87 4.53999C563.14 4.53999 563.39 4.61999 563.63 4.78999L607.85 35.49C609.27 36.48 610.11 38.09 610.11 39.82V63.52C610.11 65.08 609.42 66.55 608.23 67.56L576.38 94.29C575.43 95.08 574.23 95.52 572.99 95.52H526.35C525.11 95.52 523.91 95.08 522.96 94.29L491.11 67.56C489.91 66.56 489.23 65.09 489.23 63.52V39.82C489.23 38.09 490.08 36.48 491.49 35.49L535.72 4.77999C535.95 4.61999 536.2 4.53999 536.47 4.53999H562.85H562.87ZM562.85 0.549988H536.47C535.38 0.549988 534.33 0.879988 533.43 1.50999L489.21 32.22C486.72 33.95 485.23 36.79 485.23 39.83V63.53C485.23 66.27 486.44 68.86 488.54 70.62L520.39 97.35C522.05 98.75 524.17 99.52 526.34 99.52H572.98C575.15 99.52 577.27 98.75 578.93 97.35L610.78 70.62C612.88 68.86 614.09 66.27 614.09 63.53V39.83C614.09 36.8 612.6 33.95 610.11 32.22L565.91 1.50999C565.01 0.879988 563.96 0.549988 562.87 0.549988H562.85Z"
							fill="#BF3054"
						/>
						<path
							d="M45.88 70.48C55.059 70.48 62.5 63.039 62.5 53.86C62.5 44.681 55.059 37.24 45.88 37.24C36.701 37.24 29.26 44.681 29.26 53.86C29.26 63.039 36.701 70.48 45.88 70.48Z"
							fill="#BF3054"
						/>
						<path
							d="M45.22 50.54H49.21V54.53H51.87V47.88H45.22V50.54Z"
							fill="#FFF1DF"
						/>
						<path d="M46.55 57.19H42.56V53.2H39.9V59.85H46.55V57.19Z" fill="#FFF1DF" />
					</g>
					<defs>
						<clipPath id="clip0_35_64">
							<rect fill="white" height="111.72" width="629.83" />
						</clipPath>
					</defs>
				</svg>

				<SContainer className="relative flex text-left">
					<div className="relative">
						<Text className="mt-1 ml-16 text-red">{title}</Text>
						<BreakText small className="mt-2 ml-16 text-offWhite">
							{collapsedDescription}
						</BreakText>
					</div>
					<SImageContainer
						className="absolute top-1"
						clipPath="url(#NOTIFICATION_IMAGE_CLIP_PATH)"
						image={mainImage.url}
					/>

					<STiles
						className="absolute left-[34px] bottom-[14px] w-[78%] bg-repeat"
						image={tiles.src}
					/>
				</SContainer>
			</SAnimator>
		</AnimatePresence>
	);
};

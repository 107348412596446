import { useContext } from 'react';

import { MenuContext } from '@common/contexts';
import { ContentSection } from '@common/enums';
import { NavBarItem } from '@home/components/organisms';
import { FooterSectionContent } from '@home/sections';

export interface MenuInterface {
	open: () => void;
	close: () => void;
	isOpen: () => boolean;
	items: () => NavBarItem[];
	setItems: (items: NavBarItem[]) => void;
	footer: FooterSectionContent | undefined;
	setFooter: (footer: FooterSectionContent) => void;
	active: () => ContentSection;
	setActive: (active: ContentSection) => void;
	atFirst: () => boolean;
	atLast: () => boolean;
}

export const useMenu = (): [menu: MenuInterface] => {
	const menuContext = useContext(MenuContext);
	const sections = menuContext.items.map((item) => item.key);

	const _open = () => {
		menuContext.set({ visible: true });
	};

	const _close = () => {
		menuContext.set({ visible: false });
	};

	const _isOpen = () => menuContext.visible;

	const _items = () => menuContext.items;

	const _setItems = (items: NavBarItem[]) => {
		menuContext.set({ items });
	};

	const _active = () => menuContext.active;

	const _setActive = (active: ContentSection) => {
		window.location.hash = active;
		menuContext.set({ active });
	};

	const _footer = menuContext.footer;

	const _setFooter = (footer: FooterSectionContent) => {
		menuContext.set({ footer });
	};

	const _atFirst = () => sections.indexOf(menuContext.active) === 0;
	const _atLast = () =>
		sections.indexOf(menuContext.active) === sections.length - 1;

	return [
		{
			open: _open,
			close: _close,
			isOpen: _isOpen,
			items: _items,
			setItems: _setItems,
			footer: _footer,
			setFooter: _setFooter,
			active: _active,
			setActive: _setActive,
			atFirst: _atFirst,
			atLast: _atLast,
		},
	];
};

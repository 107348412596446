import { ReactNode, useContext } from 'react';

import { ModalContext } from '@common/contexts';

export interface ModalContextProps {
	closeable?: boolean;
	content: ReactNode | string;
}

export interface ModalInterface {
	open: (props: ModalContextProps) => void;
	close: () => void;
	isOpen: () => boolean;
}

export const useModal = (): [modal: ModalInterface] => {
	const modalContext = useContext(ModalContext);

	const _open = (props: ModalContextProps) => {
		modalContext.set(true, props);
	};

	const _close = () => {
		modalContext.set(false, modalContext.modal);
	};

	const _isOpen = () => modalContext.visible;

	return [{ open: _open, close: _close, isOpen: _isOpen }];
};

import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { HudBreakpoint } from '@common/enums';
import { useWindowSize } from '@common/hooks';

type Position = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

const Container = styled.div<{ position: Position; scale: number }>`
	position: absolute;
	top: ${({ position, scale }) =>
		position === 'topLeft' || position === 'topRight'
			? Math.round(204 * scale)
			: ''}px;
	bottom: ${({ position, scale }) =>
		position === 'bottomLeft' || position === 'bottomRight'
			? Math.round(263 * scale)
			: ''}px;

	left: ${({ position, scale }) =>
		position === 'topLeft' || position === 'bottomLeft'
			? Math.round(125 * scale)
			: ''}px;
	right: ${({ position, scale }) =>
		position === 'topRight' || position === 'bottomRight'
			? Math.round(125 * scale)
			: ''}px;

	transform: ${({ position }) =>
		position === 'bottomLeft'
			? 'scaleX(-1)'
			: position === 'topRight'
			? 'rotate(180deg) scaleX(-1)'
			: position === 'topLeft'
			? 'rotate(180deg)'
			: ''};

	height: ${({ scale }) => Math.round(176 * scale)}px;
	width: ${({ scale }) => Math.round(71 * scale)}px;
	z-index: 1000;

	@media (max-height: ${HudBreakpoint.MediumLarge}px) {
		bottom: ${({ position, scale }) =>
			position === 'bottomLeft' || position === 'bottomRight'
				? Math.round(263 * scale) + 1
				: ''}px;
		left: ${({ position, scale }) =>
			position === 'topLeft' || position === 'bottomLeft'
				? Math.round(125 * scale - 1)
				: ''}px;
		right: ${({ position, scale }) =>
			position === 'topRight' || position === 'bottomRight'
				? Math.round(125 * scale - 1)
				: ''}px;
	}

	@media (max-height: ${HudBreakpoint.Medium}px) {
		bottom: ${({ position, scale }) =>
			position === 'bottomLeft' || position === 'bottomRight'
				? Math.round(263 * scale) + 1
				: ''}px;
		left: ${({ position, scale }) =>
			position === 'topLeft' || position === 'bottomLeft'
				? Math.round(125 * scale - 7)
				: ''}px;
		right: ${({ position, scale }) =>
			position === 'topRight' || position === 'bottomRight'
				? Math.round(125 * scale - 7)
				: ''}px;
	}

	@media (max-height: ${HudBreakpoint.Small}px) {
		left: ${({ position, scale }) =>
			position === 'topLeft' || position === 'bottomLeft'
				? 125 * scale - 9
				: ''}px;
		right: ${({ position, scale }) =>
			position === 'topRight' || position === 'bottomRight'
				? 125 * scale - 9
				: ''}px;
	}

	@media (min-height: ${HudBreakpoint.Large}px) {
		left: ${({ position, scale }) =>
			position === 'topLeft' || position === 'bottomLeft'
				? 125 * scale + 6
				: ''}px;
		right: ${({ position, scale }) =>
			position === 'topRight' || position === 'bottomRight'
				? 125 * scale + 8
				: ''}px;

		top: ${({ position, scale }) =>
			position === 'topLeft' || position === 'topRight' ? 204 * scale : ''}px;
		bottom: ${({ position, scale }) =>
			position === 'bottomLeft' || position === 'bottomRight'
				? 263 * scale + 2
				: ''}px;
	}
`;

const SClippedContainer = styled.div<{
	clipPath: string;
}>`
	aspect-ratio: 1; // Very important little *ucker!!!
	height: 100%;
	clip-path: ${(props) => props.clipPath};
`;

const Gradient = styled(motion.div)`
	background: linear-gradient(180deg, #fff1df 0%, rgba(255, 241, 223, 0) 100%);
`;

interface ScrollIndicatorProps {
	percentage: number;
}

export const ScrollIndicator = ({ percentage }: ScrollIndicatorProps) => {
	const [currentPercentage, setCurrentPercantage] = useState(percentage);
	const [animation, setAnimation] = useState<'reset' | 'current'>('current');
	const { hudScale } = useWindowSize();

	useEffect(() => {
		if (percentage === 0 && currentPercentage !== 0) {
			setAnimation('reset');
		} else {
			setAnimation('current');
		}
		setCurrentPercantage(percentage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [percentage]);

	const reset = {
		height: 0,
		transition: {
			duration: 0.5,
			ease: [0.76, 0, 0.24, 1],
			delay: 0,
		},
	};

	const TOP_VARIANTS = {
		reset,
		current: {
			height: `${currentPercentage < 0 ? Math.abs(currentPercentage) : 0}%`,
			transition: {
				duration: 0,
			},
		},
	};

	const BOTTOM_VARIANTS = {
		reset,
		current: {
			height: `${currentPercentage > 0 ? currentPercentage : 0}%`,
			transition: {
				duration: 0,
			},
		},
	};

	const positions: Position[] = [
		'topLeft',
		'topRight',
		'bottomLeft',
		'bottomRight',
	];

	return (
		<>
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_SCROLL_INDICATOR"
					>
						<path d="M0.333974 0C0.316988 0.0709211 0.275034 0.215964 0.256962 0.286629C0.205621 0.487294 0.154024 0.695001 0 1L0.0770116 0.970236C0.0901023 0.96518 0.101022 0.955514 0.107408 0.943033C0.213922 0.735454 0.27995 0.517442 0.334932 0.290917C0.35013 0.228189 0.367052 0.172438 0.382505 0.111246C0.386336 0.0960123 0.383846 0.0797543 0.375481 0.0664405L0.333974 0Z" />
					</clipPath>
				</defs>
			</svg>
			{positions.map((position) => (
				<Container key={position} position={position} scale={hudScale}>
					<div className="relative h-full w-full hidden lg:block">
						<SClippedContainer clipPath={`url(#CLIP_PATH_SCROLL_INDICATOR)`}>
							<Gradient
								animate={animation}
								className="w-full absolute"
								variants={
									position.includes('top') ? TOP_VARIANTS : BOTTOM_VARIANTS
								}
							/>
						</SClippedContainer>
					</div>
				</Container>
			))}
		</>
	);
};

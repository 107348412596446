import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics';
import { FC } from 'react';

const MEASUREMENT_ID = 'G-ZPXYJPM8RX';

export interface MetaProps {
	title?: string;
	keywords?: string;
	description?: string;
	ogTitle?: string;
	ogType?: string;
	ogUrl?: string;
	ogImage?: string;
}

export const Meta: FC<MetaProps> = (props: MetaProps) => {
	usePageViews(MEASUREMENT_ID);

	return (
		<>
			<NextSeo
				description={props.description}
				openGraph={{
					title: props.ogTitle,
					url: props.ogUrl,
					description: props.description,
					images: [
						{
							url: 'https://legionsandlegends.com/images/legions_legends_card.jpg',
						},
					],
				}}
				title={props.title}
				twitter={{
					handle: '@AzraGames',
					cardType: 'summary_large_image',
					site: '@AzraGames',
				}}
			/>

			<Head>
				<meta charSet="utf-8"></meta>
				<meta content="width=device-width, initial-scale=1" name="viewport" />

				<link key="favicon16" href="/images/favicon_16.png" rel="icon" sizes="16x16" />
				<link key="favicon32" href="/images/favicon_32.png" rel="icon" sizes="32x32" />
				<link key="favicon96" href="/images/favicon_96.png" rel="icon" sizes="96x96" />

				<link
					key="apple152"
					href="/images/favicon_152.png"
					rel="apple-touch-icon-precomposed"
					sizes="152x152"
				/>

				<link
					key="apple167"
					href="/images/favicon_167.png"
					rel="apple-touch-icon-precomposed"
					sizes="167x167"
				/>

				<link
					key="apple180"
					href="/images/favicon_180.png"
					rel="apple-touch-icon-precomposed"
					sizes="180x180"
				/>
			</Head>
			<GoogleAnalytics gaMeasurementId={MEASUREMENT_ID} />
		</>
	);
};

Meta.defaultProps = {
	title: 'Legions & Legends',
	keywords:
		'combat collectible, rpg, game, play and own, web3, video games, free to play games, mobile games, desktop games, console games, play to earn, play and earn,',
	description:
		'Legions & Legends is the Play &amp; Own debut title from Azra Games. Conquer an uncharted galaxy where magic and technology collide.',
	ogTitle: 'Legions & Legends',
	ogUrl: 'https://legionsandlegends.com/',
	ogImage: 'https://legionsandlegends.com/images/social-card.png',
};

import { motion } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '@common/enums';
import { Text } from '@home/components/atoms';
import { GameOverviewFeature } from '@home/types';

import { getContentVariant } from './animations';
import {
	GameFeatureFrameAnimator,
	GameFeatureImageAnimator,
} from './components';

interface SClippedContainerProps {
	clipPath: string;
	marginLeft: number;
	marginTop: number;
	mobile?: boolean;
}

const SGameFeature = styled(motion.div)<{ mobile?: boolean }>`
	width: 100%;
	aspect-ratio: ${(props) => (props.mobile ? 327 / 83 : 412 / 156)};
	max-width: 470px;

	&:hover {
		cursor: pointer;
	}

	@media (min-width: ${Breakpoint.LARGE}px) {
		width: 50%;
	}
`;

const SShadow = styled.div`
	filter: drop-shadow(6px 8px 8px rgba(0, 0, 0, 0.6));
`;

const SClippedContainer = styled.div<SClippedContainerProps>`
	aspect-ratio: 1; // Very important little *ucker!!!
	width: ${(props) => (props.mobile ? 97 : 95)}%; // Seems to be good enough
	clip-path: ${(props) => props.clipPath};
	margin-top: ${(props) => props.marginTop}%;
	margin-left: ${(props) => props.marginLeft}%;
`;

const SGradientOverlay = styled(motion.div)<{
	paddingBottom: number;
	paddingLeft: number;
}>`
	padding-bottom: ${(props) => props.paddingBottom}%;
	padding-left: ${(props) => props.paddingLeft}%;
	background: linear-gradient(
		0deg,
		rgba(116, 31, 122, 0.75) 0%,
		rgba(116, 31, 122, 0.72) 25%,
		rgba(116, 31, 122, 0.64) 45%,
		rgba(116, 31, 122, 0.5) 63%,
		rgba(116, 31, 122, 0.31) 80%,
		rgba(116, 31, 122, 0.06) 97%,
		rgba(116, 31, 122, 0) 100%
	);
`;

export interface GameFeatureProps {
	feature: GameOverviewFeature;
	position: 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight';
	mobile?: boolean;
	onClick: () => void;
}

const CLIP_PATHS = {
	TopLeft: 'CLIP_PATH_TOP_LEFT',
	TopRight: 'CLIP_PATH_TOP_RIGHT',
	BottomLeft: 'CLIP_PATH_BOTTOM_LEFT',
	BottomRight: 'CLIP_PATH_BOTTOM_RIGHT',
};

const MARGIN_MAP = {
	TopLeft: { top: 2.1, left: 1.1 },
	TopRight: { top: 2.2, left: 3.6 },
	BottomLeft: { top: 1, left: 1.1 },
	BottomRight: { top: 1, left: 3.6 },
	Mobile: { top: 2.1, left: 1.1 },
};

const CONTENT_PADDING_MAP = {
	TopLeft: { bottom: 7, left: 14 },
	TopRight: { bottom: 7, left: 14 },
	BottomLeft: { bottom: 6, left: 14 },
	BottomRight: { bottom: 6, left: 14 },
	Mobile: { bottom: 5, left: 11 },
};

export const GameFeature: FC<GameFeatureProps> = (props: GameFeatureProps) => {
	const _onClick = () => {
		props.onClick();
	};

	return (
		<SGameFeature
			className="relative mx-[2px] mb-1 sm:mb-2 md:mb-0"
			mobile={props.mobile}
			onClick={_onClick}
		>
			<svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<clipPath clipPathUnits="objectBoundingBox" id="CLIP_PATH_TOP_LEFT">
						<path d="M0.901936 0C0.904037 0 0.906037 0.00063282 0.907758 0.00184786L0.992381 0.0605999C0.99714 0.063916 1 0.0693583 1 0.075155V0.295659C1 0.300899 0.997696 0.30586 0.993672 0.309227L0.932717 0.360359C0.929528 0.363043 0.925478 0.364511 0.921326 0.364511H0.035388L0 0.322035V0.0149095L0.0166814 0H0.901936Z" />
					</clipPath>

					<clipPath clipPathUnits="objectBoundingBox" id="CLIP_PATH_TOP_RIGHT">
						<path d="M0.098064 0C0.095963 0 0.093963 0.00063282 0.092242 0.00184786L0.00761902 0.0605999C0.00286002 0.063916 0 0.0693583 0 0.075155V0.295659C0 0.300899 0.00230399 0.30586 0.00632799 0.309227L0.067283 0.360359C0.070472 0.363043 0.074522 0.364511 0.078674 0.364511H0.964612L1 0.322035V0.0149095L0.983319 0H0.098064Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_BOTTOM_LEFT"
					>
						<path d="M0.901936 0.364502C0.904037 0.364502 0.906037 0.363869 0.907758 0.362654L0.992381 0.303902C0.99714 0.300586 1 0.295144 1 0.289347V0.0688429C1 0.0636029 0.997696 0.058642 0.993672 0.055275L0.932717 0.00414294C0.929528 0.00145894 0.925478 -9.05991e-06 0.921326 -9.05991e-06H0.035388L0 0.0424669V0.349592L0.0166814 0.364502H0.901936Z" />
					</clipPath>

					<clipPath
						clipPathUnits="objectBoundingBox"
						id="CLIP_PATH_BOTTOM_RIGHT"
					>
						<path d="M0.098064 0.364502C0.095963 0.364502 0.093963 0.363869 0.092242 0.362654L0.00761902 0.303902C0.00286002 0.300586 0 0.295144 0 0.289347V0.0688429C0 0.0636029 0.00230399 0.058642 0.00632799 0.055275L0.067283 0.00414294C0.070472 0.00145894 0.074522 -9.05991e-06 0.078674 -9.05991e-06H0.964612L1 0.0424669V0.349592L0.983319 0.364502H0.098064Z" />
					</clipPath>

					<clipPath clipPathUnits="objectBoundingBox" id="CLIP_PATH_MOBILE">
						<path d="M0.912459 0C0.914339 0 0.916119 0.000567029 0.917659 0.00165403L0.993199 0.054242C0.997449 0.05721 0.999999 0.062082 0.999999 0.06727V0.173114C0.999999 0.177804 0.997939 0.182245 0.994349 0.185258L0.939939 0.231027C0.937089 0.233428 0.933479 0.234742 0.929769 0.234742H0.031591L0 0.196723V0.013345L0.014891 0H0.912459Z" />
					</clipPath>
				</defs>
			</svg>

			<SShadow className="w-full h-full">
				<SClippedContainer
					clipPath={
						props.mobile
							? 'url(#CLIP_PATH_MOBILE)'
							: `url(#${CLIP_PATHS[props.position]})`
					}
					marginLeft={
						props.mobile
							? MARGIN_MAP.Mobile.left
							: MARGIN_MAP[props.position].left
					}
					marginTop={
						props.mobile
							? MARGIN_MAP.Mobile.top
							: MARGIN_MAP[props.position].top
					}
					mobile={props.mobile}
				>
					<GameFeatureImageAnimator
						image={props.feature.coverImage.url}
						mobile={props.mobile}
						position={props.position}
					>
						<SGradientOverlay
							className="absolute bottom-0 w-full h-1/2 flex flex-col justify-end"
							paddingBottom={
								props.mobile
									? CONTENT_PADDING_MAP.Mobile.bottom
									: CONTENT_PADDING_MAP[props.position].bottom
							}
							paddingLeft={
								props.mobile
									? CONTENT_PADDING_MAP.Mobile.left
									: CONTENT_PADDING_MAP[props.position].left
							}
							variants={getContentVariant(props.position, props.mobile)}
						>
							<div className="h-[34px] flex flex-col justify-center">
								<Text small>{props.feature.title}</Text>
							</div>
						</SGradientOverlay>
					</GameFeatureImageAnimator>
				</SClippedContainer>
			</SShadow>

			<GameFeatureFrameAnimator
				mobile={props.mobile}
				position={props.position}
			/>
		</SGameFeature>
	);
};

export enum HudAnimation {
	COUNTDOWN = 'countdown',
	DISCORD = 'discord',
	SPEED_METER_LEFT = 'speed-meter-left',
	SPEED_METER_RIGHT = 'speed-meter-right',
	ARCH_LEFT_TOP = 'arch-left-top',
	ARCH_LEFT_BOTTOM = 'arch-left-bottom',
	ARCH_RIGHT_TOP = 'arch-right-top',
	ARCH_RIGHT_BOTTOM = 'arch-right-bottom',
}

// File: HUD_Countdown_M01_v5
// Size: 1000 x 174
// Intro: start - idle_loop1_in (0 - 3083ms)
// Idle Loop 1: idle_loop1_in (3083ms) until counter_end (4083ms)
// Counter end Transition: counter_end (4083ms) until idle_loop2_in (5583ms)
// Idle Loop 2: idle_loop2_in (5583ms) until end idle_loop2_out (6567ms)

export enum CountdownAnimationTimeStamp {
	TS_START = 0,
	TS_LOOP1_START = (3083 / 1000) * 60,
	TS_LOOP1_END = (4083 / 1000) * 60,
	TS_END_TRANSITION_START = (4083 / 1000) * 60,
	TS_END_TRANSITION_STOP = (5583 / 1000) * 60,
	TS_LOOP2_START = (5583 / 1000) * 60,
	TS_LOOP2_END = (6567 / 1000) * 60,
}
